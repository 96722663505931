import { Container, Group, Icon, Link, SectionBanner, Switch, Text, Typography } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { PermissionLevelEnum, useCollaborators } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { isCollaboratorBlocked, useNavigate } from '@melio/platform-utils';
import { useSystemMessage } from '@melio/platform-utils/system-message';
import { useIsSubscriptionsEnabled, useSubscriptionFeature } from '@melio/subscriptions';
import React from 'react';

import { useFormContext } from '../../../FormContext';

export const PermissionLevelSection = ({
  isPendingPlanFreeUserTryingToSubmit = false,
}: {
  isPendingPlanFreeUserTryingToSubmit?: boolean;
}) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { watch, setValue, formState, formProps } = useFormContext();
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { data: collaborators } = useCollaborators({});

  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const collaboratorsInSubscriptionPlan = collaborators?.filter((c) => !isCollaboratorBlocked(c)) ?? [];

  const { quota, tryUseFeature: tryActivateCollaborator } = useSubscriptionFeature({
    featureName: 'collaborators',
    requirements: { totalUnits: collaboratorsInSubscriptionPlan.length + 1 },
  });

  const permissionLevel = watch('permissionLevel');

  const { showMessage } = useSystemMessage();

  const handleChange = (value: boolean) => {
    const permissionLevelIntention = value ? PermissionLevelEnum.Full : PermissionLevelEnum.Blocked;

    const handle = () => {
      track('User', 'Click', {
        Intent: 'manage-user-activation',
        Cta: value ? 'on' : 'off',
        AlertShown: isSubscriptionBannerShown(permissionLevelIntention) ? 'additional-user-surcharge' : null,
      });
      setValue('permissionLevel', permissionLevelIntention);
    };

    const initialState = formState.defaultValues?.permissionLevel;
    const isTryingToActivateBlockedUser =
      initialState === PermissionLevelEnum.Blocked && permissionLevelIntention === PermissionLevelEnum.Full;

    if (isTryingToActivateBlockedUser) {
      tryActivateCollaborator({ onFeatureIsEligible: handle, showSystemMessageHandler: showMessage });
    } else {
      handle();
    }
  };

  const isSubscriptionBannerShown = (permissionLevelIntention: PermissionLevelEnum) =>
    isSubscriptionsEnabled &&
    quota.excessUnitFee &&
    collaboratorsInSubscriptionPlan.length >= quota.freeUnitsLimit &&
    permissionLevelIntention === PermissionLevelEnum.Full &&
    formState.defaultValues?.permissionLevel === PermissionLevelEnum.Blocked;

  const onClickSeePlansLink = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    navigate('/settings/subscription-plans');
  };

  const seePlansLink = (
    <Link
      href="#"
      onClick={(e) => onClickSeePlansLink(e)}
      variant="inline"
      label={formatMessage('activities.collaboratorDrawer.body.permissionLevelSection.sectionBanner.button')}
      data-testid="collaborator-drawer-body-permission-level-section-see-plans-link"
    />
  );

  return (
    <Container overflow="initial">
      <Group variant="vertical" spacing="s">
        {isPendingPlanFreeUserTryingToSubmit ? (
          <SectionBanner
            variant="critical"
            title={formatMessage('activities.collaboratorDrawer.body.permissionLevelSection.sectionBanner.title')}
            description={formatMessage(
              'activities.collaboratorDrawer.body.permissionLevelSection.sectionBanner.description'
            )}
            action={seePlansLink}
            data-testid="collaborator-drawer-body-permission-level-account-activation-blocked-section-banner"
          />
        ) : null}
        <Group variant="vertical" spacing="xxs">
          <Group justifyContent="space-between" alignItems="center">
            <Text as="h3" textStyle="heading3Semi" id="permission-level-switch-label">
              {formatMessage('activities.collaboratorDrawer.body.permissionLevelSection.heading')}
            </Text>

            <Group alignItems="center">
              <Switch
                isDisabled={isPendingPlanFreeUserTryingToSubmit}
                isReadOnly={formProps.isReadOnly}
                data-testid="collaborator-drawer-body-permission-level-switch"
                onChange={handleChange}
                value={permissionLevel === PermissionLevelEnum.Full && !isPendingPlanFreeUserTryingToSubmit}
                label={
                  permissionLevel === PermissionLevelEnum.Full && !isPendingPlanFreeUserTryingToSubmit
                    ? formatMessage('activities.collaboratorDrawer.body.permissionLevelSection.switch.on')
                    : formatMessage('activities.collaboratorDrawer.body.permissionLevelSection.switch.off')
                }
                aria-labelledby="permission-level-switch-label"
                aria-describedby="permission-level-switch-description"
              />
            </Group>
          </Group>

          <Typography.Description
            id="permission-level-switch-description"
            label={formatMessage('activities.collaboratorDrawer.body.permissionLevelSection.description')}
          />
        </Group>

        {isSubscriptionBannerShown(permissionLevel) ? (
          <SectionBanner
            description={
              <Text textStyle="body3" color="global.neutral.1000">
                {formatMessage(
                  'activities.collaboratorDrawer.body.permissionLevelSection.subscription.planWithExcessFee.exceedsMaximumFreeSeats',
                  {
                    usedSeatsCount: collaboratorsInSubscriptionPlan.length,
                    freeSeatsCount: quota.freeUnitsLimit,
                    excessFee: formatCurrency(quota.excessUnitFee ?? 0),
                  }
                )}
              </Text>
            }
            variant="neutral"
            leftElement={<Icon type="info" aria-hidden />}
            data-testid="collaborator-drawer-body-permission-level-subscription-banner"
          />
        ) : null}
      </Group>
    </Container>
  );
};
