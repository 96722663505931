import { InvalidVendorBankAccountDetails } from '@melio/ap-widgets';
import { useMelioIntl, usePartnerFeature } from '@melio/platform-provider';
import { useSystemMessage } from '@melio/platform-utils';
import { useState } from 'react';

export const useInvalidBankAccountModalOnBatchPayments = () => {
  const { showMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();
  const [invalidBankAccountModalData, setInvalidBankAccountModalData] = useState<
    InvalidVendorBankAccountDetails | undefined
  >();

  const [isInvalidBankAccountModalEnabled] = usePartnerFeature('InvalidVendorBankAccountModal', false);

  const onInvalidVendorBankAccountModalClose = () => {
    setInvalidBankAccountModalData(undefined);
  };

  const onInvalidVendorBankAccountModalSuccess = (vendorName: string) => {
    showMessage({
      type: 'success',
      title: formatMessage('widgets.invalidVendorBankAccountModal.success', {
        vendorName,
      }),
    });

    onInvalidVendorBankAccountModalClose();
  };

  return {
    invalidBankAccountModalData,
    isInvalidBankAccountModalEnabled,
    onInvalidVendorBankAccountModalSuccess,
    onInvalidVendorBankAccountModalClose,
    setInvalidBankAccountModalData,
  };
};
