import { Link, NakedButton, useToast } from '@melio/penny';
import { AccountingPlatformSlug, useAccount, useAccountingPlatforms } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const useBillsTabEmptyState = (shouldShowGmailText: boolean, onEmailCopy: (value: string) => void) => {
  const { formatMessage } = useMelioIntl();
  const { toast } = useToast();
  const { data: accountData } = useAccount({ id: 'me' });
  const { data: accountingPlatformData } = useAccountingPlatforms();

  const handleCopyToClipboard = (value: string) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        toast({ type: 'informative', title: formatMessage('widgets.inboxEmailAddressCopyLink.toast.success') });
        onEmailCopy?.(value);
      })
      .catch(() => toast({ type: 'error', title: formatMessage('widgets.inboxEmailAddressCopyLink.toast.error') }));
  };

  const getGmailTextPartial = () =>
    shouldShowGmailText ? formatMessage('activities.payDashboard.billsTab.emptyState.description.gmail') : '';

  const getAccountingPlatformTextPartial = () => {
    const isOnlyQBOAvailable =
      accountingPlatformData?.length === 1 &&
      accountingPlatformData?.[0]?.accountingSlug === AccountingPlatformSlug.QuickBooksOnline;

    return formatMessage(
      isOnlyQBOAvailable
        ? 'activities.payDashboard.billsTab.emptyState.description.qboOnly'
        : 'activities.payDashboard.billsTab.emptyState.description.allAccountingSoftware'
    );
  };

  const getCopyEmailTextPartial = () => {
    const inboxEmailAddress = accountData?.company.inboxEmailAddress;

    if (inboxEmailAddress) {
      return formatMessage('activities.payDashboard.billsTab.emptyState.description.withCopyEmailButton', {
        email: <Link href={`mailto:${inboxEmailAddress}`} label={inboxEmailAddress} newTab />,
        link: (
          <NakedButton
            onClick={() => handleCopyToClipboard(inboxEmailAddress)}
            variant="secondary"
            label={formatMessage('activities.payDashboard.billsTab.emptyState.copyEmailButton')}
            data-testid="empty-state-copy-bills-inbox-email-button"
          />
        ),
      });
    } else {
      return formatMessage('activities.payDashboard.billsTab.emptyState.description.withoutCopyEmailButton');
    }
  };

  const emptyStateText = formatMessage('activities.payDashboard.billsTab.emptyState.description', {
    accountingSoftware: getAccountingPlatformTextPartial(),
    gmail: getGmailTextPartial(),
    copyEmail: getCopyEmailTextPartial(),
  });

  return { emptyStateText };
};
