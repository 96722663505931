import { Group, Layout, LoadingContainer, Text } from '@melio/penny';
import { useFundingSources } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSetDocumentTitle } from '@melio/platform-sdk';
import { PageTitle, SettingsInternalPageHeader } from '@melio/platform-utils';
import { groupBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { usePartnerGroups, useSubscription } from '../../../hooks';
import { FundingSourceGroupList } from '../../components';
import { SUBSCRIPTION_SETTINGS_PLANS_ROUTE } from '../../constants';
import { useSubscriptionRouter } from '../../utils';
import { SubscriptionSelectFundingSourceFooter } from './SubscriptionSelectFundingSourceFooter';
import { SubscriptionSelectFundingSourceHeader } from './SubscriptionSelectFundingSourceHeader';

export const SubscriptionSelectFundingSource = () => {
  const [searchParams] = useSearchParams();
  const { data: fundingSources, isFetching: isFetchingFundingSources } = useFundingSources();
  const fundingSourcesGroupedByType = useMemo(() => groupBy(fundingSources, 'type'), [fundingSources]);
  const { formatMessage } = useMelioIntl();
  const { goToAddBankAccount, goToAddCard } = useSubscriptionRouter();
  const subscription = useSubscription();
  const { isFiservPartner } = usePartnerGroups();
  useSetDocumentTitle(formatMessage('activities.subscriptionPaymentMethod.screens.title'));

  const newFundingSourceId = searchParams.get('fundingSourceId');
  const defaultFundingSourceToSelect =
    fundingSourcesGroupedByType['bank-account']?.[0] || fundingSourcesGroupedByType['card']?.[0];
  const [selectedFundingSourceId, setSelectedFundingSourceId] = useState<string>(
    newFundingSourceId || subscription?.fundingSourceId || defaultFundingSourceToSelect?.id || ''
  );
  const location = useLocation();

  const navigateToAddBankAccount = () => goToAddBankAccount({ returnUrl: location.pathname });
  const navigateToAddCard = () => goToAddCard({ returnUrl: location.pathname });

  return (
    <Layout
      paddingContent="s"
      data-testid="subscription-payment-method-select"
      {...(!isFiservPartner && {
        header: {
          isSticky: true,
          content: <SubscriptionSelectFundingSourceHeader />,
        },
      })}
      footer={{
        isSticky: true,
        content: (
          <SubscriptionSelectFundingSourceFooter
            fundingSources={fundingSources}
            selectedFundingSourceId={selectedFundingSourceId}
          />
        ),
      }}
    >
      <Layout maxWidth="600px" paddingContent={isFiservPartner ? 'none' : 's'}>
        <Group variant="vertical" alignItems="flex-start" spacing="s" height="full">
          <Group variant="vertical" alignItems="flex-start" spacing="s">
            {isFiservPartner ? (
              <SettingsInternalPageHeader
                title={formatMessage('activities.subscriptionPaymentMethod.screens.title')}
                goBackToRoute={SUBSCRIPTION_SETTINGS_PLANS_ROUTE}
              />
            ) : (
              <PageTitle textStyle="heading1Semi" id="subscritpions-choose-billing-account">
                {formatMessage('activities.subscriptionPaymentMethod.screens.title')}
              </PageTitle>
            )}

            <Text>{formatMessage('activities.subscriptionPaymentMethod.screens.description')}</Text>
          </Group>

          <LoadingContainer isLoading={isFetchingFundingSources}>
            <FundingSourceGroupList
              fundingSourcesGroupedByType={fundingSourcesGroupedByType}
              onSelected={(fundingSource) => setSelectedFundingSourceId(fundingSource.id)}
              selectedId={selectedFundingSourceId}
              onAddBank={navigateToAddBankAccount}
              onAddCard={navigateToAddCard}
            />
          </LoadingContainer>
        </Group>
      </Layout>
    </Layout>
  );
};
