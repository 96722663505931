import { FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import {
  BaseModal,
  Container,
  Group,
  Link,
  LoadingContainer,
  SectionBanner,
  Text,
  useBreakpoint,
  // eslint-disable-next-line import/no-deprecated
  useToast,
  VerificationCodeField,
} from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { ResendCode } from '../components';
import { useVerifyCodeErrorDetails } from '../hooks';

export type AuthenticationModalScreenProps = {
  isOpen: boolean;
  email: string;
  onClose: VoidFunction;
  generateNewCode: VoidFunction;
  onResetResendTimer: VoidFunction;
  onCodeComplete: (code: string, email: string) => Promise<unknown>;
  isLoading: boolean;
  secToResend: number;
  errorCode?: string;
};

export const AuthenticationModalScreen = forwardRef<AuthenticationModalScreenProps>(
  (
    { errorCode, onResetResendTimer, secToResend, isLoading, onCodeComplete, generateNewCode, onClose, isOpen, email },
    ref
  ) => {
    const { helpCenterUrl } = useConfig().settings;
    const { getSectionBannerErrorDetails } = useVerifyCodeErrorDetails();
    // eslint-disable-next-line import/no-deprecated
    const { toast } = useToast();
    const { formatMessage } = useMelioIntl();
    const { isExtraSmallScreen: isMobile } = useBreakpoint();
    const onCompleteHandler = async (code: string) => {
      try {
        const results = await onCodeComplete(code, email);
        return Boolean(results);
      } catch (e) {
        return true;
      }
    };
    const { track } = useAnalytics();
    const handleGenerateNewCode = () => {
      toast({
        type: 'success',
        title: formatMessage('ar.guestPayment.activities.authentication.modal.resendCode.toast.title'),
      });
      track('PaymentRequest', 'Click', { Cta: 'resend-code' });
      generateNewCode();
    };

    useAnalyticsView('PaymentRequest', isOpen, true, {
      PageName: 'verification-code',
      Intent: 'verification',
      AuthenticatorType: 'email',
      EligabilityType: 'send-to-my-email',
    });

    return (
      <BaseModal ref={ref} isOpen={isOpen} onClose={onClose} data-testid="otp-modal-screen">
        <BaseModal.Header>
          <Text textStyle="heading2Semi">
            <FormattedMessage id="ar.guestPayment.activities.authentication.modal.header" />
          </Text>
        </BaseModal.Header>
        <BaseModal.Body>
          <Container paddingX={isMobile ? 'none' : 'm'} paddingY="s" textAlign="center">
            <Group variant="vertical" spacing="xl">
              {errorCode ? (
                <SectionBanner
                  {...getSectionBannerErrorDetails(errorCode)}
                  variant="critical"
                  data-testid="error-section-banner"
                />
              ) : null}
              <Text>
                <FormattedMessage id="ar.guestPayment.activities.authentication.modal.description" values={{ email }} />
              </Text>
              {isLoading ? (
                <LoadingContainer isLoading />
              ) : (
                <VerificationCodeField
                  autoFocus
                  length={6}
                  onComplete={onCompleteHandler}
                  data-testid="VerificationCode.input-verification-code"
                />
              )}
              <Group variant="vertical" spacing="xs">
                <ResendCode
                  onResetResendTimer={onResetResendTimer}
                  secToResend={secToResend}
                  onClick={handleGenerateNewCode}
                />
                <Text textStyle="body2">
                  <FormattedMessage id="ar.guestPayment.activities.authentication.modal.help.text" />{' '}
                  <Link
                    color="secondary"
                    href={helpCenterUrl}
                    label={formatMessage('ar.guestPayment.activities.authentication.modal.help.link')}
                    newTab
                  />
                </Text>
              </Group>
            </Group>
          </Container>
        </BaseModal.Body>
      </BaseModal>
    );
  }
);
AuthenticationModalScreen.displayName = 'AuthenticationModalScreen';
