import { useVendorNotPermitted } from '@melio/ap-domain';
import { LockedVendorIcon, useAccountingPlatformName } from '@melio/ap-widgets';
import { Container, Group, Icon, Pill, Text, Typography } from '@melio/penny';
import { InboxItemBillTypeEnum, useAccountingPlatforms } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { identity } from 'lodash';

import { getBillsTabItemVendor, getInboxItemVendorName } from '../BillsTab.utils';
import { BillsTabItem } from '../types';
import { getBillsTabAmountCellInfo } from './BillsTabAmountCell';
import { getBillsTabOrigin } from './BillsTabOriginCell';
import { useBillsTabStatusCell } from './BillsTabStatusCell';

export type BillsTabListItemProps = { billsTabItem: BillsTabItem };

export const BillsTabListItem = ({ billsTabItem }: BillsTabListItemProps) => {
  const { formatCurrency, formatMessage, formatDate } = useMelioIntl();
  const billsTabItemVendor = getBillsTabItemVendor(billsTabItem);
  const isVendorNotPermitted = useVendorNotPermitted(billsTabItemVendor);
  const isReadOnly = billsTabItem.type === InboxItemBillTypeEnum.Bill && isVendorNotPermitted;
  const vendorName =
    getInboxItemVendorName(billsTabItem) ||
    formatMessage('activities.payDashboard.billsTab.listItem.vendorName.missing');
  const dueDate = billsTabItem.payload.dueDate
    ? formatMessage('activities.payDashboard.billsTab.listItem.dueDate', {
        dueDate: formatDate(billsTabItem.payload.dueDate, { dateStyle: 'medium' }),
      })
    : formatMessage('activities.payDashboard.billsTab.listItem.dueDate.missing');

  const { totalAmount, isPartiallyPaid, balance, currency } = getBillsTabAmountCellInfo(billsTabItem);
  const shouldShowTotalAmountText = !isPartiallyPaid && totalAmount;
  const shouldShowBalanceText = isPartiallyPaid && balance;
  const shouldShowPartiallyPaidTotalAmountText = isPartiallyPaid && totalAmount;
  const getBillsTabStatusCell = useBillsTabStatusCell();
  const { status, label: statusLabel } = getBillsTabStatusCell({ billsTabItem });
  const vendorDescription = [billsTabItemVendor?.nickname, billsTabItemVendor?.accountNumber]
    .filter(identity)
    .join(' | ');

  return (
    <Container paddingX="m" overflow="initial" data-testid={`bills-tab-list-item-${billsTabItem.payload.id}`}>
      <Group justifyContent="space-between">
        <Group variant="vertical" spacing="xxs">
          <Group variant="horizontal" spacing="xxs" alignItems="center">
            {isReadOnly ? <LockedVendorIcon /> : null}
            <Typography.MainLabel label={vendorName} variant="bold" />
          </Group>
          <Text shouldSupportEllipsis color="global.neutral.900" textStyle="body3">
            {vendorDescription}
          </Text>
          <Text textStyle="body3" color="global.neutral.900">
            {dueDate}
          </Text>
          <BillListItemOriginSection billsTabItem={billsTabItem} />
          <Pill type="secondary" label={statusLabel} status={status} />
        </Group>
        <Group variant="vertical" spacing="xxxs" alignItems="flex-end">
          {shouldShowTotalAmountText || shouldShowBalanceText ? (
            <Group variant="horizontal" spacing="xxs" alignItems="center">
              <Text textStyle="body2Semi">
                {shouldShowTotalAmountText
                  ? formatCurrency(totalAmount, currency)
                  : shouldShowBalanceText
                  ? formatCurrency(balance, currency)
                  : ''}
              </Text>
              <Icon type="chevron-right" size="small" />
            </Group>
          ) : null}
          {shouldShowPartiallyPaidTotalAmountText ? (
            <Text color="global.neutral.900" textStyle="body3">
              {formatMessage('activities.payDashboard.billsTab.listItem.billTotal', {
                amount: formatCurrency(totalAmount),
              })}
            </Text>
          ) : null}
        </Group>
      </Group>
    </Container>
  );
};

export const BillListItemOriginSection = ({ billsTabItem }: { billsTabItem: BillsTabItem }) => {
  const { formatMessage } = useMelioIntl();
  const { activeAccountingPlatform } = useAccountingPlatforms();
  const accountingPlatformName = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);
  const { originLabel, invoiceNumber, invoiceNumberLabel } = getBillsTabOrigin(
    billsTabItem,
    accountingPlatformName,
    formatMessage
  );

  return (
    <Group alignItems="center" spacing="xs">
      {invoiceNumber && (
        <Text color="global.neutral.800">
          <Group alignItems="center" justifyContent="center">
            <Icon type="bills" size="small" color="inherit" />
          </Group>
        </Text>
      )}
      <Text textStyle="body3" color="global.neutral.700">
        {invoiceNumberLabel}
      </Text>
      {originLabel && (
        <Text textStyle="body3" color="global.neutral.900">
          ({originLabel})
        </Text>
      )}
    </Group>
  );
};
