import { useAccountingPlatforms, useDeleteCollaborator, useUpdateCollaborator } from '@melio/platform-api';
import { PermissionLevelEnum } from '@melio/platform-api/src/entities/collaborators/useCollaborator';
import { ComponentProps, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useActivitiesNavigate } from '../../../utils';
import { AccountingPlatformSyncConnectionErrorEnum } from '../../accounting-platform-connect';
import { useAsyncActionWithToast } from './hooks/useAsyncActionWithToast';
import { ApproveAccountingFirmModalScreen } from './screens/modals/InviteOrEdit/ApproveAccountingFirm.modal-screen.';
import { Collaborator } from './types';

export const useApproveAccountingFirmModel = (collaborators?: Collaborator[]) => {
  const { navigateToSettingsAccountingPlatform } = useActivitiesNavigate();
  const [state, setModalState] = useState<{
    isOpen: boolean;
    collaborator: Collaborator | null;
  }>({
    isOpen: false,
    collaborator: null,
  });
  const openModal = (collaborator: Collaborator) => setModalState({ isOpen: true, collaborator });
  const { onClose } = useOpenApproveAccountingFirmModalByQueryParam({
    collaborators,
    isOpen: state.isOpen,
    openModal,
  });

  const closeModal = () => {
    onClose();
    setModalState({ isOpen: false, collaborator: null });
  };
  const { activeAccountingPlatform } = useAccountingPlatforms();
  const { isUpdating, update: updateCollaborator } = useUpdateCollaborator({
    id: state.collaborator?.id,
    enabled: !!state.collaborator?.id,
  });

  const approveWithToast = useAsyncActionWithToast({
    successLabel: 'activities.manageCollaborators.messages.collaborator.approveFirmInvite.success',
    errorLabel: 'activities.manageCollaborators.messages.collaborator.approveFirmInvite.error',
    onSuccess: closeModal,
    isInfo: true,
  });

  const { isDeleting, delete: deleteCollaborator } = useDeleteCollaborator({
    id: state.collaborator?.id,
    enabled: !!state.collaborator?.id,
  });

  const declineWithToast = useAsyncActionWithToast({
    successLabel: 'activities.manageCollaborators.messages.collaborator.declineFirmInvite.success',
    errorLabel: 'activities.manageCollaborators.messages.collaborator.declineFirmInvite.error',
    onSuccess: closeModal,
    isInfo: true,
  });

  const submit: ComponentProps<typeof ApproveAccountingFirmModalScreen>['onApprove'] = async () => {
    if (state.collaborator?.roleUniqueName) {
      const currentUniqueName = state.collaborator?.roleUniqueName;
      await approveWithToast(() =>
        updateCollaborator({
          roleUniqueName: currentUniqueName,
          permissionLevel: PermissionLevelEnum.Full,
        })
      );
    }
  };
  const onDecline: ComponentProps<typeof ApproveAccountingFirmModalScreen>['onDecline'] = async () => {
    await declineWithToast(async () => {
      const data = await deleteCollaborator();
      if (data.disconnectedFromAccountingSoftware) {
        navigateToSettingsAccountingPlatform({
          syncError: AccountingPlatformSyncConnectionErrorEnum.GeneralError,
          accountingPlatformId: activeAccountingPlatform?.id,
        });
      }
    });
  };

  return {
    openModal,
    closeModal,
    state,
    submit,
    onDecline,
    isDeleting,
    isUpdating,
  };
};

const useOpenApproveAccountingFirmModalByQueryParam = ({
  collaborators,
  isOpen,
  openModal,
}: {
  collaborators?: Collaborator[];
  isOpen: boolean;
  openModal: (collaborator: Collaborator) => void;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const approveAccountingFirmModalUserId = searchParams.get('approveAccountingFirmModalUserId');
  const approveAccountingFirmCollaboratorFromQuery = approveAccountingFirmModalUserId
    ? collaborators?.find((col) => col.userId === approveAccountingFirmModalUserId)
    : undefined;

  const cleanQueryParam = () => {
    searchParams.delete('approveAccountingFirmModalUserId');
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (collaborators !== undefined) {
      if (approveAccountingFirmCollaboratorFromQuery?.allowedActions.includes('approve-accounting-firm')) {
        !isOpen && openModal(approveAccountingFirmCollaboratorFromQuery);
      } else {
        cleanQueryParam();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveAccountingFirmCollaboratorFromQuery, isOpen]);

  const onClose = () => {
    if (approveAccountingFirmModalUserId) {
      cleanQueryParam();
    }
  };

  return { approveAccountingFirmModalUserId, onClose };
};
