import { FC, useState } from 'react';

import { SystemMessageContext } from './SystemMessageContext';
import { SystemMessageType } from './types';

export const SystemMessageProvider: FC = ({ children }) => {
  const [message, setMessage] = useState<SystemMessageType | null>(null);

  const clear = () => setMessage(null);

  return (
    <SystemMessageContext.Provider
      value={{ setMessage: (value: SystemMessageType) => setMessage(value), message, clear }}
    >
      {children}
    </SystemMessageContext.Provider>
  );
};
