import { Spinner } from '@chakra-ui/react';
import { FormattedMessage, useIsMobile, useMelioIntl } from '@melio/ar-domain';
import { Avatar, Button, Container, Form, Group, Icon, Pill, Text, useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { date, object, SchemaOf } from 'yup';

import { ScheduledDateField, useEstimatedDeliveryDate } from '../../../components';
import { ScheduledDateDetails } from '../../types';
import { getClosestBusinessDay } from '../../utils';

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    scheduledDate: date().required(formatMessage('ar.guestPayment.activities.scheduledDate.required.label')),
  }) as SchemaOf<ScheduledDateDetails>;
};

export type PayByBankAccountScreenProps = {
  onSubmit: ({ scheduledDate }: { scheduledDate: Date }) => void;
  fundingSourceName?: string;
  isSaving: boolean;
  amount: number;
  logo?: string;
};

export const PayByBankAccountScreen = forwardRef<PayByBankAccountScreenProps>(
  ({ logo, fundingSourceName, onSubmit, isSaving, amount }, ref) => {
    const isMobile = useIsMobile();
    const { track } = useAnalytics();
    const minScheduledDate = getClosestBusinessDay();
    const { formatDate, formatCurrency, formatMessage } = useMelioIntl();
    const { formProps, registerField, watch } = useMelioForm<ScheduledDateDetails>({
      onSubmit,
      schema: useSchema(),
      isSaving,
      defaultValues: { scheduledDate: minScheduledDate },
    });

    const handleOnSubmit = () => {
      track('PaymentRequest', 'Click', {
        Intent: 'pay-invoice',
        Cta: 'pay',
      });
      return onSubmit({ scheduledDate: watch('scheduledDate') });
    };

    return (
      <Group variant="vertical" spacing="l" data-testid="pay-by-bank-account-screen" ref={ref}>
        <Group variant="vertical" spacing="m">
          {isSaving ? (
            <Container border="regular" paddingX="m" paddingY="m">
              <Group justifyContent="center">
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Spinner />
              </Group>
            </Container>
          ) : (
            <Group variant="vertical" spacing="xxs">
              <Text textStyle="body4Semi" color="semantic.text.secondary">
                <FormattedMessage id="ar.guestPayment.payByBank.form.bankAccountDetails.label" />
              </Text>
              <Container border="regular" paddingX="m" paddingY="m">
                <Group variant="vertical">
                  <Group alignItems="center">
                    {logo ? <Avatar name={fundingSourceName || ''} src={logo} /> : <Icon type="bank" />}
                    <Text textStyle="body2Semi">{fundingSourceName}</Text>
                  </Group>
                </Group>
              </Container>
            </Group>
          )}

          <Form data-component="ScheduledDateForm" size={isMobile ? 'small' : 'large'} {...formProps}>
            <Group variant="vertical" spacing="xs-s" width="full">
              <ScheduledDateField
                labelProps={{ label: formatMessage('ar.guestPayment.activities.scheduledDate.label') }}
                fundingSourceType="bank-account"
                minDate={minScheduledDate}
                {...registerField('scheduledDate')}
              />
              <Pill
                status="neutral"
                type="secondary"
                label={formatMessage('ar.guestPayment.activities.scheduledDate.estimation.label', {
                  date: formatDate(useEstimatedDeliveryDate(watch('scheduledDate'), 'bank-account')),
                })}
              />
            </Group>
          </Form>
        </Group>
        <Button
          isLoading={isSaving}
          data-testid="add-bank-submit-button"
          size="large"
          label={formatMessage('ar.guestPayment.activities.cardHolder.form.buttons.submit.text', {
            amount: formatCurrency(amount),
          })}
          onClick={handleOnSubmit}
        />
      </Group>
    );
  }
);
PayByBankAccountScreen.displayName = 'PayByBankAccountScreen';
