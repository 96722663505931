import { Button, Container, Form, Group, Link, SectionBanner } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { Plan } from '@melio/subscriptions';
import { useLearnMoreLink } from '@melio/subscriptions/src/hooks';
import React from 'react';

import { AccountingClientPlanSelectionStepDiscountBanner } from '../../../../components/AccountingClientPlanSelectionStepDiscountBanner/AccountingClientPlanSelectionStepDiscountBanner';
import { FIRM_DISCOUNT_PERCENTAGE } from '../../../../consts';
import { useFocusStepOnError } from '../../../../hooks/useFocusStepOnError';
import { FirmClientSubscriptionPlanSelectionActivity } from '../../../FirmClientSubscriptionPlanSelection';
import {
  AssignFirmClientPlanFormField,
  AssignFirmClientPlanFormFields,
  AssignFirmClientPlanFormStepComponent,
} from '../../types';
import { useAssignFirmClientPlanFormContext } from '../AssignFirmClientPlanForm/AssignFirmClientPlanFormFormContext';

const stepFields: Array<AssignFirmClientPlanFormField> = ['subscriptionPlanId'];

export const AssignFirmClientPlanSelectionStep: AssignFirmClientPlanFormStepComponent = ({
  submitLabel,
  focusErrorStep,
  onContinue,
  isSaving,
}) => {
  const { formState, setValue, watch, registerField, submitButtonProps } = useAssignFirmClientPlanFormContext();
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const learnMoreLink = useLearnMoreLink();

  useFocusStepOnError<AssignFirmClientPlanFormFields>({
    formState,
    stepFields,
    focusErrorStep,
  });

  const validationErrorMessage = formState.errors.subscriptionPlanId?.message;

  const verifyPlanSelection = () => {
    onContinue();
  };

  const handleSelectSubscriptionPlanId = ({ id: planId, tier }: Plan) => {
    track('Organization', 'Click', {
      Cta: 'subscribe',
      Intent: 'subscribe',
      PlanChosenId: planId,
      PlanChosen: tier,
      BillingCycle: 'monthly',
    });
    setValue('subscriptionPlanId', planId);
  };

  return (
    <Group spacing="m" variant="vertical" width="full">
      <Form.TextField isRequired type="hidden" {...registerField('subscriptionPlanId')} isHidden />
      {validationErrorMessage && (
        <SectionBanner
          data-testid="plan-selection-validation-error"
          description={validationErrorMessage}
          variant="critical"
        />
      )}
      <AccountingClientPlanSelectionStepDiscountBanner discountPercent={FIRM_DISCOUNT_PERCENTAGE} />
      <FirmClientSubscriptionPlanSelectionActivity
        selectedSubscriptionPlanId={watch('subscriptionPlanId')}
        onSelect={handleSelectSubscriptionPlanId}
        isSaving={isSaving}
        discountPercentage={FIRM_DISCOUNT_PERCENTAGE}
      />
      <Container>
        <Link
          label={formatMessage('activities.accountants.newClient.form.plan.viewAllFeatures')}
          href={learnMoreLink}
          color="secondary"
          size="medium"
          newTab
        />
      </Container>
      <Container justifyContent="flex-end">
        <Button
          variant="primary"
          size="medium"
          label={submitLabel}
          {...submitButtonProps}
          onClick={verifyPlanSelection}
        />
      </Container>
    </Group>
  );
};
