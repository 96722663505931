import { useAccount, useGuestPayorFundingSources, useGuestPayorOnboarding } from '@melio/ar-domain';
// eslint-disable-next-line import/no-deprecated
import { Group, useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { PartnerName, PlaidAccountData } from '@melio/platform-api';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import React, { useEffect, useRef } from 'react';

import { SignInBanner } from '../../components';
import { useExistingUserEmail } from '../../hooks';
import { AuthenticationModalActivity } from '../authentication-modal';
import { AddBankFundingSourceFormScreen, AddPlaidFundingSourceScreen } from './screens';

type AddBankFundingSourceActivityProps = {
  onDone: (id: string) => void;
  paymentRequestLink: string;
  partnerName: PartnerName;
  onLoggedIn?: (accessToken: string, refreshToken?: string | null) => Promise<unknown>;
  onError?: ARErrorFunction;
  isNavigateApUsersToDashboardEnabled?: boolean;
};

const plaidAnalytics = {
  PageName: 'verification-code',
  Intent: 'verification',
  AuthenticatorType: 'email',
};

export const AddBankFundingSourceActivity = withAnalyticsContext<AddBankFundingSourceActivityProps>(
  forwardRef(
    (
      {
        onError,
        onLoggedIn,
        partnerName,
        paymentRequestLink,
        isNavigateApUsersToDashboardEnabled,
        onDone,
        setAnalyticsProperties,
      },
      ref
    ) => {
      const { onboarding, isLoading: isLoadingOnBoarding } = useGuestPayorOnboarding();
      const { create, isCreating: isCreatingFundingSource } = useGuestPayorFundingSources({ enabled: false });
      const [isPlaidModalOpen, openPlaidModal] = useBoolean(false);
      const [isOTPModalOpen, otpModalOpen] = useBoolean(false);
      const [isLoadingPlaidToken, loadingPlaidToken] = useBoolean(false);
      const [userEmail, setUserEmail] = React.useState<string>('');
      // eslint-disable-next-line import/no-deprecated
      const { toast } = useToast();
      const { track } = useAnalytics();
      const { data: account, error: fetchAccountError } = useAccount({ id: 'me' });
      const prevEmailRef = useRef<string>();
      const {
        onSignInClick,
        onEmailChangeHandler,
        isLoading: isUserEmailLoading,
        isSignInBannerAvailable,
        userType,
        currentUserEmail,
      } = useExistingUserEmail({ paymentRequestLink, isNavigateApUsersToDashboardEnabled });

      useEffect(() => {
        const prevEmail = prevEmailRef.current;

        if (userEmail && userEmail !== prevEmail) {
          prevEmailRef.current = userEmail;
          const isAuthenticated = !fetchAccountError && account && account.user.email === userEmail;
          isAuthenticated ? void handleAuthenticationDone() : otpModalOpen.on();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [userEmail, account]);

      const createPlaidFundingSource = (data: PlaidAccountData) => {
        openPlaidModal.off();
        void create({
          type: 'plaid',
          details: { plaidAccountId: data.accounts[0]?.id as string, plaidToken: data.public_token },
        })
          .then(({ id }) => {
            track('PaymentRequest', 'Status', {
              StatusType: 'success',
              ...plaidAnalytics,
            });
            onDone(id);
          })
          .catch(plaidErrorHandler);
      };

      setAnalyticsProperties({
        UserType: userType,
        Email: currentUserEmail,
      });

      useEffect(() => {
        if (isSignInBannerAvailable) {
          track('PaymentRequest', 'View', {
            PageName: 'payment-request-sign-in',
          });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isSignInBannerAvailable]);

      const plaidErrorHandler = (error: ARPlatformError) => {
        track('PaymentRequest', 'Status', {
          StatusType: 'failure',
          ErrorType: error.message,
          ...plaidAnalytics,
        });
        openPlaidModal.off();
        toast({ type: 'error', title: error.message });
        onError?.(error);
      };

      const handleOnSubmit = ({ email }: { email: string }) => {
        track('PaymentRequest', 'Click', {
          PaymentMethodType: 'ach',
          Intent: 'connect-bank-account',
          Cta: 'connect-with-plaid',
        });
        setUserEmail(email);
      };

      const handleAuthenticationDone = async () => {
        try {
          otpModalOpen.off();
          await onboarding({ paymentRequestLink });
          openPlaidModal.on();
        } catch (error) {
          toast({ type: 'error', title: (error as ARPlatformError).message });
          onError?.(error as ARPlatformError);
        }
      };

      const resetEmailState = () => {
        setUserEmail('');
        prevEmailRef.current = '';
      };

      const onAuthenticationModalClose = () => {
        otpModalOpen.off();
        resetEmailState();
      };

      const onPlaidModalClose = () => {
        openPlaidModal.off();
        resetEmailState();
      };

      return (
        <Group spacing="none">
          <AddBankFundingSourceFormScreen
            onSubmit={handleOnSubmit}
            isSaving={isLoadingPlaidToken || isLoadingOnBoarding || isCreatingFundingSource}
            ref={ref}
            onEmailChanged={onEmailChangeHandler}
            isLoading={isUserEmailLoading}
            EmailBanner={isSignInBannerAvailable ? <SignInBanner onSignInClick={onSignInClick} /> : null}
          />
          <AuthenticationModalActivity
            partnerName={partnerName}
            email={userEmail}
            onLoggedIn={onLoggedIn}
            onClose={onAuthenticationModalClose}
            isOpen={isOTPModalOpen}
            onDone={handleAuthenticationDone}
          />
          {isPlaidModalOpen ? (
            <AddPlaidFundingSourceScreen
              onLoadToken={(isLoading) => {
                if (isLoading) {
                  loadingPlaidToken.on();
                } else {
                  loadingPlaidToken.off();
                }
              }}
              onSuccess={createPlaidFundingSource}
              onClose={onPlaidModalClose}
              onError={plaidErrorHandler}
            />
          ) : null}
        </Group>
      );
    }
  )
);
AddBankFundingSourceActivity.displayName = 'AddCardFundingSourceActivity';
