import {
  isFXCurrency,
  isPayorPaymentFee,
  sumPaymentFees,
  useEstimatedDeliveryDateLabel,
  useFastDeliveryBadge,
} from '@melio/ap-domain';
import { useFundingSourceUnverifiedText } from '@melio/ap-widgets';
import { Group, GroupProps, SectionBanner, useBreakpointValue } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { compact } from 'lodash';

import { PaymentDetails } from '../types';
import { PaymentScheduledPaymentDetailsCardContainer } from './PaymentDetailsCardContainer';
import { PaymentDetailsCardRow } from './PaymentDetailsRow';
import { useBillInvoiceNumberLabel } from './useBillInvoiceNumberLabel';
import { useDeliveryMethodDescription } from './useDeliveryMethodDescription';
import { useUpdatedFieldsBadge } from './useUpdatedFieldsBadge';

export const PaymentScheduledPaymentDetailsCard = ({
  payment,
  isEditPaymentFlow = false,
}: {
  payment: PaymentDetails;
  isEditPaymentFlow?: boolean;
}) => {
  const { formatDate, formatCurrency } = useMelioIntl();
  const rowsSpacing = useBreakpointValue<GroupProps['spacing']>({ xs: 's', s: 'xs', m: 'xs' });
  const {
    settings: {
      payment: {
        scheduled: { showZeroFees },
      },
    },
  } = useConfig();

  const invoiceNumber = useBillInvoiceNumberLabel(payment.bills);

  const unVerifiedFundingSourceBannerText: string = useFundingSourceUnverifiedText({
    fundingSource: payment.fundingSource,
    effectiveScheduleDate: payment.scheduledDate,
  });

  const deliveryMethodDisplayText = useDeliveryMethodDescription({
    deliveryMethod: payment.deliveryMethod,
    vendorName: payment.vendor.name,
    deliveryPreferenceType: payment.deliveryPreferenceType,
  });

  const { dateLabel } = useEstimatedDeliveryDateLabel({
    minDeliveryDate: payment.deliveryDate,
    maxDeliveryDate: payment.maxDeliveryDate,
    deliveryPreferenceType: payment.deliveryPreferenceType,
  });

  const fastBadge = useFastDeliveryBadge(payment.fees);
  const updatedBadges = useUpdatedFieldsBadge(payment.updatedFields);

  const payorFees = payment.fees?.filter(isPayorPaymentFee) ?? [];
  const totalFeesAmount = sumPaymentFees(payorFees);

  const isFxPayment = isFXCurrency(payment.vendor.currency);
  const shouldShowFeesSection = !!totalFeesAmount || (showZeroFees && !isFxPayment);

  return (
    <PaymentScheduledPaymentDetailsCardContainer
      paymentId={payment.id}
      approvalDecisionStatus={payment.approvalDecisionStatus}
      bills={payment.bills}
      deliveryMethod={payment.deliveryMethod}
      paymentAmount={payment.paymentAmount}
      foreignAmount={payment.foreignAmount}
      scheduledDate={payment.scheduledDate}
      hasBorder={false}
      isEditPaymentFlow={isEditPaymentFlow}
      subscriptionOccurrence={payment.subscriptionOccurrence}
    >
      <Group variant="vertical" spacing="l">
        <Group as="ul" variant="vertical" spacing={rowsSpacing}>
          <PaymentDetailsCardRow
            fieldName="vendorName"
            titleMessage="activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.vendorName"
            value={payment.vendor.name}
          />

          {invoiceNumber ? (
            <PaymentDetailsCardRow
              fieldName="invoiceNumber"
              titleMessage="activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.invoiceNumber"
              value={invoiceNumber}
            />
          ) : null}

          <PaymentDetailsCardRow
            fieldName="paymentMethod"
            titleMessage="activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.paymentMethod"
            value={payment.fundingSource.displayName}
            badges={compact([updatedBadges.fundingSourceId])}
          />

          <PaymentDetailsCardRow
            fieldName="debitDate"
            titleMessage="activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.debitDate"
            value={formatDate(payment.scheduledDate, { dateStyle: 'medium' })}
            badges={compact([updatedBadges.scheduledDate])}
          />

          <PaymentDetailsCardRow
            fieldName="deliveryMethod"
            titleMessage="activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.deliveryMethod"
            value={deliveryMethodDisplayText}
            badges={compact([fastBadge, updatedBadges.deliveryMethod])}
          />

          <PaymentDetailsCardRow
            fieldName="deliveryDate"
            titleMessage={
              payment.deliveryMethod.type === 'paper-check'
                ? 'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.deliveryDate.check'
                : 'activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.deliveryDate'
            }
            value={dateLabel}
            badges={compact([updatedBadges.effectiveDeliveryDate])}
          />
          {payment.note && (
            <PaymentDetailsCardRow
              fieldName="note"
              titleMessage="activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.note"
              value={payment.note}
            />
          )}

          {shouldShowFeesSection ? (
            <PaymentDetailsCardRow
              fieldName="fees"
              titleMessage="activities.paymentScheduledSuccessfully.screens.paymentScheduled.paymentDetails.fees"
              value={formatCurrency(totalFeesAmount)}
            />
          ) : null}
        </Group>
        {unVerifiedFundingSourceBannerText && (
          <SectionBanner
            variant="informative"
            description={unVerifiedFundingSourceBannerText}
            data-testid="funding-source-unverified-banner"
          />
        )}
      </Group>
    </PaymentScheduledPaymentDetailsCardContainer>
  );
};
