import { StatusIconSolid, Tooltip } from '@melio/penny';
import { FxQuote, Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const DebitDateTooltip = ({ payment, debitDate }: { payment: Payment; debitDate?: Date }) => {
  const { formatDate, formatMessage } = useMelioIntl();

  if (!debitDate) {
    return null;
  }

  const preTitle = formatMessage('activities.paymentsLateApprovalModal.table.cells.debit.tooltip.preTitle');
  const tooltipLabel = formatMessage('activities.paymentsLateApprovalModal.table.cells.debit.tooltip', {
    oldDate: formatDate(payment.scheduledDate, { dateStyle: 'medium' }),
    newDate: formatDate(debitDate, { dateStyle: 'medium' }),
  });
  return (
    <Tooltip label={tooltipLabel} shouldAddTriggerFocus triggerAriaLabel={tooltipLabel} preTitle={preTitle}>
      <StatusIconSolid data-testid="debit-date-icon" variant="informative" size="small" />
    </Tooltip>
  );
};

export const DeliveryDateTooltip = ({ payment, deliveryDate }: { payment: Payment; deliveryDate?: Date }) => {
  const { formatDate, formatMessage } = useMelioIntl();

  if (!deliveryDate) {
    return null;
  }

  const preTitle = formatMessage('activities.paymentsLateApprovalModal.table.cells.deliver.tooltip.preTitle');
  const tooltipLabel = formatMessage('activities.paymentsLateApprovalModal.table.cells.deliver.tooltip', {
    oldDate: formatDate(payment.estimatedDelivery, { dateStyle: 'medium' }),
    newDate: formatDate(deliveryDate, { dateStyle: 'medium' }),
  });
  return (
    <Tooltip label={tooltipLabel} shouldAddTriggerFocus triggerAriaLabel={tooltipLabel} preTitle={preTitle}>
      <StatusIconSolid data-testid="delivery-date-icon" variant="informative" size="small" />
    </Tooltip>
  );
};

export const FxAmountTooltip = ({ fxQuote }: { fxQuote: FxQuote }) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Tooltip
      label={formatMessage('activities.paymentsLateApprovalModal.table.cells.amount.rateChanged', {
        rate: fxQuote.usdToForeignRate.toFixed(4),
        currency: fxQuote.foreignCurrency,
      })}
    >
      <StatusIconSolid variant="informative" size="small" />
    </Tooltip>
  );
};
