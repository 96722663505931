import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { PaymentSchedulingPreference, useConfig } from '@melio/platform-provider';
import { addDays } from 'date-fns';
import { useMemo } from 'react';

export const usePaymentSchedulingPreference = () => {
  // eslint-disable-next-line no-restricted-syntax
  const today = useMemo(() => new Date(), []);

  const {
    settings: {
      payment: {
        scheduling: { scheduleBy, maxDeliveryDateInDays },
      },
    },
  } = useConfig();

  const [useDeliveryDateSchedulingPreference] = useFeature<boolean>(
    FeatureFlags.UseDeliveryDateSchedulingPreference,
    false
  );
  const [isSelectDeliveryDateFeatureEnabled] = useFeature<boolean>(
    FeatureFlags.SelectDeliveryDateFeatureEnabled,
    false
  );

  const schedulingPreference = isSelectDeliveryDateFeatureEnabled
    ? scheduleBy
    : PaymentSchedulingPreference.ByScheduleDate;

  const selectedPreference = useDeliveryDateSchedulingPreference
    ? PaymentSchedulingPreference.ByDeliveryDate
    : schedulingPreference;

  return {
    scheduleBy: selectedPreference,
    isByDeliveryDate: selectedPreference === PaymentSchedulingPreference.ByDeliveryDate,
    isByScheduleDate: selectedPreference === PaymentSchedulingPreference.ByScheduleDate,
    minDeliveryDate: today,
    maxDeliveryDate: addDays(today, maxDeliveryDateInDays),
  };
};
