import { ActionsDropdownMenuItemProps, AvatarGroup, Group, Pill, Text, Tooltip } from '@melio/penny';
import { PillProps } from '@melio/penny/dist/types/components/dataDisplay/Pill/Pill.types';
import { useAnalytics } from '@melio/platform-analytics';
// eslint-disable-next-line no-restricted-imports
import { FirmClientExpanded } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import { isAccountantFirmClientPlanTier, Tier, useIsSubscriptionsEnabled } from '@melio/subscriptions';

import { isEligibleForAssignPlan } from '../../../utils/isEligibleForAssignPlan';
import { CompanyAvatar } from '../../CompanyAvatar';
import { PaymentsOverview } from '../../PaymentsOverview';
import { CardLayout } from '../common/Card.layout';

type Props = {
  client: FirmClientExpanded;
  isPaymentsOverviewLoading: boolean;
  isLoading: boolean;
  onManageClientPlan: VoidFunction;
  onToggleHiddenState: VoidFunction;
  onProceedToCheckout: VoidFunction;
  onGoToAssignPlan: VoidFunction;
  onClick?: VoidFunction;
};

type SubscriptionPlanState = 'missing' | 'activated' | 'awaiting-activation';

export const OrganizationCard = ({
  client,
  isPaymentsOverviewLoading,
  isLoading,
  onManageClientPlan,
  onToggleHiddenState,
  onProceedToCheckout,
  onGoToAssignPlan,
  onClick,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const { track } = useAnalytics();

  const { id: accountingClientId, name, logoUrl, billsAndPaymentsOverview } = client.organization;
  const companyName = name || '';

  const subscriptionInfo = isSubscriptionsEnabled ? client.subscriptionInfo : undefined;
  const { subscription, isAwaitingActivation: isSubscriptionAwaitingActivation } = subscriptionInfo || {};
  const isVipPlan = subscription?.planTier === Tier.MelioVip;
  const isAccountingFirmClientPlan = subscription?.planTier
    ? isAccountantFirmClientPlanTier(subscription.planTier)
    : false;

  const subscriptionPlanDropdownActionsPropsMapping: Record<
    SubscriptionPlanState,
    Array<ActionsDropdownMenuItemProps>
  > = {
    missing: [
      {
        label: formatMessage('activities.accountants.OrganizationCard.menu.assignClientPlan'),
        onClick: () => onGoToAssignPlan(),
        dataTestId: `organization-assign-plan-${accountingClientId}`,
      },
    ],
    activated: [
      {
        label: formatMessage('activities.accountants.OrganizationCard.menu.manageClientPlan'),
        onClick: onManageClientPlan,
        dataTestId: `organization-manage-plan-${accountingClientId}`,
      },
    ],
    'awaiting-activation': [
      {
        label: formatMessage('activities.accountants.OrganizationCard.menu.proceedToCheckout'),
        onClick: onProceedToCheckout,
        dataTestId: `organization-processed-to-checkout-${accountingClientId}`,
      },
    ],
  };

  const subscriptionPlanPillPropsMapping: Record<SubscriptionPlanState, PillProps | null> = {
    missing: {
      label: formatMessage('activities.accountants.OrganizationCard.pill.assignPlan.label'),
      status: 'neutral',
      type: 'secondary',
    },
    'awaiting-activation': {
      label: formatMessage('activities.accountants.OrganizationCard.pill.awaitingActivation.label'),
      status: 'neutral',
      type: 'secondary',
    },
    activated: null,
  };

  const onToggleDropdown = (value: boolean) => {
    if (value) {
      track('User', 'Click', { Cta: 'client-options' });
    }
  };

  const subscriptionPlanState = ((): SubscriptionPlanState | null => {
    if (isPaymentsOverviewLoading) {
      return null;
    }
    if (isSubscriptionsEnabled) {
      if (isAccountingFirmClientPlan) {
        return 'activated';
      }
      if (isSubscriptionAwaitingActivation) {
        // There is no active subscription, and the chosen client plan is waiting for checkout
        return 'awaiting-activation';
      }
      if (isEligibleForAssignPlan(subscription)) {
        // There is no active subscription and no tier (in case of Melio_Basic)
        return 'missing';
      }
    }
    return null;
  })();

  const subscriptionPlanDropdownActionsProps = subscriptionPlanState
    ? subscriptionPlanDropdownActionsPropsMapping[subscriptionPlanState]
    : [];

  const subscriptionPlanPillProps = subscriptionPlanState && subscriptionPlanPillPropsMapping[subscriptionPlanState];

  return (
    <CardLayout
      data-testid={`organization-card-${accountingClientId}`}
      isMenuDisabled={isLoading}
      actions={[
        ...subscriptionPlanDropdownActionsProps,
        {
          label: client.organization.isHidden
            ? formatMessage('activities.accountants.OrganizationCard.menu.unhideClient')
            : formatMessage('activities.accountants.OrganizationCard.menu.hideClient'),
          onClick: onToggleHiddenState,
          dataTestId: 'menu-item-toggle-hidden-state',
        },
      ]}
      onClick={onClick}
      onToggle={onToggleDropdown}
      top={
        <>
          <CompanyAvatar size="medium" src={logoUrl} name={companyName} />
          <Group variant="vertical" spacing="xxs" alignItems="flex-start">
            <Group variant="horizontal" alignItems="center" spacing="xs">
              <Text textStyle="body2Semi" shouldSupportEllipsis>
                {companyName}
              </Text>
              {subscription?.planTier && (
                <Tooltip
                  isEnabled={isVipPlan || !isAccountingFirmClientPlan}
                  label={
                    isVipPlan
                      ? formatMessage('activities.accountants.OrganizationCard.planTier.vipTooltip.label')
                      : formatMessage('activities.accountants.OrganizationCard.planTier.smbTooltip.label')
                  }
                  data-testid={`plan-tier-pill-tooltip-${accountingClientId}`}
                >
                  <Pill
                    label={
                      isVipPlan
                        ? formatMessage(`activities.accountants.OrganizationCard.planTier.${Tier.MelioVip}.chip.label`)
                        : formatMessage(`activities.subscription.plans.${subscription?.planTier}.title`)
                    }
                    status={isAccountingFirmClientPlan ? 'neutral' : 'brand'}
                    type={isAccountingFirmClientPlan ? 'primary' : 'secondary'}
                    data-testid={`plan-tier-pill-${accountingClientId}`}
                  />
                </Tooltip>
              )}
              {subscriptionPlanPillProps && (
                <Pill
                  data-testid={`pending-plan-pill-${subscriptionPlanState}-${accountingClientId}`}
                  {...subscriptionPlanPillProps}
                />
              )}
            </Group>
          </Group>
        </>
      }
      bottom={
        <>
          <PaymentsOverview
            isPaymentsOverviewLoading={isPaymentsOverviewLoading}
            paymentsOverview={billsAndPaymentsOverview}
          />
          {!!client.firmMembers?.length && (
            <AvatarGroup
              data-testid="firm-members"
              items={client.firmMembers.map((member) => ({
                name: [member.firstName, member.lastName].join(' ').trim(),
              }))}
            />
          )}
        </>
      }
    />
  );
};
