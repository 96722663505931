import { Container, Group, Icon, Text, Tooltip } from '@melio/penny';

import { PlanCapabilities } from '../../../../hooks';

export type PlanCardFeaturesListProps = { capabilities: PlanCapabilities };

export const PlanCardFeaturesList = ({ capabilities }: PlanCardFeaturesListProps) => (
  <Group spacing="xs" variant="vertical">
    <Container paddingBottom="xxs">
      <Text as="h3" textStyle="body3Semi">
        {capabilities.title}
      </Text>
    </Container>

    <Group as="ul" variant="vertical" spacing="xs">
      {capabilities.list.map((item) => (
        <Group alignItems="center" spacing="xs" key={item.message} as="li">
          <Icon type="checked" size="small" color="brand" />
          <Container justifyContent="space-between">
            <Text textStyle="body3" color="global.neutral.900">
              {item.message}
            </Text>
            {item.tooltipMessage && (
              <Tooltip label={item.tooltipMessage}>
                <Icon type="info" isReadOnly size="small" />
              </Tooltip>
            )}
          </Container>
        </Group>
      ))}
    </Group>
  </Group>
);
