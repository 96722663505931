import { CountryData, Currency, getTransferRequiredFields } from '@melio/international-payments-utils';
import { Money } from '@melio/money';
import {
  Bill,
  BillInfo,
  CountryInternationalDeliveryDetails,
  DeliveryMethod,
  DeliveryMethodType,
  ExchangeRateBase,
  Industry,
  OrganizationBusinessType,
  Payment,
  PaymentIntent,
} from '@melio/platform-api';

import { FX_PROHIBITED_NAICS_CODES } from './fxProhibitedNacisCode';

export const FX_ALLOWED_BUSINESS_TYPES: OrganizationBusinessType[] = [
  OrganizationBusinessType.SoleProprietorship,
  OrganizationBusinessType.LimitedLiabilityCompany,
  OrganizationBusinessType.Corporation,
  OrganizationBusinessType.MunicipalCorporation,
  OrganizationBusinessType.TrustOrEstate,
];

const NAICS_CODE_LENGTH = 6;
const LEGACY_MANUAL_INDUSTRY_NAICS_CODE = 999999;

export const isIndustryShouldPrefilled = (industry?: Industry | null) =>
  industry?.naicsCode?.toString().length === NAICS_CODE_LENGTH &&
  industry.naicsCode !== LEGACY_MANUAL_INDUSTRY_NAICS_CODE;

export const isNaicsCodeAllowedForFX = (naicsCode?: number | null) =>
  !!naicsCode && !FX_PROHIBITED_NAICS_CODES.includes(naicsCode);

export const isBusinessTypeAllowedForFX = (businessType?: OrganizationBusinessType | null) =>
  businessType ? FX_ALLOWED_BUSINESS_TYPES.includes(businessType) : true;

export const convertFxAmountToUsdAmount = ({
  paymentAmount,
  currency,
  fxRate,
}: {
  paymentAmount: number;
  currency?: string;
  fxRate: ExchangeRateBase;
}) => {
  const amountInUsd = Money.fromBaseUnit(paymentAmount, currency ?? 'USD').convert('USD', fxRate.usdToForeignRate);
  return amountInUsd.amount.toNumber();
};

export const isFXCurrency = (currency?: string | null) => !!(currency && currency !== 'USD');

export const isFxBill = (bill?: Bill | BillInfo) => isFXCurrency(bill?.currency);

export const isFxPayment = (payment: Payment) => isFXCurrency(payment.currency);

export const isFxPaymentIntent = (paymentIntent: PaymentIntent) => isFxBill(paymentIntent.billInfo);

export const getDMCurrency = (deliveryMethod?: DeliveryMethod | null) =>
  deliveryMethod?.type === DeliveryMethodType.InternationalAccount ? deliveryMethod.details.currency : 'USD';

export const isFxDM = (deliveryMethod: DeliveryMethod) => isFXCurrency(getDMCurrency(deliveryMethod));

export const fxMemoRegex = /^[A-Za-z0-9\-./?:(), +]*$/;

export const isTransferRequireForCurrency = (currency: Currency) =>
  getTransferRequiredFields(currency).alternatives.length > 0;

export const checkCountryRiskLevel = ({
  country,
  paymentsData,
  hasInvoice,
  vendorId,
}: {
  country: CountryData | CountryInternationalDeliveryDetails | undefined;
  paymentsData: Payment[] | undefined;
  hasInvoice: boolean;
  vendorId?: string;
}) => {
  if (!country || hasInvoice) {
    return false;
  }

  if (country.risk === 'high') {
    return true;
  }

  if (country.risk === 'medium') {
    return !paymentsData?.some((payment) => payment.vendorId === vendorId);
  }

  return false;
};
