import { useMelioIntl, useTaxRates } from '@melio/ar-domain';
// eslint-disable-next-line import/no-deprecated
import { useToast } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { TaxRateFormDialogScreen, TaxRateFormFields } from './screens';

type AddTaxRateDialogActivityProps = {
  onDone: (id: string) => unknown;
  onClose: VoidFunction;
  onError?: ARErrorFunction;
};

export const AddTaxRateDialogActivity = forwardRef<AddTaxRateDialogActivityProps>(
  ({ onDone: onSuccess, onClose, onError, ...props }, ref) => {
    const { create, isCreating } = useTaxRates({ enabled: false });

    const { formatMessage } = useMelioIntl();
    // eslint-disable-next-line import/no-deprecated
    const { toast } = useToast();

    const onSubmit = ({ isDefault = false, ...values }: TaxRateFormFields) =>
      create({ isDefault, ...values })
        .then((item) => {
          onSuccess(item.id);
          toast({
            type: 'success',
            title: formatMessage('ar.settings.activities.addEditTaxRate.toast.onAdd.label', {
              name: item.name,
            }),
          });
        })
        .catch(onError);

    return (
      <TaxRateFormDialogScreen
        data-testid="add-tax-rate-dialog"
        isOpen
        variant="add"
        isSaving={isCreating}
        onClose={onClose}
        onSubmit={onSubmit}
        {...props}
        ref={ref}
      />
    );
  }
);
AddTaxRateDialogActivity.displayName = 'AddTaxRateActivity';
