import { NakedButton, Table } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { BillSubscriptionApprovalDecisionStatusEnum, Payment, PostApprovalDecisionEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

import { usePaymentStatus } from '../../PaymentsTab/components/PaymentsTabStatusCell';

type Props = {
  payment: Payment;
  onApprovalDecisionClick: (payments: Payment[], approvalDecisionStatus: PostApprovalDecisionEnum) => void;
  onViewPayment: (paymentId: Payment['id']) => void;
  isDisabled: boolean;
};

export const ApprovalsTabActionButtonCell = ({
  payment,
  onApprovalDecisionClick,
  onViewPayment,
  isDisabled,
}: Props) => {
  const { track } = useAnalytics();
  const {
    settings: { showApproveActionButton },
  } = useConfig();

  const isPaymentRelatedToPendingApprovalBillSubscription =
    payment.subscriptionOccurrence?.billSubscription?.approvalDecisionStatus ===
    BillSubscriptionApprovalDecisionStatusEnum.Pending;

  const { label, ariaLabel } = useActionButtonLabel({
    payment,
    isPaymentRelatedToPendingApprovalBillSubscription,
  });

  const handleActionClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!showApproveActionButton) {
      track('Dashboard', 'Click', { Cta: 'view', Intent: 'payment-details' });
      onViewPayment(payment.id);
    } else {
      track('Dashboard', 'Click', { Cta: 'approve', Intent: 'approval-workflow' });
      onApprovalDecisionClick([payment], PostApprovalDecisionEnum.Approved);
    }
  };

  return (
    <Table.Cell textAlign="end">
      <NakedButton
        variant="secondary"
        isDisabled={isDisabled}
        size="medium"
        data-testid={`payments-tab-action-cell-${payment.id}`}
        aria-label={ariaLabel}
        onClick={handleActionClick}
        label={label}
      />
    </Table.Cell>
  );
};

const useActionButtonLabel = ({
  payment,
  isPaymentRelatedToPendingApprovalBillSubscription,
}: {
  payment: Payment;
  isPaymentRelatedToPendingApprovalBillSubscription: boolean;
}) => {
  const getPaymentStatus = usePaymentStatus();
  const { label: paymentStatusLabel } = getPaymentStatus(payment);
  const { formatCurrency, formatDate, formatMessage } = useMelioIntl();
  const {
    settings: { showApproveActionButton },
  } = useConfig();

  const ariaLabelData = {
    vendorName: payment.vendor?.name,
    paymentCreatedByName: `${payment.createdBy?.firstName ?? ''} ${payment.createdBy?.lastName ?? ''}`,
    formattedScheduleDate: formatDate(payment.scheduledDate, { dateStyle: 'medium' }),
    formattedPaymentStatusLabel: paymentStatusLabel,
    formattedPaymentAmount: formatCurrency(payment.amount),
  };

  const getLabel = () => {
    if (showApproveActionButton) {
      return isPaymentRelatedToPendingApprovalBillSubscription
        ? formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.approveAll')
        : formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.approve');
    } else {
      return formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.view');
    }
  };

  const getAriaLabel = () => {
    if (showApproveActionButton) {
      return isPaymentRelatedToPendingApprovalBillSubscription
        ? formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.approveAll.ariaLabel', ariaLabelData)
        : formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.approve.ariaLabel', ariaLabelData);
    } else {
      return formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.view.ariaLabel', ariaLabelData);
    }
  };

  return {
    label: getLabel(),
    ariaLabel: getAriaLabel(),
  };
};
