import { useCatalogItems, useMelioIntl } from '@melio/ar-domain';
// eslint-disable-next-line import/no-deprecated
import { useToast } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { CatalogItemFormDialogScreen, CatalogItemFormFields } from './screens';

type AddCatalogItemDialogActivityProps = {
  onDone: (id: string) => unknown;
  onClose: VoidFunction;
  onError?: ARErrorFunction;
};

export const AddCatalogItemDialogActivity = forwardRef<AddCatalogItemDialogActivityProps>(
  ({ onDone: onSuccess, onClose, onError, ...props }, ref) => {
    const { create, isCreating } = useCatalogItems({ enabled: false });

    const { formatMessage } = useMelioIntl();
    // eslint-disable-next-line import/no-deprecated
    const { toast } = useToast();

    const onSubmit = ({ taxable = false, ...values }: CatalogItemFormFields) =>
      create({ ...values, taxable, currency: 'USD' })
        .then((item) => {
          onSuccess(item.id);
          toast({
            type: 'success',
            title: formatMessage('ar.settings.activities.addEditCatalogItem.toast.onAdd.label', {
              name: item.name,
            }),
          });
        })
        .catch(onError);

    return (
      <CatalogItemFormDialogScreen
        data-testid="add-catalog-item-dialog"
        isOpen
        variant="add"
        isSaving={isCreating}
        onClose={onClose}
        onSubmit={onSubmit}
        {...props}
        ref={ref}
      />
    );
  }
);
AddCatalogItemDialogActivity.displayName = 'AddCatalogItemActivity';
