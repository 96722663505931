/* eslint-disable react-hooks/exhaustive-deps */
import { isPOBox } from '@melio/ap-domain';
import { AddressSearchWidget, FormWidgetProps } from '@melio/ap-widgets';
import { Form, useMelioForm } from '@melio/penny';
import { useTrackAnalyticsOnFailedFormSubmit } from '@melio/platform-analytics';
import { Address } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef, useDateUtils } from '@melio/platform-utils';
import { isPast, isValid, parse } from 'date-fns';
import { defaults } from 'lodash';
import { useEffect } from 'react';
import { object, SchemaOf, string } from 'yup';

export type AddOrganizationBeneficialOwnerFormWidgetFields = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  taxId: string;
  address: Address;
};

export type AddOrganizationBeneficialOwnerFormWidgetProps =
  FormWidgetProps<AddOrganizationBeneficialOwnerFormWidgetFields> & {
    setHasErrors: (hasErrors: boolean) => void;
    ownerId: string;
  };

const DATE_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]; // i.e 12/30/1995
const DATE_REGEX = /\d{2}\/\d{2}\/\d{4}/; // i.e 12/30/1995

const TAX_ID_MASK = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]; // i.e 987-65-4321
const TAX_ID_REGEX = /\d{3}-\d{2}-\d{4}/; // i.e 987-65-4321

export const useSchema = () => {
  const { formatMessage } = useMelioIntl();
  const { createDate } = useDateUtils();

  return object().shape({
    firstName: string().required(
      formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.firstName.validation.required`)
    ),
    lastName: string().required(
      formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.lastName.validation.required`)
    ),
    dateOfBirth: string()
      .required(formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.dateOfBirth.validation.required`))
      .matches(DATE_REGEX, formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.dateOfBirth.validation.format`))
      .test(
        'validDate',
        formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.dateOfBirth.validation.format`),
        (v) => {
          if (!v) {
            return false;
          }
          const date = parse(v, 'MM/dd/yyyy', createDate());
          return isValid(date) && isPast(date);
        }
      ),
    taxId: string()
      .required(formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.taxId.validation.required`))
      .matches(TAX_ID_REGEX, formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.taxId.validation.format`)),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    address: object({
      line1: string(),
      line2: string(),
      city: string(),
      state: string(),
      postalCode: string(),
    })
      .nullable()
      .default(null)
      .required(formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.ownerAddress.validation.required`))
      .test(
        'isNotPoBox',
        formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.ownerAddress.validation.poBox`),
        (address: Address) => !isPOBox(address?.line1)
      ),
  }) as SchemaOf<AddOrganizationBeneficialOwnerFormWidgetFields>;
};

export const AddOrganizationBeneficialOwnerFormWidget = forwardRef<
  AddOrganizationBeneficialOwnerFormWidgetProps,
  'form'
>(
  (
    {
      ownerId,
      setHasErrors,
      onSubmit,
      defaultValues: _defaultValues,
      isDisabled,
      isSaving,
      onSubmissionStateChange,
      ...props
    },
    ref
  ) => {
    const defaultValues = defaults(_defaultValues, {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      taxId: '',
      address: null,
    });

    const { formatMessage } = useMelioIntl();
    const { formProps, registerField, formState } = useMelioForm<AddOrganizationBeneficialOwnerFormWidgetFields>({
      onSubmit,
      schema: useSchema(),
      defaultValues,
      isSaving,
      onSubmissionStateChange,
    });

    useTrackAnalyticsOnFailedFormSubmit(formState, 'Organization', 'Status');

    useEffect(() => {
      setHasErrors(Object.keys(formState.errors).length > 0);
    }, [formState.errors]);

    return (
      <Form
        columns={2}
        data-component="AddOrganizationBeneficialOwnerFormWidget"
        ref={ref}
        {...formProps}
        {...props}
        isDisabled={isDisabled || formProps.isDisabled}
      >
        <Form.TextField
          labelProps={{ label: formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.firstName.label`) }}
          {...registerField(`firstName`)}
          id={`firstName_${ownerId}`}
        />
        <Form.TextField
          labelProps={{ label: formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.lastName.label`) }}
          {...registerField('lastName')}
          id={`lastName_${ownerId}`}
        />
        <Form.TextField
          labelProps={{ label: formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.dateOfBirth.label`) }}
          placeholder={formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.dateOfBirth.placeholder`)}
          maskProps={{
            mask: DATE_MASK,
            keepCharPositions: false,
            guide: true,
            placeholderChar: ' ',
          }}
          {...registerField('dateOfBirth')}
          id={`dateOfBirth_${ownerId}`}
        />
        <Form.TextField
          labelProps={{
            label: formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.taxId.label.text`),
            tooltipProps: {
              label: formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.taxId.label.tooltip`),
            },
          }}
          placeholder={formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.taxId.placeholder`)}
          maskProps={{
            mask: TAX_ID_MASK,
          }}
          {...registerField('taxId')}
          id={`taxId_${ownerId}`}
          data-private
        />
        <AddressSearchWidget
          colSpan={2}
          labelProps={{ label: formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.ownerAddress.label`) }}
          placeholder={formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.ownerAddress.placeholder`)}
          helperTextProps={{
            label: formatMessage(`widgets.AddOrganizationBeneficialOwnerForm.ownerAddress.helperText`),
          }}
          {...registerField('address')}
          id={`address_${ownerId}`}
        />
      </Form>
    );
  }
);

AddOrganizationBeneficialOwnerFormWidget.displayName = 'AddOrganizationBeneficialOwnerFormWidget';
