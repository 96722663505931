import { Form } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { ComponentProps } from 'react';

export const VerifiedPersonaTaxIdField = (
  props: Omit<ComponentProps<typeof Form.TextField>, 'labelProps' | 'placeholder'>
) => {
  const { formatMessage } = useMelioIntl();
  return (
    <Form.TextField
      {...props}
      labelProps={{
        label: formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.taxId.label'
        ),
        tooltipProps: {
          label: formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.taxId.tooltip'
          ),
        },
      }}
      placeholder={formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.taxId.placeholder'
      )}
      data-private
    />
  );
};
