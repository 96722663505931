import { apiClient, Collaborator } from '@melio/javascript-sdk';
import { DeleteCollaboratorResponse } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useIsMutating } from 'react-query';

import { useMelioQueryClient, UseModelApiProps } from '../../core';
import { useDeleteMutation } from '../../core/useDeleteMutation';
import { createQueryKey } from '../../core/util';
import { CollaboratorModel } from './types';

export type UseDeleteCollaboratorProps = UseModelApiProps<Collaborator>;
type DeleteModel = Pick<CollaboratorModel, 'queryKey'> & { isDeleting: boolean } & {
  delete: () => Promise<DeleteCollaboratorResponse>;
};

export const useDeleteCollaborator = (props: UseDeleteCollaboratorProps): DeleteModel => {
  const queryClient = useMelioQueryClient();
  const client = apiClient.collaborators();

  const queryKey = createQueryKey({
    queryKey: 'collaborator',
    role: 'collection',
    scope: 'default',
    id: props.id,
  });

  const deleteFn = useDeleteMutation<Collaborator, DeleteCollaboratorResponse>(
    () => client.delete(props.id as string),
    { queryKey },
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries('collaborator'),
          queryClient.invalidateQueries('SubscriptionsApi'),
        ]);
      },
    }
  );

  const isDeleting = useIsMutating(queryKey) > 0;

  return {
    queryKey,
    delete: deleteFn,
    isDeleting,
  };
};
