import { ReactElement, useCallback } from 'react';
import { getAccountingPlatformNameForAnalytics, useAccountingPlatformPollingSync } from '@melio/ap-domain';
import { useAccountingPlatformName } from '@melio/ap-widgets';
import { StatusModal, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { AccountingPlatformSlug } from '@melio/platform-api';
import { useSystemMessage } from '@melio/platform-utils';

import { AccountingPlatformCard as AccountingPlatformCardCl } from '@/cl/components/AccountingPlatformCard/AccountingPlatformCard.component';
import { useFormatSyncDate } from '@/hooks/date.hooks';
import { useDisclosure } from '@/hooks/useDisclosure';
import { APP_EVENTS, emitAppEvent } from '@/queries/event-emitter-query';
import { usePlatformIntl } from '@/translations/Intl';

type Props = {
  logo: ReactElement | null;
  title: string;
  isLoading: boolean;
  errorText?: string;
  organizationId?: string;
  descriptionList: string[];
  lastCompletionTime?: Date | null;
  accountingPlatformId: string;
  accountingPlatformSlug?: AccountingPlatformSlug;
  onDisconnectClicked: () => Promise<void>;
  dialogWillBeShown?: boolean;
};

export const AccountingPlatformConnectedCard = ({
  logo,
  title,
  isLoading: isDisconnectLoading,
  errorText,
  organizationId,
  descriptionList,
  lastCompletionTime,
  accountingPlatformId,
  accountingPlatformSlug,
  onDisconnectClicked,
  dialogWillBeShown,
}: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { formatMessage } = usePlatformIntl();
  const { formatDate } = useFormatSyncDate();
  const { showMessage } = useSystemMessage();
  const { track, trackMarketing } = useAnalytics();
  const accountingPlatformName = useAccountingPlatformName(accountingPlatformSlug);
  const accountingPlatformNameForAnalytics = getAccountingPlatformNameForAnalytics(accountingPlatformSlug);
  const isQuickBooksDesktopAccountingPlatform = accountingPlatformSlug === AccountingPlatformSlug.QuickBooksDesktop;
  const defaultAnalyticsFields = {
    Flow: 'settings',
    Intent: 'disconnect-sync',
    PageName: `disconnect-${accountingPlatformNameForAnalytics}-sync`,
    EntryPoint: 'settings-page',
  };

  const handleSyncDone = useCallback(() => {
    track('SyncAccountingSoftware', 'Status', {
      PageName: 'accounting-software-sync',
      Status: 'success',
    });
    trackMarketing('active-accounting-software-sync', {
      Status: 'success',
    });

    !isDisconnectLoading &&
      showMessage({
        type: 'success',
        title: formatMessage('widgets.syncNow.success.toast', {
          accountingPlatformName,
        }),
      });
  }, [track, trackMarketing, showMessage, formatMessage, isDisconnectLoading, accountingPlatformName]);

  const handleSyncError = useCallback(() => {
    track('SyncAccountingSoftware', 'Status', {
      PageName: 'accounting-software-sync',
      Status: 'failure',
    });
    showMessage({
      type: 'error',
      title: formatMessage('widgets.syncNow.settings.error.toast', {
        accountingPlatformName,
      }),
    });
  }, [track, showMessage, formatMessage, accountingPlatformName]);

  const {
    triggerSync: onSyncNowClicked,
    isRunning: isSyncRunning,
    isError: isSyncError,
  } = useAccountingPlatformPollingSync({
    organizationId: organizationId,
    accountingPlatformId: accountingPlatformId,
    isQuickBooksDesktopAccountingPlatform,
    onSyncDone: handleSyncDone,
    onSyncError: handleSyncError,
  });

  const onDisconnect = useCallback(async () => {
    onClose();
    await onDisconnectClicked();
    emitAppEvent(APP_EVENTS.ACCOUNTING_PLATFORM_DISCONNECTED, {
      accountingPlatformId,
    });
    track('SyncAccountingSoftware', 'Click', {
      ...defaultAnalyticsFields,
      Cta: 'disconnect',
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose, onDisconnectClicked, accountingPlatformId]);

  const onCancel = () => {
    track('SyncAccountingSoftware', 'Click', {
      ...defaultAnalyticsFields,
      Cta: 'cancel',
    });
    onClose();
  };

  const onModalClose = () => {
    track('SyncAccountingSoftware', 'Click', {
      ...defaultAnalyticsFields,
      Cta: 'close',
    });
    onClose();
  };

  const onModalOpen = () => {
    track('SyncAccountingSoftware', 'Click', {
      ...defaultAnalyticsFields,
      Cta: 'disconnect',
    });
    track('SyncAccountingSoftware', 'View', {
      ...defaultAnalyticsFields,
    });
    onOpen();
  };

  const buttons = [
    {
      text: formatMessage('widgets.accountingPlatform.card.buttons.syncNow'),
      onClicked: () => {
        onSyncNowClicked();
      },
      isLoading: isSyncRunning,
      isDisable: isDisconnectLoading || isSyncRunning,
      dataTestId: 'accounting-platform-connect-card-sync-button',
    },
    {
      text: formatMessage('widgets.accountingPlatform.card.buttons.disconnect'),
      onClicked: onModalOpen,
      isLoading: isDisconnectLoading,
      isDisable: isDisconnectLoading || isSyncRunning,
      dataTestId: 'accounting-platform-connected-card-disconnect-button',
      ariaHasPopup: dialogWillBeShown && ('dialog' as const),
    },
  ];
  const syncErrorMessageKey = isQuickBooksDesktopAccountingPlatform
    ? 'widgets.accountingPlatform.card.type.error.type.sync.quickBooksDesktop.alert'
    : 'widgets.accountingPlatform.card.type.error.type.sync.alert';
  const syncErrorMessage = formatMessage(syncErrorMessageKey, {
    accountingPlatformName,
  });
  const syncDate = formatDate(lastCompletionTime || undefined);

  return (
    <>
      <AccountingPlatformCardCl
        title={title}
        descriptionList={descriptionList}
        buttons={buttons}
        logo={logo}
        errorText={isSyncError ? syncErrorMessage : errorText}
        lastSync={syncDate ? formatMessage('widgets.accountingPlatform.date.sync', { syncDate: syncDate }) : undefined}
        accountingPlatformSlug={accountingPlatformSlug}
      />
      <StatusModal
        data-testid="accounting-platform-disconnect-modal"
        isOpen={isOpen}
        onClose={onModalClose}
        header={formatMessage('widgets.accountingPlatform.card.disconnectModal.title', {
          accountingPlatformName,
        })}
        variant="cancel"
        primaryButton={{
          label: formatMessage('widgets.accountingPlatform.card.disconnectModal.disconnectButton'),
          onClick: onDisconnect,
          variant: 'primary',
        }}
        secondaryButton={{
          label: formatMessage('widgets.accountingPlatform.card.disconnectModal.cancelButton'),
          onClick: onCancel,
          variant: 'tertiary',
        }}
      >
        <Text>
          {formatMessage('widgets.accountingPlatform.card.disconnectModal.description', {
            accountingPlatformName,
          })}
        </Text>
      </StatusModal>
    </>
  );
};
