import { FormattedMessage, PayorPaymentRequestDetails, useMelioIntl } from '@melio/ar-domain';
import { Group, SelectionCard, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { useFormattedFee } from '../hooks';
import { GuestPayorFundingSourceTypes } from '../types';

export type FundingSourceSelectionProps = {
  fees: PayorPaymentRequestDetails['fees'];
  selectedFundingSource?: GuestPayorFundingSourceTypes;
  onCardSelected: VoidFunction;
  onBankSelected: VoidFunction;
};

export const FundingSourceSelection = forwardRef<FundingSourceSelectionProps>(
  ({ onBankSelected, onCardSelected, selectedFundingSource, fees }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { getFeeLabel } = useFormattedFee();

    return (
      <Group variant="vertical" spacing="m" ref={ref}>
        <Text textStyle="body2Semi" color="global.neutral.1000">
          <FormattedMessage id="ar.guestPayment.paymentMethods.tabs.title.text" />
        </Text>
        <Group>
          <SelectionCard
            variant="flat"
            icon="credit-card"
            data-testid="card-funding-source-tab"
            mainLabelProps={{
              label: formatMessage('ar.guestPayment.paymentMethods.tabs.card.label'),
            }}
            onClick={onCardSelected}
            isSelected={selectedFundingSource === 'card'}
            descriptionProps={{ label: getFeeLabel(fees?.card) }}
          />
          <SelectionCard
            variant="flat"
            icon="bank"
            data-testid="bank-funding-source-tab"
            mainLabelProps={{
              label: formatMessage('ar.guestPayment.paymentMethods.tabs.bank.label'),
            }}
            onClick={onBankSelected}
            isSelected={selectedFundingSource === 'bank'}
            descriptionProps={{ label: getFeeLabel(fees?.ach) }}
          />
        </Group>
      </Group>
    );
  }
);

FundingSourceSelection.displayName = 'SelectFundingSource';
