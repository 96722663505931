import { getPaymentAnalyticsFields } from '@melio/ap-activities';
import { Link, NakedButton, SectionBanner } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { BankAccount, Card, DeliveryMethodType, Payment } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { usePartnerConfig } from '@/hooks/partners';
import { useRouter } from '@/hooks/router.hooks';
import { FormatMessage, usePlatformIntl } from '@/translations/Intl';

const cardFailureReason = [
  'doNotHonor',
  'invalidTransaction',
  'exceedsWithdrawalAmountLimit',
  'insufficientFunds',
] as const;
type CardPaymentFailureReason = (typeof cardFailureReason)[number];
const bankFailureReason = [
  'insufficientFunds',
  'receiverAccountClosed',
  'noBankAccount',
  'invalidBankAccountNumber',
  'unauthorizedDebitToConsumerAccount',
  'paymentStoppedByVendor',
  'uncollectedFunds',
  'bankAccountUnauthorized',
  'routingNumberIncorrect',
  'accountFrozen',
  'receiverRefusedCreditEntry',
  'userBlockedMelioAccount',
] as const;
type BankAccountPaymentFailureReason = (typeof bankFailureReason)[number];

/**
 * which failure reasons support a failure description.
 */
const reason = {
  card: cardFailureReason,
  'bank-account': bankFailureReason,
};

type DescriptionMessageModel = {
  lastFourDigits: string;
  vendorCompanyName: string | undefined;
};

type Model =
  | null
  | {
      failureReason: CardPaymentFailureReason;
      fundingSourceType: 'card';
      descriptionModel: DescriptionMessageModel;
    }
  | {
      failureReason: BankAccountPaymentFailureReason;
      fundingSourceType: 'bank-account';
      descriptionModel: DescriptionMessageModel;
    };

const getPaymentFailedActionAlertModel = (payment: Payment): Model => {
  const failureReason = payment.paymentFailure?.failureReason;

  if (!failureReason) return null;

  const vendorCompanyName = payment.vendor?.name;

  const fundingSourceType = payment.fundingSource?.type!;
  if (fundingSourceType === 'card' && reason['card'].includes(failureReason as CardPaymentFailureReason)) {
    const { lastFourDigits } = payment.fundingSource?.details as Card;

    return {
      failureReason: failureReason as CardPaymentFailureReason,
      fundingSourceType: 'card' as const,
      descriptionModel: {
        lastFourDigits,
        vendorCompanyName,
      },
    };
  } else if (
    fundingSourceType === 'bank-account' &&
    reason['bank-account'].includes(failureReason as BankAccountPaymentFailureReason)
  ) {
    const { accountNumber } = payment.fundingSource?.details as BankAccount;
    const lastFourDigits = accountNumber?.slice(-4);

    return {
      failureReason: failureReason as BankAccountPaymentFailureReason,
      fundingSourceType: 'bank-account' as const,
      descriptionModel: {
        lastFourDigits,
        vendorCompanyName,
      },
    };
  }

  return null;
};

type DescriptionNodes = {
  supportEmail: React.ReactNode;
  learnMore: React.ReactNode;
  retryDeliverLink: React.ReactNode;
};

const getTitleAndDescription = (
  payment: Payment,
  formatMessage: FormatMessage,
  descriptionNodes: DescriptionNodes,
  isRobinhoodRefundFlowEnabled: boolean,
) => {
  const canRetryDeliver = payment.paymentActions?.retryDeliver.eligible;
  const canRefund = payment.paymentActions?.refund?.eligible;

  if (canRetryDeliver) {
    if (canRefund && isRobinhoodRefundFlowEnabled) {
      return {
        title: formatMessage('widgets.paymentDetails.failed.reason.deliverRefund.title'),
        description: formatMessage('widgets.paymentDetails.failed.reason.deliverRefund.description'),
      };
    }
    return {
      title: formatMessage('widgets.paymentDetails.failed.reason.deliver.title'),
      description: formatMessage('widgets.paymentDetails.failed.reason.deliver.description', {
        retryPayment: descriptionNodes.retryDeliverLink,
      }),
    };
  }

  if (payment.deliveryMethod?.type === DeliveryMethodType.VirtualCard) {
    const message = formatMessage(`widgets.paymentDetails.failed.reason.virtualCard.description`, {
      vendorName: payment.vendor?.name,
    });
    if (message) {
      return { title: '', description: message };
    }
  }

  const model = getPaymentFailedActionAlertModel(payment);
  if (!model) {
    return {
      title: formatMessage('widgets.paymentDetails.failed.reason.general.title'),
      description: formatMessage('widgets.paymentDetails.failed.reason.general.description', {
        supportEmail: descriptionNodes.supportEmail,
      }),
    };
  }

  if (model.fundingSourceType === 'card') {
    return {
      title: formatMessage(`widgets.paymentDetails.failed.reason.card.${model.failureReason}.title`),
      description: formatMessage(`widgets.paymentDetails.failed.reason.card.${model.failureReason}.title`, {
        ...model.descriptionModel,
        ...descriptionNodes,
      }),
    };
  }

  if (model.fundingSourceType === 'bank-account') {
    return {
      title: formatMessage(`widgets.paymentDetails.failed.reason.bank-account.${model.failureReason}.title`, {
        supportPhoneNumber: formatMessage('supportPhoneNumber'),
      }),
      description: formatMessage(
        `widgets.paymentDetails.failed.reason.bank-account.${model.failureReason}.description`,
        {
          ...model.descriptionModel,
          ...descriptionNodes,
        },
      ),
    };
  }

  return { title: '', description: '' };
};

export const PaymentFailedSectionBanner = ({ payment }: { payment: Payment }) => {
  const { track } = useAnalytics();
  const [isRobinhoodRefundFlowEnabled] = useFeature(FeatureFlags.RobinhoodRefundFlow, false);
  const { formatMessage } = usePlatformIntl();
  const { partnerConfig } = usePartnerConfig();
  const { goToRetryFailedToDeliverPayment } = useRouter();
  const failedToCollectSupportLink = partnerConfig.config.links.failedToCollectSupport;
  const supportEmail = (
    <Link href={`mailto:${partnerConfig.supportEmail}`} label={partnerConfig.supportEmail!} newTab />
  );

  const onRetryDeliverLinkClicked = () => {
    track('Dashboard', 'Click', {
      Cta: 'retry-payment',
      Flow: 'dashboard',
      EntryPoint: 'dashboard-inbox',
      FailedDestination: 'deliver-failure',
      ...getPaymentAnalyticsFields(payment),
    });
    goToRetryFailedToDeliverPayment(payment.id);
  };

  const retryDeliverLink = (
    <NakedButton
      variant="secondary"
      onClick={onRetryDeliverLinkClicked}
      data-testid="retry-link"
      label={formatMessage('widgets.paymentDetails.failed.retryPayment')}
    />
  );

  const learnMore = failedToCollectSupportLink ? (
    <Link href={failedToCollectSupportLink} label={formatMessage('widgets.paymentDetails.failed.learnMore')} newTab />
  ) : null;

  const { title, description } = getTitleAndDescription(
    payment,
    formatMessage,
    {
      supportEmail,
      learnMore,
      retryDeliverLink,
    },
    isRobinhoodRefundFlowEnabled,
  );

  return (
    <SectionBanner title={title} description={description} variant="critical" data-testid="payment-failed-alert" />
  );
};
