import { BillSubscription, BillSubscriptionEndPolicyEnum } from '@melio/platform-api';

import { PaymentFlowActivityProps } from '../PaymentFlowActivity/PaymentFlowActivity';

export const useEditBillSubscriptionDefaults = ({
  billSubscription,
}: {
  billSubscription: BillSubscription | undefined;
}) => {
  const defaultValues: PaymentFlowActivityProps['defaultValues'] = {
    recurrenceType: 'recurring',
    vendorId: billSubscription?.vendorId,
    deliveryMethodId: billSubscription?.deliveryMethodId,
    fundingSourceId: billSubscription?.fundingSourceId,
    amountToPay: billSubscription?.amount?.toString(),
    noteToVendor: billSubscription?.memoToVendor,
    intervalType: billSubscription?.intervalType,
    deliveryDate: billSubscription?.nextOccurrence?.deliveryDate || billSubscription?.nextOccurrence?.dueDate,
    startDate: billSubscription?.nextOccurrence?.deliveryDate || billSubscription?.nextOccurrence?.dueDate,
    lastAmount: billSubscription?.lastAmount?.toString() || undefined,
    endPolicy: billSubscription?.endPolicy,
    ...(billSubscription?.endPolicy === BillSubscriptionEndPolicyEnum.NumOfOccurrences && {
      numOfOccurrences: billSubscription?.numOfOccurrences?.toString() || null,
    }),
    ...(billSubscription?.endPolicy === BillSubscriptionEndPolicyEnum.EndDate && {
      endDate: billSubscription?.endDate || null,
    }),
  };

  return {
    defaultValues,
  };
};
