import { usePaymentsLateApprovals } from '@melio/ap-domain';
import { Group, StatusIconOutlined, StatusIconSolid, Text, Tooltip } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const PaymentLateApprovalIndication = ({ payment, iconFirst }: { payment: Payment; iconFirst?: boolean }) => {
  const { formatMessage } = useMelioIntl();
  const getPaymentsLateApprovals = usePaymentsLateApprovals();

  const paymentsLateApprovals = getPaymentsLateApprovals({ payments: [payment] });
  const paymentLateApproval = paymentsLateApprovals[payment.id];
  const isDebitDateAfterToday = !!paymentLateApproval?.isDebitDateAfterToday;

  const getTooltipLabel = () => {
    if (payment.fundingSource?.type === 'bank-account') {
      return isDebitDateAfterToday
        ? formatMessage(
            'activities.payDashboard.paymentsTab.table.cells.debitDate.indication.tooltip.title.lateDebitDate.bank-account'
          )
        : formatMessage(
            'activities.payDashboard.paymentsTab.table.cells.debitDate.indication.tooltip.title.debitDateToday.bank-account'
          );
    } else if (payment.fundingSource?.type === 'card') {
      return isDebitDateAfterToday
        ? formatMessage(
            'activities.payDashboard.paymentsTab.table.cells.debitDate.indication.tooltip.title.lateDebitDate.card'
          )
        : formatMessage(
            'activities.payDashboard.paymentsTab.table.cells.debitDate.indication.tooltip.title.debitDateToday.card'
          );
    }
    return isDebitDateAfterToday
      ? formatMessage(
          'activities.payDashboard.paymentsTab.table.cells.debitDate.indication.tooltip.title.lateDebitDate.default'
        )
      : formatMessage(
          'activities.payDashboard.paymentsTab.table.cells.debitDate.indication.tooltip.title.debitDateToday.default'
        );
  };

  const label = isDebitDateAfterToday
    ? formatMessage('activities.payDashboard.paymentsTab.table.cells.debitDate.indication.lateDebitDate')
    : formatMessage('activities.payDashboard.paymentsTab.table.cells.debitDate.indication.debitDateToday');
  const tooltipLabel = getTooltipLabel();

  const renderIcon = isDebitDateAfterToday ? (
    <StatusIconSolid size="small" variant="warning" data-testid="late-approval-indication-warning-icon" />
  ) : (
    <StatusIconOutlined size="small" variant="neutral" data-testid="late-approval-indication-info-icon" />
  );

  const renderText = (
    <Text textStyle="body4" color="global.neutral.900">
      {label}
    </Text>
  );

  return iconFirst ? (
    <Group variant="horizontal" spacing="xxs" alignItems="center">
      {renderIcon}
      {renderText}
    </Group>
  ) : (
    <Group variant="horizontal" spacing="xxs" alignItems="center">
      {renderText}

      <Tooltip
        label={tooltipLabel}
        preTitle={label}
        triggerAriaLabel={tooltipLabel}
        shouldAddTriggerFocus
        data-testid="payment-late-approval-indication-tooltip"
      >
        {renderIcon}
      </Tooltip>
    </Group>
  );
};
