import { useConfig } from '@melio/platform-provider';
import { useIsFeatureInSubscription, useIsSubscriptionsEnabled } from '@melio/subscriptions';

const feature: Parameters<typeof useIsFeatureInSubscription>[0] = { featureName: 'requestCallbackSupport' };

// could throw an error because of useIsFeatureInSubscription
export const useIsCallbackEligible = () => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const {
    settings: { isRequestCallbackEnabled },
  } = useConfig();
  const { isEligible } = useIsFeatureInSubscription(feature);

  return isSubscriptionsEnabled && !!isRequestCallbackEnabled && isEligible;
};
