/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { noop } from 'lodash';
import { PaymentFlowDoneAction, RecurringPaymentFlowActivity } from '@melio/ap-activities';
import {
  AddBillFormWidgetFields,
  isRecurringFrequency,
  RecurringBaseFields,
  RecurringEndDateFields,
  RecurringNumOfOccurrencesFields,
} from '@melio/ap-widgets';
import { useToast, UseToastOptions } from '@melio/penny';
import { OriginFlow, useAnalytics } from '@melio/platform-analytics';
import { BillSubscription, BillSubscriptionEndPolicyEnum, Currency } from '@melio/platform-api';
import { FormatMessage, useMelioIntl } from '@melio/platform-i18n';
import { SystemMessage, useNavigate } from '@melio/platform-utils';

import { usePaymentFlowIntuitConnect } from '@/hooks/accountingPlatforms.hooks';
import { useRouter } from '@/hooks/router.hooks';
import { useAddNewBillEvents } from '@/hooks/useAddNewBill.hooks';
import { AddBillScreen } from '@/screens/schedule-payment/AddBill.screen';
import { PayDashboardTabs } from '@/types/payDashboard.types';

type AddBillAndSchedulePaymentScreenProps = {
  vendorId?: string;
  deliveryMethodId?: string;
  file?: File;
  amount?: string;
  categoryId?: string | null;
  origin?: OriginFlow;
  returnUrl?: string;
  files?: File[];
  waitingForMessage?: boolean;
};

type PaymentFlowDoneActionParams = { billSubscription: BillSubscription; isPendingApproval: boolean };

type GetToastParams = {
  isPendingApproval: boolean;
  goToPaymentsTab: () => void;
  numOfOccurrences?: null | number;
  formatMessage: FormatMessage;
};

const getToastOptions = ({
  isPendingApproval,
  formatMessage,
  numOfOccurrences,
  goToPaymentsTab,
}: GetToastParams): UseToastOptions => {
  if (isPendingApproval) {
    return {
      type: 'informative',
      title: formatMessage('screens.scheduleSinglePayment.toast.success.recurring.pending.title'),
      action: {
        type: 'button',
        text: formatMessage('screens.scheduleSinglePayment.toast.success.single.action'),
        onAction: goToPaymentsTab,
      },
    };
  }
  const isSingleOccurrence = numOfOccurrences == null || numOfOccurrences === 1;
  const toastTitle = isSingleOccurrence
    ? formatMessage('screens.addBillAndSchedulePayment.toast.success.single.title')
    : formatMessage('screens.addBillAndSchedulePayment.toast.success.multiple.title', {
        numOfOccurrences: numOfOccurrences,
      });
  const toastActionText = formatMessage('screens.batchPayments.toast.success.action', {
    pluralSuffix: isSingleOccurrence ? '' : 's',
  });
  return {
    type: 'success',
    title: toastTitle,
    action: {
      type: 'button',
      text: toastActionText,
      onAction: goToPaymentsTab,
    },
  };
};
export const AddBillAndSchedulePaymentScreen = ({
  vendorId,
  deliveryMethodId,
  returnUrl,
  waitingForMessage,
  file,
  amount,
  categoryId,
  origin,
  files,
}: AddBillAndSchedulePaymentScreenProps) => {
  const { track, trackMarketing } = useAnalytics();
  const navigate = useNavigate();
  const { replaceToPayDashboardTab, generateNPEDashboardLink } = useRouter();
  const { onDone, onClose, onBack } = useAddNewBillEvents({ deliveryMethodId, returnUrl, waitingForMessage });
  const { toast } = useToast();
  const { loginToAccountPlatformAuth, isLoginToAccountPlatformAuthLoading } = usePaymentFlowIntuitConnect({
    redirectMethod: 'replace',
  });

  const { formatMessage } = useMelioIntl();

  const [currentStep, goToStep] = useState<'ADD_BILL_STEP' | 'RECURRING_PAYMENT_STEP'>('ADD_BILL_STEP');
  const [addBillFormState, setAddBillFormState] = useState<AddBillFormWidgetFields>();
  const [flowUuid, setFlowUuid] = useState<string>('');

  const { recurringPaymentEndByPolicy, recurringPaymentEndValue } = useMemo(() => {
    if (!addBillFormState || !isRecurringFrequency(addBillFormState.paymentFrequency)) {
      return { recurringPaymentEndByPolicy: null, recurringPaymentEndValue: null };
    }

    const recurringPaymentEndByPolicy = (addBillFormState as RecurringBaseFields).endPolicy;
    return {
      recurringPaymentEndByPolicy,
      recurringPaymentEndValue:
        recurringPaymentEndByPolicy === BillSubscriptionEndPolicyEnum.EndDate
          ? (addBillFormState as RecurringEndDateFields).endDate?.toISOString()
          : (addBillFormState as RecurringNumOfOccurrencesFields).numOfOccurrences,
    };
  }, [addBillFormState]);

  useEffect(() => {
    setFlowUuid(`${new Date().valueOf()}${Math.floor(Math.random() * 10000000)}`);
  }, [setFlowUuid]);

  const onRecurringFlowDone = useCallback(
    (
      billSubscription: BillSubscription,
      action: PaymentFlowDoneAction = 'goToDashboard',
      isPendingApproval: boolean,
    ) => {
      const handlers: Record<PaymentFlowDoneAction, (params: PaymentFlowDoneActionParams) => void> = {
        goToDashboard: handleGoToDashboard,
        createNewPayment: handleCreateNewPayment,
        syncQBOAccountingPlatform: loginToAccountPlatformAuth,
        openInNewTabDashboard: noop,
        resolveFailedPayments: noop,
      };

      handlers[action]({ billSubscription, isPendingApproval });
    },
    [replaceToPayDashboardTab, navigate, returnUrl],
  );

  const handleGoToDashboard = ({ billSubscription, isPendingApproval }: PaymentFlowDoneActionParams) => {
    if (returnUrl) {
      const goToPaymentsTab = () => {
        navigate(generateNPEDashboardLink('payments'));
      };

      const toastOptions = getToastOptions({
        isPendingApproval,
        formatMessage,
        numOfOccurrences: billSubscription.numOfOccurrences,
        goToPaymentsTab,
      });

      toast(toastOptions);
      return navigate(returnUrl, { state: { keepToast: true } });
    }
    const paymentId = billSubscription?.occurrences?.[0]?.paymentId;
    replaceToPayDashboardTab(PayDashboardTabs.Scheduled, paymentId || undefined);
  };

  const handleCreateNewPayment = () => {
    setAddBillFormState(undefined);
    goToStep('ADD_BILL_STEP');
  };

  const onDoneAddBill = useCallback(
    (
      data: AddBillFormWidgetFields,
      billId?: string,
      target?: 'continue' | 'close' | 'markAsPaid',
      message?: SystemMessage,
    ) => {
      if (isRecurringFrequency(data.paymentFrequency)) {
        const analyticsProps = {
          Amount: Number(data?.billAmount),
          PaymentFrequency:
            data?.paymentFrequency && data?.paymentFrequency.charAt(0).toUpperCase() + data?.paymentFrequency.slice(1),
          DueDate: (data as RecurringNumOfOccurrencesFields | RecurringEndDateFields).startDate.toISOString(),
          Currency: Currency.Usd,
          Note: !!data?.note,
          RecurringPaymentEndBy: recurringPaymentEndByPolicy,
          RecurringPaymentEndValue: recurringPaymentEndValue,
          FlowUid: flowUuid,
        };
        track('AddBillInfo', 'SavedAndContinued', analyticsProps);
        trackMarketing('bill-create-recurring_save-success', analyticsProps);

        setAddBillFormState(data);
        goToStep('RECURRING_PAYMENT_STEP');
      } else {
        onDone(data, billId, target, message);
      }
    },
    [onDone, goToStep, setAddBillFormState],
  );

  switch (currentStep) {
    case 'ADD_BILL_STEP':
      return (
        <AddBillScreen
          onDone={onDoneAddBill}
          vendorId={vendorId}
          origin={origin}
          onBack={onBack}
          onClose={onClose}
          file={file}
          amount={amount}
          categoryId={categoryId}
          files={files}
        />
      );
    case 'RECURRING_PAYMENT_STEP':
      return (
        <RecurringPaymentFlowActivity
          onDone={onRecurringFlowDone}
          onClose={onClose}
          onBack={() => goToStep('ADD_BILL_STEP')}
          billFormFields={addBillFormState as RecurringNumOfOccurrencesFields | RecurringEndDateFields}
          flowUuid={flowUuid}
          isLoading={isLoginToAccountPlatformAuthLoading}
        />
      );
  }
};
