import { Box } from '@chakra-ui/react';
import { Button, Group, IconButton, Popover, Text, Tooltip, useBreakpointValue, useToast } from '@melio/penny';
import { useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { BillsEmailInboxDashboardView, useConfig } from '@melio/platform-provider';

type Props = {
  onCopy?: (value: string) => void;
};

export const InboxEmailAddressCopyLinkWidget = ({ onCopy }: Props) => {
  const { toast } = useToast();
  const { formatMessage } = useMelioIntl();
  const shouldRenderInboxEmailAddress = useBreakpointValue({ m: true, s: false });
  const {
    settings: { billsEmailInboxDashboardView },
  } = useConfig();

  const { data } = useAccount({ id: 'me' });

  const handleCopyToClipboard = (value: string) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        toast({ type: 'informative', title: formatMessage('widgets.inboxEmailAddressCopyLink.toast.success') });
        onCopy?.(value);
      })
      .catch(() => toast({ type: 'error', title: formatMessage('widgets.inboxEmailAddressCopyLink.toast.error') }));
  };

  const inboxEmailAddress = data?.company.inboxEmailAddress;
  if (!inboxEmailAddress) {
    return null;
  }

  if (billsEmailInboxDashboardView === BillsEmailInboxDashboardView.IconAndLink) {
    return (
      <Group variant="horizontal" alignItems="center">
        {shouldRenderInboxEmailAddress && (
          <Text textStyle="body4" color="global.neutral.900">
            {inboxEmailAddress}
          </Text>
        )}
        <Tooltip
          label={formatMessage('widgets.inboxEmailAddressCopyLink.tooltip')}
          data-testid="inbox-email-address-copy-link-tooltip"
        >
          <IconButton
            variant="primary"
            icon="duplicate"
            aria-label={formatMessage('widgets.inboxEmailAddressCopyLink.ariaLabel', {
              email: inboxEmailAddress,
            })}
            onClick={() => handleCopyToClipboard(inboxEmailAddress)}
          />
        </Tooltip>
      </Group>
    );
  }

  return (
    <Text textStyle="body4" color="global.neutral.800" data-testid="inbox-email-address-copy-link-label">
      {formatMessage('widgets.inboxEmailAddressCopyLink.label', {
        link: (
          <Popover
            description={formatMessage('widgets.inboxEmailAddressCopyLink.description', { email: inboxEmailAddress })}
            actionRenderer={({ onClose, ...props }) => (
              <Button
                {...props}
                size="small"
                variant="secondary-inverse"
                label={formatMessage('widgets.inboxEmailAddressCopyLink.cta')}
                onClick={() => {
                  onClose();
                  handleCopyToClipboard(inboxEmailAddress);
                }}
              />
            )}
            placement="top-end"
            data-testid="inbox-email-address-copy-link-popover"
          >
            <Box
              as="span"
              color="global.neutral.1000"
              textDecoration="underline"
              cursor="pointer"
              data-testid="inbox-email-address-copy-link-link"
              _hover={{
                color: 'global.brand.700',
              }}
            >
              {formatMessage('widgets.inboxEmailAddressCopyLink.link')}
            </Box>
          </Popover>
        ),
      })}
    </Text>
  );
};

InboxEmailAddressCopyLinkWidget.displayName = 'InboxEmailAddressCopyLinkWidget';
