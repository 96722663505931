import { BillSubscriptionEndPolicyEnum, useBillSubscription } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSetDocumentTitle } from '@melio/platform-sdk';
import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { SeriesPaymentUpdatedSuccessfullyScreen } from '../../edit-bill-subscription/screens/newPaymentUpdated/SeriesPaymentUpdatedSuccessfully.screen';
import { PaymentFlowLoader } from '../components/PaymentFlowLoader';
import { PaymentFlowActivity } from '../PaymentFlowActivity/PaymentFlowActivity';
import { PaymentFlowFormFields } from '../types';
import { useSubmitErrorHandler } from '../util/useSubmitErrorHandler/useSubmitErrorHandler';
import { useEditBillSubscriptionDefaults } from './useEditBillSubscriptionDefaults';

type EditRecurringActivityProps = {
  billSubscriptionId: string;
  onDone: VoidFunction;
  onClose: VoidFunction;
};

export const EditRecurringActivity = ({ billSubscriptionId, onDone, onClose }: EditRecurringActivityProps) => {
  const { formatMessage } = useMelioIntl();
  const title = formatMessage('activities.paymentFlow.form.header.title.editRecurringPayment');
  const navigate = useNavigate();

  useSetDocumentTitle(title);
  const {
    data: billSubscription,
    _mutations: {
      update: { error: submitError },
    },
    update: updateBillSubscription,
    isMutating: isUpdatingBillSubscription,
    isLoading: isLoadingBillSubscription,
  } = useBillSubscription({
    id: billSubscriptionId,
    params: { expand: ['nextOccurrence', 'vendor', 'fundingSource', 'deliveryMethod', 'occurrences'] },
    onUpdateError: useSubmitErrorHandler(),
  });

  const onSubmit = (data: PaymentFlowFormFields) =>
    updateBillSubscription({
      amount: Number(data.amountToPay),
      fundingSourceId: data.fundingSourceId || void 0,
      deliveryMethodId: data.deliveryMethodId || void 0,
      memoToVendor: data.noteToVendor || void 0,
      intervalType: data.intervalType,
      startDate: data.startDate || void 0,
      debitDate: data.scheduleDate || void 0,
      ...(data.endPolicy === BillSubscriptionEndPolicyEnum.NoEndDate
        ? {
            endPolicy: BillSubscriptionEndPolicyEnum.NoEndDate,
          }
        : data.endPolicy === BillSubscriptionEndPolicyEnum.EndDate
        ? {
            endPolicy: BillSubscriptionEndPolicyEnum.EndDate,
            endDate: data.endDate,
            lastAmount: data.lastAmount ? Number(data.lastAmount) : undefined,
          }
        : {
            endPolicy: BillSubscriptionEndPolicyEnum.NumOfOccurrences,
            numOfOccurrences: data.numOfOccurrences ? Number(data.numOfOccurrences) : undefined,
            lastAmount: data.lastAmount ? Number(data.lastAmount) : undefined,
          }),
    });

  const { defaultValues } = useEditBillSubscriptionDefaults({ billSubscription });

  if (isLoadingBillSubscription) {
    return <PaymentFlowLoader />;
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PaymentFlowActivity
            defaultValues={defaultValues}
            billSubscription={billSubscription}
            title={title}
            isSubmitting={isUpdatingBillSubscription}
            submitError={submitError}
            onSubmit={(data) => onSubmit(data).then(() => navigate('success'))}
            onClose={onClose}
          />
        }
      />
      <Route
        path="/success"
        element={
          billSubscription ? (
            <SeriesPaymentUpdatedSuccessfullyScreen billSubscription={billSubscription} onBackToDashboard={onDone} />
          ) : null
        }
      />
    </Routes>
  );
};
