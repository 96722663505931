import { useInvoicePreference, useMelioIntl } from '@melio/ar-domain';
// eslint-disable-next-line import/no-deprecated
import { Loader, useToast } from '@melio/penny';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import React from 'react';

import { InvoiceSettingsScreen, RemoveCustomPaymentDetailsModalScreen, RemoveInvoiceNoteModalScreen } from './screens';

export type InvoiceSettingsActivityProps = {
  onError?: ARErrorFunction;
  onEditInvoiceFormat: VoidFunction;
  onEditInvoiceNote: VoidFunction;
  onEditCustomPaymentDetails: VoidFunction;
};

export const InvoiceSettingsActivity = forwardRef<InvoiceSettingsActivityProps>(
  ({ onError, onEditInvoiceFormat, onEditInvoiceNote, onEditCustomPaymentDetails }, ref) => {
    const { data, isFetching, isMutating, update } = useInvoicePreference({ onError });
    const [isRemoveNoteModalOpen, removeNoteModalOpen] = useBoolean(false);
    const [isRemoveCustomPaymentDetailsModalOpen, removeCustomPaymentDetailsModalOpen] = useBoolean(false);
    // eslint-disable-next-line import/no-deprecated
    const { toast } = useToast();
    const { formatMessage } = useMelioIntl();

    if (isFetching || !data) {
      return <Loader />;
    }
    const handleOnRemoveNote = () => {
      update({ noteOnInvoice: '' })
        .then(() => {
          removeNoteModalOpen.off();
          toast({
            type: 'success',
            title: formatMessage('ar.settings.flows.invoiceSettings.sections.invoiceNote.removeNote.success.label'),
          });
        })
        .catch((err: ARPlatformError) => {
          toast({
            type: 'error',
            title: formatMessage(
              'ar.settings.flows.invoiceSettings.sections.invoiceNote.removeNote.toast.failure.label'
            ),
          });
          onError?.(err);
        });
    };

    const handleOnRemoveCustomPaymentDetails = () => {
      update({ customPayInstructions: '' })
        .then(() => {
          removeCustomPaymentDetailsModalOpen.off();
          toast({
            type: 'success',
            title: formatMessage(
              'ar.settings.flows.invoiceSettings.sections.paymentOptions.removeCustomPaymentDetails.toast.success.label'
            ),
          });
        })
        .catch((err: ARPlatformError) => {
          toast({
            type: 'error',
            title: formatMessage(
              'ar.settings.flows.invoiceSettings.sections.paymentOptions.removeCustomPaymentDetails.toast.failure.label'
            ),
          });
          onError?.(err);
        });
    };

    const handleOnEditACHPaymentOption = (isEnabled: boolean) => {
      update({ allowACHPayment: isEnabled })
        .then((invoicePreference) => {
          removeCustomPaymentDetailsModalOpen.off();
          toast({
            type: 'success',
            title: formatMessage(
              invoicePreference.allowACHPayment
                ? 'ar.settings.flows.invoiceSettings.sections.paymentOptions.editPaymentOption.toast.activated.success.label'
                : 'ar.settings.flows.invoiceSettings.sections.paymentOptions.editPaymentOption.toast.deactivated.success.label'
            ),
          });
        })
        .catch((err: ARPlatformError) => {
          toast({
            type: 'error',
            title: formatMessage(
              'ar.settings.flows.invoiceSettings.sections.paymentOptions.editPaymentOption.toast.failure.label'
            ),
          });
          onError?.(err);
        });
    };

    const handleOnEditCardPaymentOption = (isEnabled: boolean) => {
      update({ allowCardPayment: isEnabled })
        .then((invoicePreference) => {
          removeCustomPaymentDetailsModalOpen.off();
          toast({
            type: 'success',
            title: formatMessage(
              invoicePreference.allowCardPayment
                ? 'ar.settings.flows.invoiceSettings.sections.paymentOptions.editPaymentOption.toast.activated.success.label'
                : 'ar.settings.flows.invoiceSettings.sections.paymentOptions.editPaymentOption.toast.deactivated.success.label'
            ),
          });
        })
        .catch((err: ARPlatformError) => {
          toast({
            type: 'error',
            title: formatMessage(
              'ar.settings.flows.invoiceSettings.sections.paymentOptions.editPaymentOption.toast.failure.label'
            ),
          });
          onError?.(err);
        });
    };

    return (
      <>
        <InvoiceSettingsScreen
          onEditCustomPaymentDetails={onEditCustomPaymentDetails}
          onRemoveCustomPaymentDetails={() => removeCustomPaymentDetailsModalOpen.on()}
          onEditInvoiceFormat={onEditInvoiceFormat}
          onEditInvoiceNote={onEditInvoiceNote}
          onRemoveInvoiceNote={() => removeNoteModalOpen.on()}
          invoicePreferences={data}
          onToggleACHPaymentsAllowed={handleOnEditACHPaymentOption}
          onToggleCardPaymentsAllowed={handleOnEditCardPaymentOption}
          isUpdating={isMutating}
          ref={ref}
        />
        <RemoveInvoiceNoteModalScreen
          isSubmitting={isMutating}
          isOpen={isRemoveNoteModalOpen}
          onClose={() => removeNoteModalOpen.off()}
          onCloseModal={() => removeNoteModalOpen.off()}
          onSubmit={handleOnRemoveNote}
        />
        <RemoveCustomPaymentDetailsModalScreen
          isSubmitting={isMutating}
          isOpen={isRemoveCustomPaymentDetailsModalOpen}
          onClose={() => removeCustomPaymentDetailsModalOpen.off()}
          onCloseModal={() => removeCustomPaymentDetailsModalOpen.off()}
          onSubmit={handleOnRemoveCustomPaymentDetails}
        />
      </>
    );
  }
);

InvoiceSettingsActivity.displayName = 'InvoiceSettingsActivity';
