import { useIsExternalAccountant } from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';
import { useLocation } from '@melio/platform-utils';
import { Fragment } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useGracePreiodEndPaywall } from '../hooks/useGracePeriodEndPaywall';
import { useIsSubscriptionsEnabled } from '../hooks/useIsSubscriptionsEnabled';
import {
  ContextualPaywallModalActivity,
  GracePeriodModalActivity,
  OnboardingPaywallModalActivity,
  VipWelcomeModalActivity,
} from '../ui';
import {
  ACCOUNTING_CLIENT_SUBSCRIPTION_PLAN_SELECTED_ROUTE,
  ADD_NEW_COMPANY_ROUTE,
  NOT_SUBSCRIBED_ORGS_ALLOWED_ROUTES,
  OWNER_SHOULD_SETUP_SUBSCRIPTION_ROUTE,
} from '../ui/constants';
import { useSubscriptionContext } from './SubscriptionContext';

export const SubscriptionAppFlows = () => {
  const { pathname } = useLocation();
  const {
    accountingClientSelectedSubscriptionPlan,
    subscription,
    gracePeriod: {
      isEligibleForGracePeriod,
      isTodayInGracePeriod,
      isOrgCreatedBeforeGracePeriodStart,
      isUserCreatedBeforeGracePeriodStart,
      isEligibleAndGracePeriodEnded,
    },
  } = useSubscriptionContext();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const isExternalAccountant = useIsExternalAccountant();

  const { can } = usePermissions();

  const canCreateSubscription = can({
    subject: 'subscriptions',
    action: 'create',
  });

  const isNonOwnerAndNoGraceEligibilitySetupRequired =
    isSubscriptionsEnabled && !subscription && !canCreateSubscription && !isEligibleForGracePeriod;

  const { graceEndRedirectRoute, shouldShowGracePeriodEndPaywall } = useGracePreiodEndPaywall();

  const isAccountingClientPlanSelectedByFirm = accountingClientSelectedSubscriptionPlan && !subscription;
  if (
    isAccountingClientPlanSelectedByFirm &&
    !NOT_SUBSCRIBED_ORGS_ALLOWED_ROUTES.some((route) => route.startsWith(pathname))
  ) {
    return <Navigate to={ACCOUNTING_CLIENT_SUBSCRIPTION_PLAN_SELECTED_ROUTE} />;
  }

  const WithSubscriptionActivities =
    isOrgCreatedBeforeGracePeriodStart && isUserCreatedBeforeGracePeriodStart ? (
      <VipWelcomeModalActivity />
    ) : (
      <Fragment />
    );

  const WithoutSubscriptionActivities =
    isEligibleForGracePeriod && isTodayInGracePeriod ? (
      <GracePeriodModalActivity />
    ) : (
      <>
        {isExternalAccountant ? (
          <Navigate to="/subscription/grace/select-client-plan" replace />
        ) : (
          <OnboardingPaywallModalActivity />
        )}
      </>
    );

  const shouldRenderModals =
    isSubscriptionsEnabled &&
    !isAccountingClientPlanSelectedByFirm &&
    !isEligibleAndGracePeriodEnded &&
    !isNonOwnerAndNoGraceEligibilitySetupRequired;

  const isAddCompanyRoute = pathname.startsWith(ADD_NEW_COMPANY_ROUTE);

  return (
    <>
      <Outlet />
      {isNonOwnerAndNoGraceEligibilitySetupRequired && !isAddCompanyRoute && (
        <Navigate to={OWNER_SHOULD_SETUP_SUBSCRIPTION_ROUTE} />
      )}
      {isSubscriptionsEnabled && shouldShowGracePeriodEndPaywall && <Navigate to={graceEndRedirectRoute} replace />}
      {shouldRenderModals && (
        <>
          <ContextualPaywallModalActivity />
          {subscription ? WithSubscriptionActivities : WithoutSubscriptionActivities}
        </>
      )}
    </>
  );
};
