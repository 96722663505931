import {
  ARErrorCode,
  GuestPayorUserTypes,
  RouteElement,
  useGuestPayorPaymentRequestDetails,
  useGuestPayorUserDetails,
  useMelioIntl,
  useUpdatedPaymentRequestLink,
} from '@melio/ar-domain';
import { SectionBannerProps } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { PartnerName } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  AddBankFundingSourceActivity,
  AddCardFundingSourceActivity,
  CustomPaymentActivity,
  GuestPaymentConfirmationActivity,
  GuestPaymentRequestErrorsActivity,
  InvoicePaidActivity,
  PayByBankActivity,
} from '../activities';
import { GuestPaymentLayout } from '../layout';
import { ErrorType } from '../types';
import { useGetPaymentErrorNotification, useGuestPaymentRouterFlow } from './hooks';
import { PaymentsLayoutWrapper } from './PaymentsLayoutWrapper';

export type GuestPaymentRouterFlowProps = {
  paymentRequestLink: string;
  onViewInvoice: VoidFunction;
  partnerName: PartnerName;
  onLoggedIn?: (accessToken: string, refreshToken?: string | null) => Promise<unknown>;
  onUpdatedLink: (updatedPaymentRequestLink: string) => void;
  isNavigateApUsersToDashboardEnabled?: boolean;
};

export const GuestPaymentRouterFlow = forwardRef<GuestPaymentRouterFlowProps>(
  ({
    onLoggedIn,
    onUpdatedLink,
    partnerName,
    paymentRequestLink,
    onViewInvoice,
    isNavigateApUsersToDashboardEnabled,
  }) => {
    const {
      data,
      isLoading,
      isError: isPaymentRequestError,
      error: paymentRequestError,
      isFetched: isPaymentRequestFirstFetchCompleted,
    } = useGuestPayorPaymentRequestDetails({ paymentRequestLink });
    const { data: userDetails, isLoading: isUserDetailsLoading } = useGuestPayorUserDetails({ paymentRequestLink });
    const {
      Paths,
      goToError,
      goToPayByBank,
      goToPaymentConfirmation,
      goToInvoicePaid,
      goToBank,
      goToCard,
      goToCustom,
    } = useGuestPaymentRouterFlow();
    const {
      data: updatedPaymentRequestLink,
      isLoading: isUpdatedLinkLoading,
      error: updatedPaymentRequestLinkError,
    } = useUpdatedPaymentRequestLink({ paymentRequestLink, enabled: isPaymentRequestError });
    const { formatMessage, formatDate } = useMelioIntl();
    const [notificationInfo, setNotificationInfo] = useState<
      SectionBannerProps & { type: 'error' | 'invoice-updated' }
    >();
    const [isPaymentProcessing, setIsPaymentProcessing] = useState<boolean>(false);
    const [isCreatePaymentLoading, setIsCreatePaymentLoading] = useState<boolean>(false);
    const [errorType, setErrorType] = useState<ErrorType | undefined>();

    const getPaymentErrorNotification = useGetPaymentErrorNotification();

    const config = useConfig();
    const generateDeepLinkToPaymentRequestPayDashboard =
      config.settings.guestPaymentFlow?.generateDeepLinkToPaymentRequestPayDashboard;
    const redirectToPayDashboardUrl =
      generateDeepLinkToPaymentRequestPayDashboard && data
        ? generateDeepLinkToPaymentRequestPayDashboard(data.paymentRequestId, {
            paymentRequestDetails: { ref: paymentRequestLink },
            vendorDetails: { name: data.payeeDetails.companyName },
          })
        : undefined;

    useEffect(() => {
      if (updatedPaymentRequestLink) {
        onUpdatedLink(updatedPaymentRequestLink);
      } else if (paymentRequestError && updatedPaymentRequestLinkError) {
        const isCancelledInvoiceError = updatedPaymentRequestLinkError.errorCode === ARErrorCode.InvoiceCancelled;
        setErrorType(isCancelledInvoiceError ? ErrorType.INVOICE_CANCELLED : ErrorType.GENERAL_ERROR);
        goToError();
      }
    }, [updatedPaymentRequestLink, paymentRequestError, updatedPaymentRequestLinkError]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (data?.invoice.editedAt) {
        setNotificationInfo({
          title: formatMessage('ar.guestPayment.notifications.updatedAt.title.text'),
          description: formatMessage('ar.guestPayment.notifications.updatedAt.description.text', {
            updatedAtDate: formatDate(data.invoice.editedAt),
            companyName: data.payeeDetails.companyName,
          }),
          showCloseIcon: true,
          type: 'invoice-updated',
        });
      }
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (!data) return;

      if (data.receivablePaymentDetails) goToInvoicePaid();
    }, [isPaymentRequestFirstFetchCompleted]); // eslint-disable-line react-hooks/exhaustive-deps

    const { track } = useAnalytics();

    if (isUpdatedLinkLoading || isLoading || isUserDetailsLoading) {
      return <GuestPaymentLayout isLoading />;
    }

    const onCreatePayment = (paymentId: string) => {
      goToPaymentConfirmation(paymentId);
    };

    const trackPaymentError = () => {
      track('PaymentRequest', 'Status', {
        ErrorType: 'payment-failed',
      });
    };

    const showErrorNotification = ({ title, description }: { title: string; description: string }) =>
      setNotificationInfo({
        title,
        description,
        variant: 'critical',
        type: 'error',
      });

    const handleBankPaymentError = () => {
      trackPaymentError();
      showErrorNotification(getPaymentErrorNotification('bank'));
    };

    const handleCardPaymentError = () => {
      trackPaymentError();
      showErrorNotification(getPaymentErrorNotification('card'));
    };

    const handlePaymentFormLoading = (isLoading: boolean) => {
      setIsCreatePaymentLoading(isLoading);
    };

    const shouldRedirectToPayDashboard = () =>
      redirectToPayDashboardUrl &&
      isNavigateApUsersToDashboardEnabled &&
      userDetails?.userType === GuestPayorUserTypes.REGISTERED_AND_MATCHED;

    if (shouldRedirectToPayDashboard()) {
      window.location.href = redirectToPayDashboardUrl as string;
      return null;
    }

    return (
      <Routes>
        <Route
          path={Paths.Error}
          element={<GuestPaymentRequestErrorsActivity paymentRequestLink={paymentRequestLink} errorType={errorType} />}
        />
        <Route
          path={Paths.PaymentConfirmation}
          element={
            <RouteElement
              component={GuestPaymentConfirmationActivity}
              pathToProps={{ paymentId: 'paymentId' }}
              paymentRequestLink={paymentRequestLink}
              onViewInvoice={onViewInvoice}
            />
          }
        />
        <Route
          path={Paths.InvoicePaid}
          element={<InvoicePaidActivity paymentRequestLink={paymentRequestLink} onViewInvoice={onViewInvoice} />}
        />
        <Route
          path="*"
          element={
            <PaymentsLayoutWrapper
              notificationInfo={notificationInfo}
              goToCustom={goToCustom}
              goToBank={goToBank}
              goToCard={goToCard}
              goToPayByBank={goToPayByBank}
              onViewInvoice={onViewInvoice}
              isPaymentFormLoading={isCreatePaymentLoading}
              paymentRequestLink={paymentRequestLink}
              isPaymentProcessing={isPaymentProcessing}
            />
          }
        >
          <Route
            path={Paths.Custom}
            element={<CustomPaymentActivity paymentRequestLink={paymentRequestLink} onViewInvoice={onViewInvoice} />}
          />
          <Route
            path={Paths.Bank}
            element={
              <AddBankFundingSourceActivity
                onLoggedIn={onLoggedIn}
                paymentRequestLink={paymentRequestLink}
                onDone={goToPayByBank}
                partnerName={partnerName}
                isNavigateApUsersToDashboardEnabled={isNavigateApUsersToDashboardEnabled}
              />
            }
          />
          <Route
            path={Paths.PayByBank}
            element={
              <RouteElement
                component={PayByBankActivity}
                pathToProps={{ fundingSourceId: 'fundingSourceId' }}
                onCreatePayment={onCreatePayment}
                onError={handleBankPaymentError}
                onFormLoading={handlePaymentFormLoading}
                goToBankRoute={goToBank}
                setIsPaymentProcessing={setIsPaymentProcessing}
                paymentRequestLink={paymentRequestLink}
              />
            }
          />
          <Route
            path={Paths.Card}
            element={
              <AddCardFundingSourceActivity
                onError={handleCardPaymentError}
                onLoggedIn={onLoggedIn}
                partnerName={partnerName}
                paymentRequestLink={paymentRequestLink}
                onFormLoading={handlePaymentFormLoading}
                onCreatePayment={onCreatePayment}
                setIsPaymentProcessing={setIsPaymentProcessing}
                redirectToPayDashboardUrl={redirectToPayDashboardUrl}
                isNavigateApUsersToDashboardEnabled={isNavigateApUsersToDashboardEnabled}
              />
            }
          />
        </Route>
      </Routes>
    );
  }
);
GuestPaymentRouterFlow.displayName = 'GuestPaymentRouterFlow';
