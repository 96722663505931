// eslint-disable-next-line import/no-deprecated
import { useToast } from '@melio/penny';
import {
  NavigateOptions as _NavigateOptions,
  useNavigate as reactRouterUseNavigate,
  // eslint-disable-next-line no-restricted-imports
  useSearchParams as reactRouterUseSearchParams,
} from 'react-router-dom';

import { useSystemMessage } from './system-message';
import { compileUrlTemplate, PathParams } from './url-templte';

/**
 * extends react-router-dom's useNavigate hook to support query params, and callback function return
 * @returns a navigation function or a callback navigation function
 */
export const useNavigate = <TRoute extends string = never, TState = never>({
  withSearchparams,
  closeToastOnNavigate = true,
  __useNavigate = reactRouterUseNavigate,
  __useSearchParams = reactRouterUseSearchParams,
}: UseNavigateOptions<TState> = {}) => {
  const _navigate = __useNavigate();

  const [globalSearchParams] = __useSearchParams();

  // eslint-disable-next-line import/no-deprecated
  const { closeToast } = useToast();
  const { cleanMessage } = useSystemMessage();

  /**
   * Navigate to `path`
   * @param path Path to navigate to
   * @param options NavigateOptions
   * @param options.withSearchparams If true, appends search params to the path (default: false)
   */

  function navigate<TPathParams extends PathParams>(
    path: [TRoute] extends [never] ? string : TRoute,
    _options?: NavigateOptions<TPathParams, TState>
  ): void {
    const {
      historyBack,
      pathParams,
      withSearchparams: localWithSearchparams,
      queryParams: localQueryParams,
      ...options
    } = _options ?? {};
    if (historyBack && history.length > 1) {
      return history.back();
    }

    const [_pathname = '', pathQueryString = ''] = path.split('?');

    const pathname = compileUrlTemplate(_pathname)(pathParams);

    const target = new URLSearchParams();

    if (localWithSearchparams ?? withSearchparams) {
      for (const [key, value] of globalSearchParams.entries()) {
        target.set(key, value);
      }
    }

    for (const [key, value] of new URLSearchParams(pathQueryString).entries()) {
      target.set(key, value);
    }

    if (localQueryParams) {
      Object.entries(localQueryParams).forEach(([key, value]) => {
        target.set(key, value);
      });
    }

    for (const [key, value] of target.entries()) {
      if (!value) {
        target.delete(key);
      }
    }

    if (!options.keepSystemMessage) {
      cleanMessage();
    }

    if (options.closeToast ?? closeToastOnNavigate) {
      closeToast();
    }

    return _navigate({ pathname, search: target.toString() }, options);
  }

  return navigate;
};

export type NavigateOptions<TPathParams extends PathParams = never, TState = never> = Override<
  _NavigateOptions,
  {
    withSearchparams?: boolean;
    historyBack?: boolean;
    pathParams?: TPathParams;
    queryParams?: Record<string, string>;
    state?: TState;
    closeToast?: boolean;
    keepSystemMessage?: boolean;
  }
>;

export type UseNavigateOptions<TState = never> = Pick<NavigateOptions<never, TState>, 'withSearchparams' | 'state'> & {
  __useNavigate?: typeof reactRouterUseNavigate;
  __useSearchParams?: typeof reactRouterUseSearchParams;
  closeToastOnNavigate?: boolean;
  keepSystemMessageOnNavigate?: boolean;
};

export function useFlowRouting<TRoute extends string = never, TState = never>(props?: UseNavigateOptions<TState>) {
  const navigate = useNavigate<TRoute, TState>(props);

  type Params = Parameters<typeof navigate>;
  const createCallback = (path: Params[0]) => (options?: Params[1]) => navigate(path, options);

  return {
    navigate,
    createCallback,
  };
}
