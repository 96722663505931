/* eslint-disable max-lines */
import { Box } from '@chakra-ui/react';
// eslint-disable-next-line import/no-deprecated
import { Container, Group, IconButton, Loader, Text, useBreakpoint, useToast } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useAccount, useFundingSources, useOrganizations } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useLocation } from '@melio/platform-utils';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SubscriptionBillingCycleEnum, useSubscriptionMe, useSubscriptionsPreview } from '../../../api';
import {
  useCreateSubscriptionMarketingTrack,
  useFreeTrialEligibility,
  useIsSubscriptionBillingPayorIsFirm,
  useIsSubscriptionsEnabled,
  usePlanInfo,
  usePlansTiers,
  useRedirects,
  useSubscription,
  useSubscriptionGracePeriod,
  useSubscriptionPreviewItems,
  useSubscriptionPromotions,
  useWizardParams,
} from '../../../hooks';
import { SubscriptionCheckoutErrorBanner } from '../../components';
import { useSubscriptionRouter } from '../../utils';
import { SubscriptionCheckoutSelection } from './components/SubscriptionCheckoutSelection';
import { SubscriptionCheckoutSummary } from './components/SubscriptionCheckoutSummary/SubscriptionCheckoutSummary';

export type SubscriptionCheckoutActivityProps = {
  returnUrl?: string;
  planId: string;
  organizationId?: string;
  onClose: () => void;
};

export const SubscriptionCheckoutActivity = withAnalyticsContext<SubscriptionCheckoutActivityProps>(
  ({ planId, organizationId, setAnalyticsProperties, onClose, returnUrl }) => {
    const [searchParams] = useSearchParams();
    const { promoCodeQueryString, billingCycle } = useWizardParams();
    const [fundingSourceId, setFundingSourceId] = useState<string | null>(searchParams.get('fundingSourceId')); // only when redirected from plaid
    const subscription = useSubscription();
    const { isEligibleForFreeTrial, isFetching: isFetchingFreeTrialEligibilityData } = useFreeTrialEligibility();
    const { isEligibleForGracePeriod, isTodayInGracePeriod } = useSubscriptionGracePeriod();
    const { getPlan, getIsFreePlan } = usePlansTiers();
    const plan = getPlan(planId);
    const { getPlanPromo } = useSubscriptionPromotions();
    const defaultPromoCode = getPlanPromo(plan)?.code || promoCodeQueryString;
    const [promoCode, setPromoCode] = useState<string | undefined>(defaultPromoCode);
    const [cycle, setCycle] = useState<SubscriptionBillingCycleEnum>(
      billingCycle || SubscriptionBillingCycleEnum.Monthly
    );
    const [shouldPreValidatePromoCode, setShouldPreValidatePromoCode] = useState<boolean>(!!defaultPromoCode);
    const [shouldUseFundingSourceFromQueryString, setShouldUseFundingSourceFromQueryString] = useState<boolean>(
      !!searchParams.get('fundingSourceId')
    );

    const { planName } = usePlanInfo(planId);

    const { goToSuccessPage } = useRedirects();
    const { goToSubscriptionPlans } = useSubscriptionRouter();
    const { formatMessage } = useMelioIntl();
    const { data: me } = useAccount({ id: 'me' });
    // eslint-disable-next-line import/no-deprecated
    const { toast } = useToast();
    const {
      create: createSubscription,
      update: updateSubscription,
      isMutating: isSubscriptionMutating,
    } = useSubscriptionMe({ enabled: false });
    const { data: fundingSources, isFetching: isFundingSourcesFetching } = useFundingSources();
    const { data: organizations = [] } = useOrganizations();
    const { data: accountData } = useAccount({ id: 'me' });
    const shouldRedirectToPlansPage = subscription?.planId && !getIsFreePlan(subscription.planId);
    const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
    const isSubscriptionPaidByFirm = useIsSubscriptionBillingPayorIsFirm();
    const {
      data: preview,
      isFetching: isPreviewFetching,
      error: previewError,
    } = useSubscriptionsPreview({
      enabled: isSubscriptionsEnabled && !shouldPreValidatePromoCode,
      params: {
        planId,
        fundingSourceId: fundingSourceId || undefined,
        planCycle: cycle,
        promoCode,
        organizationId,
      },
    });
    const { isExtraSmallScreen, isSmallScreen } = useBreakpoint();
    const isVerticalLayout = isExtraSmallScreen || isSmallScreen;
    const { track } = useAnalytics();
    const { state: locationState } = useLocation<{
      analyticsEntryPoint?: string;
      analyticsFlow?: string;
    }>();
    const { paidSeatsCount } = useSubscriptionPreviewItems({
      subscriptionPreview: preview,
      numberOfFreeUsers: plan?.numberOfFreeUsers,
    });

    const { trackCreateSubscriptionMarketingEvent } = useCreateSubscriptionMarketingTrack({
      cycle,
      isEligibleForFreeTrial,
      isFreePlan: getIsFreePlan(planId),
      planName,
    });

    const currentOrg = organizations.find((org) => org.id === me?.organizationId);

    setAnalyticsProperties({
      PageName: 'checkout',
      Flow: 'subscription',
      Intent: 'subscribe',
      ChosenPlan: planName.toLowerCase(),
    });

    useEffect(() => {
      if (shouldRedirectToPlansPage) {
        return goToSubscriptionPlans({ returnUrl, promoCode });
      }
      if (!shouldUseFundingSourceFromQueryString && fundingSources?.[0]) {
        setFundingSourceId(fundingSources[0].id);
      }
    }, [
      fundingSources,
      goToSubscriptionPlans,
      promoCode,
      returnUrl,
      shouldRedirectToPlansPage,
      shouldUseFundingSourceFromQueryString,
    ]);

    const getClickTrackingProperties = () => {
      const fundingSourceType = fundingSources?.find((source) => source.id === fundingSourceId)?.type;

      return {
        Cta: 'subscribe',
        PaymentMethodType: fundingSourceType,
        NumberOfFS: fundingSources?.length,
        NumberOfChosenSeats: paidSeatsCount,
        Tax: preview?.taxAmount,
        BillingCycle: cycle,
        HasPromo: !!promoCode,
        ...(!!promoCode && {
          PromoValue: Math.abs(preview?.discounts?.[0]?.amount || 0),
          PromoType: 'discount $',
          PromoCreationMethod:
            isEligibleForGracePeriod && isTodayInGracePeriod
              ? 'existing_users_promo'
              : promoCodeQueryString
              ? 'deeplink'
              : 'manually',
        }),
      };
    };

    const trackSubmitStatus = ({
      statusType,
      errorType,
      intent,
      subscriptionId,
    }: {
      statusType: 'success' | 'failure';
      errorType?: string;
      intent?: string;
      subscriptionId?: string;
    }) => {
      track('Organization', 'Status', {
        ...getClickTrackingProperties(),
        SubscriptionId: subscriptionId,
        StatusType: statusType,
        ...(intent && { Intent: intent }),
        ...(statusType === 'failure' && { ErrorType: errorType }),
      });
    };

    const isFreePlan = getIsFreePlan(planId);

    const onUpdateSubscription = () => {
      if (!fundingSourceId) {
        toast({
          id: 'subscription-checkout-error',
          type: 'error',
          title: formatMessage('activities.subscription.checkout.summary.toast.error'),
        });
        return;
      }
      void updateSubscription({ planId, fundingSourceId, promoCode, planCyclePeriod: cycle })
        .then((updatedSubscription) => {
          trackSubmitStatus({ statusType: 'success', subscriptionId: updatedSubscription.id });
          trackCreateSubscriptionMarketingEvent();

          goToSuccessPage();
        })
        .catch(() => {
          const errorMessage = formatMessage('activities.subscription.checkout.summary.toast.error');

          trackSubmitStatus({ statusType: 'failure', errorType: errorMessage, subscriptionId: subscription?.id });

          toast({
            id: 'subscription-checkout-error',
            type: 'error',
            title: errorMessage,
          });
        });
    };

    const onCreateSubscription = () => {
      const intent = isFreePlan ? 'free-tier' : 'subscribe';

      if (!fundingSourceId) {
        toast({ type: 'error', title: formatMessage('activities.subscription.checkout.summary.toast.error') });
        return;
      }
      void createSubscription({
        fundingSourceId,
        planId,
        planCyclePeriod: cycle,
        promoCode,
      })
        .then((newSubscription) => {
          trackSubmitStatus({ statusType: 'success', intent, subscriptionId: newSubscription.id });
          trackCreateSubscriptionMarketingEvent();

          goToSuccessPage();
        })
        .catch(() => {
          const errorMessage = formatMessage('activities.subscription.checkout.summary.toast.error');

          trackSubmitStatus({
            statusType: 'failure',
            errorType: errorMessage,
            intent,
            subscriptionId: subscription?.id,
          });

          toast({ type: 'error', title: formatMessage('activities.subscription.checkout.summary.toast.error') });
        });
    };

    const trackSubmitClick = () => {
      track('Organization', 'Click', {
        ...getClickTrackingProperties(),
      });
    };

    const onSubmit = () => {
      trackSubmitClick();

      if (subscription) {
        onUpdateSubscription();
      } else {
        onCreateSubscription();
      }
    };

    const isLoading = isPreviewFetching || isFundingSourcesFetching || !preview;

    const isDefaultPromo = !!defaultPromoCode && promoCode === defaultPromoCode;

    useAnalyticsView('Organization', true, true, {
      NumberOfSeats: paidSeatsCount,
      NumberOfPaymentMethods: fundingSources?.length,
      isMultiOrg: organizations?.length > 1,
      CompanyType: currentOrg?.companyType,
      EntryPoint: locationState?.analyticsEntryPoint ?? 'plan-selection',
      Flow: locationState?.analyticsFlow,
      IsPaidByFirm: !!isSubscriptionPaidByFirm,
    });

    const onDoneFetchingPromoCodeData = () => setShouldPreValidatePromoCode(false);
    const onDoneAddingCreditCard = () => setShouldUseFundingSourceFromQueryString(false);

    const onBack = () => {
      track('Organization', 'Click', {
        Intent: 'back',
        Cta: 'back',
      });

      return goToSubscriptionPlans({ returnUrl, promoCode });
    };

    const onCloseClick = () => {
      track('Organization', 'Click', {
        Intent: 'close',
        Cta: 'close',
      });

      return onClose();
    };

    if (isFetchingFreeTrialEligibilityData) {
      return (
        <Container paddingY="xxxl" data-testid="subscription-checkout-screen-loader">
          <Loader />
        </Container>
      );
    }

    // TODO ME-68910
    const unsupportedContainerProps = {
      maxWidth: '1200px',
    };

    return (
      <Container
        paddingY="xxs"
        data-testid="subscription-checkout-screen"
        data-component="SubscriptionCheckoutScreen"
        {...unsupportedContainerProps}
      >
        <Group alignItems="center" variant="vertical" width="full" spacing="xxl">
          <Group alignItems="center" justifyContent="space-between" width="full">
            <Group alignItems="center">
              <IconButton
                variant="primary"
                size="large"
                aria-label={formatMessage('app.settings.backButton.ariaLabel')}
                data-testid="back-button"
                icon="arrow-left"
                onClick={onBack}
              />
              <Text as="h1" textStyle="heading1Semi" data-testid="subscription-checkout-screen-title">
                {isEligibleForFreeTrial
                  ? formatMessage('activities.subscription.checkout.titleWithFreeTrial')
                  : formatMessage('activities.subscription.checkout.title', { plan: planName })}
              </Text>
            </Group>
            <IconButton
              variant="primary"
              size="large"
              aria-label={formatMessage('app.settings.backButton.ariaLabel')}
              data-testid="close-button"
              icon="close"
              onClick={onCloseClick}
            />
          </Group>
          <SubscriptionCheckoutErrorBanner error={previewError} />
          <Group variant={isVerticalLayout ? 'vertical' : 'horizontal'} justifyContent="space-between" spacing="l">
            <Box flex={{ xs: 'none', m: 4 }} width="full">
              <SubscriptionCheckoutSelection
                selectedPlan={plan}
                isSubscribing={isSubscriptionMutating}
                fundingSourceId={fundingSourceId}
                setFundingSourceId={setFundingSourceId}
                onDoneAddingCreditCard={onDoneAddingCreditCard}
                cycle={cycle}
                setCycle={setCycle}
              />
            </Box>
            <Box flex={{ xs: 'none', m: 3 }} width="full">
              <SubscriptionCheckoutSummary
                legalAddress={accountData?.company.legalAddress || null}
                preview={preview}
                isLoading={isLoading}
                selectedPlan={plan}
                isSubscribing={isSubscriptionMutating}
                onSubmit={onSubmit}
                promoCode={promoCode}
                shouldPrefetchPromoCode={isDefaultPromo}
                onChangePromoCode={setPromoCode}
                onDoneFetchingPromoCodeData={onDoneFetchingPromoCodeData}
                currentCycle={cycle}
              />
            </Box>
          </Group>
        </Group>
      </Container>
    );
  }
);
