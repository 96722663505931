import { Group, Text } from '@melio/penny';

import { usePlatformIntl } from '@/translations/Intl';

type BankAccountDetailsProps = {
  accountNumber: string | undefined;
  routingNumber: string | undefined;
};

export const BankAccountDetails = ({ accountNumber, routingNumber }: BankAccountDetailsProps) => {
  const { formatMessage } = usePlatformIntl();

  return (
    <Group variant="vertical" spacing="m" width="full">
      <Text textStyle="body2">{formatMessage('widgets.bankDetails.note')}</Text>
      <Group spacing="m" width="full">
        <Group variant="vertical" spacing="xxs" width="full">
          <Text textStyle="body3Semi">{formatMessage('widgets.bankDetails.routingNumber.label')}</Text>
          <Text textStyle="body2">{routingNumber}</Text>
        </Group>
        <Group variant="vertical" spacing="xxs" width="full">
          <Text textStyle="body3Semi">{formatMessage('widgets.bankDetails.accountNumber.label')}</Text>
          <Text textStyle="body2">{accountNumber}</Text>
        </Group>
      </Group>
    </Group>
  );
};
