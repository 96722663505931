import { Group, Icon, Modal, SectionBanner, Text, useFormSubmissionController } from '@melio/penny';
import { Bill } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { PaymentFlowFormFields } from '../../../payment-flow';
import { EditPaymentAmountForm } from './components/EditPaymentAmount.form';

export type EditPaymentAmountModalActivityProps = {
  isOpen: boolean;
  onClose: () => void;
  onDone: (amount?: number) => void;
  bill: Bill;
  isUpdatingAmount?: boolean;
  defaultAmount?: number;
};

export const EditPaymentAmountModalActivity = ({
  isOpen,
  onClose,
  onDone,
  bill,
  isUpdatingAmount,
}: EditPaymentAmountModalActivityProps) => {
  const { formatMessage } = useMelioIntl();
  const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<PaymentFlowFormFields>();

  return (
    <Modal
      isOpen={isOpen}
      isLoading={false}
      onClose={onClose}
      data-testid="edit-payment-amount-modal-screen"
      header={
        <Text as="h2" textStyle="heading2Semi">
          {formatMessage('activities.batchPayments.editPaymentAmount.title')}
        </Text>
      }
      primaryButton={{
        ...submitButtonProps,
        variant: 'primary',
        label: formatMessage('activities.batchPayments.editPaymentAmount.save'),
      }}
      secondaryButton={{
        variant: 'tertiary',
        label: formatMessage('activities.batchPayments.editPaymentAmount.cancel'),
        onClick: onClose,
      }}
    >
      <Group variant="vertical" spacing="m">
        <SectionBanner
          data-testid="edit-ebill-batch-payment-amount-modal-section-banner"
          description={formatMessage('activities.batchPayments.editPaymentAmount.sectionBanner.description')}
          variant="neutral"
          leftElement={<Icon type="info-fill" aria-hidden />}
        />
        <EditPaymentAmountForm
          onSubmit={({ amountToPay }) => {
            if (amountToPay) {
              onDone(+amountToPay);
            }
          }}
          onSubmissionStateChange={onSubmissionStateChange}
          bill={bill}
          isSaving={isUpdatingAmount}
        />
      </Group>
    </Modal>
  );
};

EditPaymentAmountModalActivity.displayName = 'EditPaymentAmountModalActivity';
