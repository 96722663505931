import { useState } from 'react';
import { AccountingPlatformSyncConnectionErrorEnum, SyncFlowEnum } from '@melio/ap-activities';
import { SyncConfirmationModal } from '@melio/ap-widgets';
import { AccountingPlatform, AccountingPlatformSlug } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';

import { useAccountingPlatformDisconnect, useIntuitConnect } from '@/hooks/accountingPlatforms.hooks';
import { AccountingPlatformCard } from './AccountingPlatformCard.widget';
import { usePaymentsSyncSubscriptionFeature } from './usePaymentsSyncSubscriptionFeature';

type Props = {
  syncError?: AccountingPlatformSyncConnectionErrorEnum;
  organizationId: string;
  accountingPlatform: AccountingPlatform;
};

export const AccountingPlatformCardQbo = ({ syncError, organizationId, accountingPlatform }: Props) => {
  const {
    settings: {
      accountingSoftware: { isConnectConfirmationEnabled },
    },
  } = useConfig();

  const { accountingSlug } = accountingPlatform;

  const { loginToQuickBooks, isLoading: isLoadingConnect } = useIntuitConnect(
    SyncFlowEnum.Setting,
    accountingPlatform.id,
  );

  const { isLoading: isLoadingDisconnect, disconnectAccountingPlatform } = useAccountingPlatformDisconnect({
    accountingPlatformId: accountingPlatform.id,
    accountingSlug,
    organizationId,
  });

  const { tryConnectToSync, isEligibleForMorePaymentsSync } = usePaymentsSyncSubscriptionFeature({
    accountingSlug: 'quickBooksOnline',
  });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleCardOnConnectClicked = () => {
    if (isConnectConfirmationEnabled) {
      setIsModalOpen(true);
      return;
    }

    tryConnectToSync({ onFeatureIsEligible: loginToQuickBooks });
  };

  const handleModalOnContinueClicked = () => {
    tryConnectToSync({ onFeatureIsEligible: loginToQuickBooks });
    setIsModalOpen(false);
  };

  if (accountingSlug !== AccountingPlatformSlug.QuickBooksOnline) {
    return null;
  }

  const dialogWillBeShown = !isEligibleForMorePaymentsSync || isConnectConfirmationEnabled;

  return (
    <>
      <AccountingPlatformCard
        accountingPlatform={accountingPlatform}
        onConnectClicked={handleCardOnConnectClicked}
        dialogWillBeShown={dialogWillBeShown}
        onDisconnectClicked={disconnectAccountingPlatform}
        isLoading={isLoadingDisconnect || isLoadingConnect}
        syncError={syncError}
      />
      <SyncConfirmationModal
        accountingSlug={AccountingPlatformSlug.QuickBooksOnline}
        isOpen={isModalOpen}
        isLoading={isLoadingConnect}
        onClose={() => setIsModalOpen(false)}
        onContinue={handleModalOnContinueClicked}
        dialogWillBeShown={dialogWillBeShown}
      />
    </>
  );
};
