import { ReactElement, useCallback, useEffect } from 'react';
import { getAccountingPlatformNameForAnalytics, isQuickBooksDesktopUnavailable } from '@melio/ap-domain';
import { useAccountingPlatformName } from '@melio/ap-widgets';
import { Link, Loader, StatusModal, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { AccountingPlatformSlug, useAccountingPlatformVerifyQuickBooksDesktop } from '@melio/platform-api';

import { AccountingPlatformCard as AccountingPlatformCardCl } from '@/cl/components/AccountingPlatformCard/AccountingPlatformCard.component';
import { usePartnerConfig } from '@/hooks/partners';
import { useDisclosure } from '@/hooks/useDisclosure';
import { APP_EVENTS, emitAppEvent } from '@/queries/event-emitter-query';
import { usePlatformIntl } from '@/translations/Intl';

type Props = {
  name: string;
  logo: ReactElement | null;
  isLoading: boolean;
  accountingPlatformId: string;
  organizationId?: string;
  dialogWillBeShown?: boolean;
  accountingPlatformSlug?: AccountingPlatformSlug;
  onConnectedClicked: () => void;
  onDisconnectClicked: () => Promise<void>;
};

export const AccountingPlatformErrorCard = ({
  name,
  logo,
  isLoading,
  accountingPlatformId,
  organizationId,
  dialogWillBeShown,
  accountingPlatformSlug,
  onConnectedClicked,
  onDisconnectClicked,
}: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { track } = useAnalytics();
  const { formatMessage } = usePlatformIntl();
  const { partnerConfig } = usePartnerConfig();
  const accountingPlatformName = useAccountingPlatformName(accountingPlatformSlug);
  const accountingPlatformNameForAnalytics = getAccountingPlatformNameForAnalytics(accountingPlatformSlug);
  const isQuickBooksDesktopAccountingPlatform = accountingPlatformSlug === AccountingPlatformSlug.QuickBooksDesktop;
  const defaultAnalyticsFields = {
    Flow: 'settings',
    Intent: 'disconnect-sync',
    PageName: `disconnect-${accountingPlatformNameForAnalytics}-sync`,
    EntryPoint: 'settings-page',
  };

  const onDisconnect = useCallback(async () => {
    onClose();
    await onDisconnectClicked();
    emitAppEvent(APP_EVENTS.ACCOUNTING_PLATFORM_DISCONNECTED, {
      accountingPlatformId,
    });
    track('SyncAccountingSoftware', 'Click', {
      ...defaultAnalyticsFields,
      Cta: 'disconnect',
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose, onDisconnectClicked, accountingPlatformId]);

  const onCancel = () => {
    track('SyncAccountingSoftware', 'Click', {
      ...defaultAnalyticsFields,
      Cta: 'cancel',
    });
    onClose();
  };

  const onModalClose = () => {
    track('SyncAccountingSoftware', 'Click', {
      ...defaultAnalyticsFields,
      Cta: 'close',
    });
    onClose();
  };

  const onModalOpen = () => {
    track('SyncAccountingSoftware', 'Click', {
      ...defaultAnalyticsFields,
      Cta: 'disconnect',
    });
    track('SyncAccountingSoftware', 'View', {
      ...defaultAnalyticsFields,
    });
    onOpen();
  };

  const buttons = [
    {
      text: isQuickBooksDesktopAccountingPlatform
        ? formatMessage('widgets.accountingPlatform.card.buttons.resetConnection')
        : formatMessage('widgets.accountingPlatform.card.buttons.reconnect'),
      onClicked: onConnectedClicked,
      isLoading,
      dataTestId: 'accounting-platform-error-card-connect-button',
      ariaHasPopup: dialogWillBeShown && ('dialog' as const),
    },
  ];

  if (isQuickBooksDesktopAccountingPlatform) {
    buttons.push({
      text: formatMessage('widgets.accountingPlatform.card.buttons.disconnect'),
      onClicked: onModalOpen,
      isLoading,
      dataTestId: 'accounting-platform-error-card-disconnect-button',
      ariaHasPopup: dialogWillBeShown && ('dialog' as const),
    });
  }

  useAnalyticsView('SyncAccountingSoftware', true, true, {
    PageName: 'something-went-wrong',
    Flow: `${accountingPlatformNameForAnalytics}-sync`,
    Intent: `sync-to-${accountingPlatformNameForAnalytics}`,
  });

  const {
    isMutating: verifyingQuickBooksDesktop,
    verifyQuickBooksDesktop,
    verifyQuickBooksDesktopData,
  } = useAccountingPlatformVerifyQuickBooksDesktop({
    id: accountingPlatformId,
    enabled: false,
  });

  useEffect(() => {
    if (isQuickBooksDesktopAccountingPlatform) {
      verifyQuickBooksDesktop(organizationId || '');
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQuickBooksDesktopAccountingPlatform]);

  const support = (
    <Link
      href={`mailto:${partnerConfig.supportEmail}`}
      label={formatMessage('widgets.accountingPlatform.card.links.support')}
      newTab
    />
  );

  const isQuickBooksDesktopUnavailableToConnect = isQuickBooksDesktopUnavailable(verifyQuickBooksDesktopData);
  const description = [
    formatMessage('widgets.accountingPlatform.card.type.error.type.invalidCredentials.description', { support }),
  ];
  const error = isQuickBooksDesktopUnavailableToConnect
    ? formatMessage('widgets.accountingPlatform.card.type.error.type.quickBooksDesktop.alert')
    : formatMessage('widgets.accountingPlatform.card.type.error.type.invalidCredentials.alert', {
        accountingPlatformName: name,
      });

  if (verifyingQuickBooksDesktop) {
    return <Loader aria-live="polite" />;
  }

  return (
    <>
      <AccountingPlatformCardCl
        title={name}
        errorText={error}
        descriptionList={description}
        buttons={buttons}
        logo={logo}
        accountingPlatformSlug={accountingPlatformSlug}
      />
      <StatusModal
        data-testid="accounting-platform-disconnect-modal"
        isOpen={isOpen}
        onClose={onModalClose}
        header={formatMessage('widgets.accountingPlatform.card.disconnectModal.title', {
          accountingPlatformName,
        })}
        variant="cancel"
        primaryButton={{
          label: formatMessage('widgets.accountingPlatform.card.disconnectModal.disconnectButton'),
          onClick: onDisconnect,
          variant: 'primary',
        }}
        secondaryButton={{
          label: formatMessage('widgets.accountingPlatform.card.disconnectModal.cancelButton'),
          onClick: onCancel,
          variant: 'tertiary',
        }}
      >
        <Text>
          {formatMessage('widgets.accountingPlatform.card.disconnectModal.description', {
            accountingPlatformName,
          })}
        </Text>
      </StatusModal>
    </>
  );
};
