import {
  useGuestPayorFundingSource,
  useGuestPayorPayment,
  useGuestPayorPaymentRequestDetails,
  useMonitoring,
} from '@melio/ar-domain';
import { useAnalyticsView } from '@melio/platform-analytics';
import { convertDateToStringRepresentation, forwardRef } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import { PayByBankAccountScreen } from './screens';

type PayByBankActivityProps = {
  onCreatePayment: (paymentId: string) => void;
  onError: ARErrorFunction;
  fundingSourceId: string;
  paymentRequestLink: string;
  goToBankRoute: VoidFunction;
  onFormLoading: (isLoading: boolean) => void;
  setIsPaymentProcessing: (isProcessing: boolean) => void;
};

export const PayByBankActivity = forwardRef<PayByBankActivityProps>(
  (
    {
      paymentRequestLink,
      onFormLoading,
      onCreatePayment,
      onError,
      fundingSourceId,
      goToBankRoute,
      setIsPaymentProcessing,
    },
    ref
  ) => {
    const [paymentId, setPaymentId] = useState<string>();

    const handlePaymentCreationError = (error: ARPlatformError) => {
      setIsPaymentProcessing(false);
      onError(error);
    };

    const {
      data: paymentRequestDetails,
      isFetching: isFetchingPaymentRequestDetails,
      refetch: refetchPaymentRequestDetails,
    } = useGuestPayorPaymentRequestDetails({
      paymentRequestLink,
      onError: handlePaymentCreationError,
    });
    const {
      data: fundingSource,
      isLoading: isLoadingFundingSource,
      isError,
    } = useGuestPayorFundingSource({ id: fundingSourceId });
    const {
      createPayment,
      isCreatingPayment,
      isFetching: isFetchingPayment,
      refetch: refetchPaymentData,
    } = useGuestPayorPayment({
      id: paymentId,
      enabled: false,
      onError: handlePaymentCreationError,
    });

    useAnalyticsView('PaymentRequest', true, true, { Intent: 'pay-invoice' });

    const { startAction } = useMonitoring();

    const handleOnSubmit = async ({ scheduledDate }: { scheduledDate: Date }) => {
      try {
        startAction('pay');
        setIsPaymentProcessing(true);
        const { paymentId } = await createPayment({
          fundingSourceId,
          paymentRequestLink,
          scheduledDate: convertDateToStringRepresentation(scheduledDate),
        });
        setPaymentId(paymentId);
        await Promise.all([refetchPaymentRequestDetails(), refetchPaymentData()]);
        onCreatePayment(paymentId);
      } catch (error) {
        handlePaymentCreationError(error as ARPlatformError);
      }
    };

    const isSaving =
      isFetchingPayment || isFetchingPaymentRequestDetails || isLoadingFundingSource || isCreatingPayment;

    useEffect(() => {
      onFormLoading(isSaving);
    }, [isSaving]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isError) {
      goToBankRoute();
    }

    return (
      <PayByBankAccountScreen
        onSubmit={handleOnSubmit}
        fundingSourceName={fundingSource?.displayName}
        logo={fundingSource?.logo}
        isSaving={isSaving}
        amount={paymentRequestDetails?.invoice.totalAmount || 0}
        ref={ref}
      />
    );
  }
);
PayByBankActivity.displayName = 'PayByBankActivity';
