import { Box } from '@chakra-ui/react';
import { Button, IconButton, useTheme } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';
import { useState } from 'react';

type Props = {
  password?: string;
  isLoading: boolean;
  onCopy: (text: string) => void;
};

export const PasswordSection = ({ password, isLoading, onCopy }: Props) => {
  const theme = useTheme();
  const { formatMessage } = useMelioIntl();
  const hiddenPassword = '**********';
  const [value, setValue] = useState<string>(hiddenPassword);
  const isHidden = value === hiddenPassword;

  if (!password) {
    return null;
  }

  const onToggle = () => {
    setValue((prevValue) => (prevValue === hiddenPassword ? password : hiddenPassword));
  };

  return (
    <Box
      p="xs"
      border="surface.default"
      borderRadius="l"
      display="flex"
      width="full"
      alignItems="center"
      backgroundColor={theme.colors.global.neutral['300']}
      justifyContent="space-between"
      data-testid="password-section"
      data-private
    >
      <Box>{value}</Box>
      <Box display="flex">
        <Box display="flex" alignItems="center" px="xs">
          <IconButton
            aria-label="toggle-view"
            icon={isHidden ? 'show' : 'hide'}
            size="large"
            variant="naked"
            onClick={onToggle}
          />
        </Box>
        <Button
          variant="primary"
          onClick={() => onCopy(password)}
          label={formatMessage(
            'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepThree.copyButton'
          )}
          aria-label={formatMessage(
            'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepThree.copyButton.ariaLabel'
          )}
          size="medium"
          isDisabled={isLoading}
          data-testid="copy-password-button"
        />
      </Box>
    </Box>
  );
};
