import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { CreateReportModal } from '@melio/ap-activities';

type SettingsContextValue = {
  openCreateReportModal: () => void;
};

export const SettingsContext = React.createContext<SettingsContextValue>({
  openCreateReportModal: () => undefined,
});

const analytics = {
  name: 'Settings',
  properties: { Flow: 'settings', EntryPoint: 'settings' },
};

type SettingsProviderProps = {
  children: React.ReactNode;
};
export const SettingsProvider = ({ children }: SettingsProviderProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const value = {
    openCreateReportModal: onOpen,
  };

  return (
    <SettingsContext.Provider value={value}>
      {children}
      <CreateReportModal isOpen={isOpen} onClose={onClose} analytics={analytics} />
    </SettingsContext.Provider>
  );
};
