import { useAnalyticsContext } from '@melio/platform-analytics';
import {
  Collaborator as ApiCollaborator,
  Invitation as ApiInvitation,
  PermissionLevelEnum,
  useAccount,
  useCollaborator,
  useCollaborators,
  useIsAccountingFirm,
  useIsExternalAccountant,
} from '@melio/platform-api';
import { CanFunction, usePermissions } from '@melio/platform-permissions';
import { useSubscription, useSubscriptionFeature } from '@melio/subscriptions';

import type { Collaborator, CollaboratorAction, Invitation, InvitationAction } from '../types';
import { usePendingInvitations } from './usePendingInvitations';

const COLLABORATOR_ACTIONS: CollaboratorAction[] = ['owner', 'edit', 'delete'];
const INVITATION_ACTIONS: InvitationAction[] = ['resend', 'cancel'];

export const useManageCollaboratorsData = () => {
  const { can, roles } = usePermissions();
  const { data: actor } = useCollaborator<'user'>({ id: 'me' });
  const {
    data: account,
    isLoading: isAccountLoading,
    isFetching: isAccountFetching,
    isFetched: isAccountFetched,
  } = useAccount({ id: 'me' });
  const {
    data: collaborators,
    isFetching: isCollaboratorsFetching,
    isLoading: isCollaboratorsLoading,
    isFetched: isCollaboratorsFetched,
  } = useCollaborators({ enabled: !!account, expand: ['user'] });
  const isAccountingFirm = useIsAccountingFirm();
  const isExternalAccountant = useIsExternalAccountant();

  const {
    data: invitations,
    isFetching: isInvitationsFetching,
    isLoading: isInvitationsLoading,
    isFetched: isInvitationsFetched,
  } = usePendingInvitations();
  const subscription = useSubscription();

  const currentSeatsCount = (subscription?.measurementInfo.quantity || 0) + (invitations || []).length;

  const collaboratorsEligibility = useSubscriptionFeature({
    featureName: 'collaborators',
    requirements: {
      totalUnits: currentSeatsCount + 1,
    },
  });

  useAnalyticsContext({
    globalProperties: {
      PageEntryPoint: 'settings-page',
      Flow: 'settings',
      UserRole: actor?.roleUniqueName,
    },
  });

  const permissions = {
    invite: can({ action: 'create', subject: 'invitation' }) && !!actor?.roleUniqueName,
    inviteRoleOptions: roles.filter((roleUniqueName) =>
      can({ subject: 'invitation', action: 'create', subjectData: { roleUniqueName } })
    ),
  };

  const collaboratorMapper = createCollaboratorMapper(can);
  const invitationMapper = createInvitationMapper(can);
  return {
    actor: actor ? collaboratorMapper(actor) : undefined,
    collaborators: collaborators?.map(collaboratorMapper),
    invitations: invitations?.map(invitationMapper),
    isFetching: isCollaboratorsFetching || isInvitationsFetching || isAccountFetching,
    isLoading: isCollaboratorsLoading || isInvitationsLoading || isAccountLoading,
    companyName: account?.company.name ?? '',
    actorMinApprovalThreshold: undefined, // TODO: in approval workflows
    permissions,
    isFetched: isCollaboratorsFetched && isInvitationsFetched && isAccountFetched,
    collaboratorsEligibility,
    remainingFreeSeats: Math.max(collaboratorsEligibility.quota.freeUnitsLimit - currentSeatsCount, 0),
    isAccountingFirm,
    isExternalAccountant,
  };
};

const createCollaboratorMapper =
  (can: CanFunction) =>
  (collaborator: ApiCollaborator<'user'>): Collaborator => {
    const { user, ...rest } = collaborator;

    const getAllowedActions = (): CollaboratorAction[] => {
      const isBlocked = collaborator.permissionLevel === PermissionLevelEnum.Blocked;
      if (isBlocked) {
        if (
          can({
            subject: 'collaborator',
            action: 'update',
            subjectData: collaborator,
          }) &&
          can({
            subject: 'collaborator',
            action: 'delete',
            subjectData: collaborator,
          })
        ) {
          return ['approve-accounting-firm'];
        }
      } else {
        return COLLABORATOR_ACTIONS.filter((action) => {
          if (action === 'owner') {
            return can({
              subject: 'collaborator:ownership',
              action: 'update',
              subjectData: collaborator,
            });
          }

          if (action === 'edit') {
            return can({
              subject: 'collaborator',
              action: 'update',
              subjectData: collaborator,
            });
          }

          if (action === 'delete') {
            return can({
              subject: 'collaborator',
              action: 'delete',
              subjectData: collaborator,
            });
          }

          return false;
        });
      }
      return [];
    };

    return {
      ...rest,
      ...user,
      allowedActions: getAllowedActions(),
    };
  };

const createInvitationMapper =
  (can: CanFunction) =>
  (invitation: ApiInvitation): Invitation => {
    const { status, ...rest } = invitation;

    const isExpired = status === 'pending' && rest.expired && rest.expired.getTime() < Date.now();

    const allowedActions: InvitationAction[] = INVITATION_ACTIONS.filter((action) => {
      if (action === 'resend') {
        return can({
          subject: 'invitation',
          action: 'update',
          subjectData: {
            roleUniqueName: invitation.roleUniqueName,
            createdById: invitation.invitedByUserId,
          },
        });
      }

      if (action === 'cancel') {
        return can({
          subject: 'invitation',
          action: 'cancel',
          subjectData: {
            roleUniqueName: invitation.roleUniqueName,
            createdById: invitation.invitedByUserId,
          },
        });
      }

      return false;
    });

    return {
      ...rest,
      status: isExpired ? 'expired' : status,
      allowedActions,
    };
  };
