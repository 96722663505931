import { Box } from '@chakra-ui/react';
import { Button, Container, Divider, Group, Icon, Link, Text } from '@melio/penny';
import {
  AccountingPlatformInitQuickBooksDesktopResponseData,
  AccountingPlatformVerifyQuickBooksDesktopConnectionResponseData,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { SystemMessageDisplay } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { ConnectionScreenCtaType } from '../../types';
import { ErrorBanner } from './components/ErrorBanner';
import { FooterContent } from './components/FooterContent';
import { PasswordSection } from './components/PasswordSection';

type Props = {
  isError: boolean;
  isLoading: boolean;
  isVerifyLoading: boolean;
  verifyQuickBooksDesktopData?: AccountingPlatformVerifyQuickBooksDesktopConnectionResponseData;
  initQuickBooksDesktopData?: AccountingPlatformInitQuickBooksDesktopResponseData;
  onCancel: VoidFunction;
  onCopyPassword: (value?: string) => void;
  onGuideLinkClick: (cta: ConnectionScreenCtaType) => void;
  onVerifyConnection: VoidFunction;
  onDownloadWebConnector: VoidFunction;
};

export const ConnectToQuickBooksDesktopScreen = ({
  isError,
  isLoading,
  isVerifyLoading,
  verifyQuickBooksDesktopData,
  initQuickBooksDesktopData,
  onCancel,
  onCopyPassword,
  onGuideLinkClick,
  onVerifyConnection,
  onDownloadWebConnector,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const { links } = useConfig();

  return (
    <NewSinglePaymentStepLayout
      backgroundColor="white"
      data-component="AccountingPlatformConnectActivity.ConnectToQuickBooksDesktopScreen"
      data-testid="accounting-platform-connect-quickbooks-desktop-screen"
      headerContent={<Box paddingBottom="l" />}
      isStickyFooter
      isLoading={isLoading}
      footerContent={<FooterContent onClose={onCancel} onSubmit={onVerifyConnection} isLoading={isVerifyLoading} />}
    >
      <Container>
        <Group variant="vertical" spacing="s">
          <NewSinglePaymentStepLayout.Title textAlign="start">
            {formatMessage('activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.title')}
          </NewSinglePaymentStepLayout.Title>
          <NewSinglePaymentStepLayout.Description textAlign="start">
            {formatMessage('activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.description')}
          </NewSinglePaymentStepLayout.Description>
          <SystemMessageDisplay data-testid="connect-qbo-notification" />
        </Group>

        {isError && <ErrorBanner verifyQuickBooksDesktopData={verifyQuickBooksDesktopData} />}

        <Container paddingY="m">
          <Box pl="xs">
            <Text as="h2" textStyle="heading3Semi">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepOne.title'
              )}
            </Text>
          </Box>
          <Box pt="s" pb="m">
            <Text textStyle="body2">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepOne.description'
              )}
            </Text>
          </Box>
          <Button
            variant="secondary"
            leftElement={<Icon size="small" type="download" color="inherit" aria-hidden />}
            onClick={onDownloadWebConnector}
            label={formatMessage(
              'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepOne.cta'
            )}
            isDisabled={isVerifyLoading}
            aria-label="download-web-connector-button"
            data-testid="download-web-connector-button"
          />
        </Container>

        <Divider />

        <Container paddingY="m">
          <Box pl="xs">
            <Text as="h2" textStyle="heading3Semi">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepTwo.title'
              )}
            </Text>
          </Box>
          <Box pt="s" pb="m">
            <Text textStyle="body2">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepTwo.description'
              )}
            </Text>
          </Box>
          <Link
            color="secondary"
            href={links['quickBooksDesktopWebConnectorGuide']}
            isBold
            label={formatMessage(
              'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepTwo.cta'
            )}
            variant="standalone"
            data-testid="connecting-melio-and-qbdt-guide-link"
            onClick={() => onGuideLinkClick('connecting-melio-and-qbdt-guide')}
          />
        </Container>

        <Divider />

        <Container paddingY="m">
          <Box pl="xs">
            <Text as="h2" textStyle="heading3Semi">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepThree.title'
              )}
            </Text>
          </Box>
          <Box pt="s" pb="m">
            <Text textStyle="body2">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepThree.description'
              )}
            </Text>
          </Box>
          <PasswordSection
            password={initQuickBooksDesktopData?.password}
            isLoading={isVerifyLoading}
            onCopy={onCopyPassword}
          />
        </Container>

        <Divider />

        <Container paddingY="m">
          <Box pl="xs">
            <Text as="h2" textStyle="heading3Semi">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepFour.title'
              )}
            </Text>
          </Box>
          <Box pt="s">
            <Text textStyle="body2">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepFour.description'
              )}
            </Text>
          </Box>
        </Container>

        <Divider />

        <Container data-testid="accounting-platform-connect-quickbooks-notice" paddingY="m">
          <Box>
            <Text as="h3" textStyle="body3Semi">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.notice.title'
              )}
            </Text>
          </Box>
          <Box>
            <Text textStyle="body3">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.notice.description'
              )}
            </Text>
          </Box>
        </Container>
      </Container>
    </NewSinglePaymentStepLayout>
  );
};
