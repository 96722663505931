import { Container, Pill, Text } from '@melio/penny';
import { PillProps } from '@melio/penny/dist/types/components/dataDisplay/Pill/Pill.types';

export type PlanCardHeaderProps = {
  planName: string;
  planDescription: string;
  planBadge?: Pick<PillProps, 'type' | 'label'>;
};

export const PlanCardHeader = ({ planDescription, planName, planBadge }: PlanCardHeaderProps) => (
  <Container data-testid="subscription-plan-card-header">
    <Container justifyContent="space-between" alignItems="center" paddingBottom="s">
      <Text as="h2" textStyle="heading3Semi">
        {planName}
      </Text>

      {planBadge && <Pill data-testid="subscription-plan-badge" status="brand" {...planBadge} />}
    </Container>

    <Text textStyle="body2">{planDescription}</Text>
  </Container>
);
