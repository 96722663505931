import { Button, Container, Popover } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useIsFeatureInSubscription, useSubscriptionFeature } from '@melio/subscriptions';
import { BooleanFeatureName } from '@melio/subscriptions/src/api';
import React from 'react';

import { PremiumBadge } from '../../../payment-approval-workflow/ApprovalWorkflowFormScreenV2/PremiumBadge';

export const PremiumBadgeWithPopover = ({
  featureName,
  featureDescription,
}: {
  featureName: BooleanFeatureName;
  featureDescription: string;
}) => {
  const { formatMessage } = useMelioIntl();
  const { tryUseFeature: tryUseApprovalWorkflows } = useSubscriptionFeature({
    featureName,
  });
  const { isEligible: isEligibleToFeature } = useIsFeatureInSubscription({
    featureName,
  });
  return !isEligibleToFeature ? (
    <Popover
      data-testid="premium-badge-popover"
      title={formatMessage('activities.subscription.premium-badge.tooltip.title')}
      actionRenderer={({ onClose, ...props }) => (
        <Button
          {...props}
          size="small"
          variant="secondary-inverse"
          label={formatMessage('activities.subscription.premium-badge.tooltip.button')}
          onClick={() => {
            onClose();
            tryUseApprovalWorkflows({ showSystemMessageHandler: () => null });
          }}
        />
      )}
      description={formatMessage('activities.subscription.premium-badge.tooltip.description', {
        featureDescription,
      })}
      triggerEvent="hover"
    >
      <Container
        alignItems="center"
        isSticky
        justifyContent="center"
        role="button"
        width="fit-content"
        data-testid="premium-badge"
      >
        <PremiumBadge featureName={featureName} showOnFreePlan />
      </Container>
    </Popover>
  ) : null;
};
