import { BrowserRouter, Navigate, Outlet, Route, Routes, useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { EbillsImportRedirectPage } from '@melio/ap-activities';
import { PartnerName, useIsAccountingFirm } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { SubscriptionPlansRoute } from '@melio/subscriptions';
import { SubscriptionAppFlows } from '@melio/subscriptions';
import { AccountingClientSubscriptionRoutes } from '@melio/subscriptions';
import { SubscriptionFlowsFullScreen } from '@melio/subscriptions/src/ui';

import { RouterComponentWrapper } from '@/hoc/routerComponentWrapper';
import { useHasAccessToFirm } from '@/hooks/useHasAccessToFirm.hooks';
import { DashboardLayout } from '@/router/layouts/Dashboard.layout';
import { FullScreenLayout } from '@/router/layouts/FullScreen.layout';
import { OnlyFooterLayout } from '@/router/layouts/OnlyFooter.layout';
import { OnlyHeaderLayout } from '@/router/layouts/OnlyHeader.layout';
import { AccountantsRouter } from '@/router/routes/accountants/Accountants.router';
import { AccountingSoftwareRoute } from '@/router/routes/Accountingsoftware.route';
import { AccountsRoute } from '@/router/routes/Accounts.route';
import { AppRedirectRoute } from '@/router/routes/AppRedirect.route';
import { ApprovalWorkflowsRoute } from '@/router/routes/ApprovalWorkflows.route';
import { ARRouter } from '@/router/routes/ar/AR.router';
import { AuthRoute } from '@/router/routes/Auth.route';
import { AuthActionRoute } from '@/router/routes/AuthAction.route';
import { AuthDemoRoute } from '@/router/routes/AuthDemo.route';
import { AuthDemoOpenIdCallback, AuthDemoOpenIdRedirect } from '@/router/routes/AuthDemoOpenId.route';
import { AuthIntuitCallbackRouteError } from '@/router/routes/AuthIntuitCallbackRouteError';
import { AuthIntuitCallbackRouteSuccess } from '@/router/routes/AuthIntuitCallbackRouteSuccess';
import { AutoPaymentActivationRoute } from '@/router/routes/AutoPaymentActivation.route';
import { BatchPaymentsRoute } from '@/router/routes/batchPayments.route';
import { BillingMethodsRoute } from '@/router/routes/BillingMethods.route';
import { BillsRoute } from '@/router/routes/Bills.route';
import { CompleteDetailsRoute } from '@/router/routes/CompleteDetails.route';
import { ContextualOnboardingRoute } from '@/router/routes/ContextualOnboarding.route';
import { EBillsSubscriptionRoute } from '@/router/routes/EBillsSubscription.route';
import { AuthEmbeddedExperienceDemoRoute as EmbeddedExperienceDemoRoute } from '@/router/routes/EmbeddedExperienceDemoRoute';
import { ErrorRoute } from '@/router/routes/Error.route';
import { ExternalEntriesRoute } from '@/router/routes/ExternalEntries.route';
import { FinancingApplicationRoute } from '@/router/routes/FinancingApplication.route';
import { NewPayDashboardRoute } from '@/router/routes/NewPayDashboard.route';
import { NewVendorRoute } from '@/router/routes/NewVendorRoute.route';
import { PayDashboardRoute } from '@/router/routes/PayDashboard.route';
import { PaymentRoute } from '@/router/routes/Payment.route';
import { PaymentMethodsRoute } from '@/router/routes/PaymentMethods.route';
import { PaymentsClassificationRoute } from '@/router/routes/PaymentsClassification.route';
import { ReceivingMethodsRoute } from '@/router/routes/ReceivingMethods.route';
import { SchedulePaymentRoute } from '@/router/routes/SchedulePayment.route';
import { SettingsRoute } from '@/router/routes/Settings.route';
import { StartRoute } from '@/router/routes/Start.route';
import { SubscriptionsGraceRouter } from '@/router/routes/subscriptions/Subscriptions.router';
import { VendorDeliveryMethodRoute } from '@/router/routes/VendorDeliveryMethod.route';
import { VendorsRoute } from '@/router/routes/Vendors.route';
import { WithAnonymousInitialData, WithInitialData, WithTimeoutHandling } from '@/router/utils';
import { getVexAppRoutes } from '@/router/vex.routes';
import { AddCompanyScreen } from '@/screens/add-company/AddCompany.screen';
import { EditBillSubscriptionScreen } from '@/screens/edit-bill-subscription/EditBillSubscription.screen';
import { PaymentRequestEntryPointScreen } from '@/screens/payment-request-entry-point/PaymentRequestEntryPoint.screen';
import { appPathPrefixSelector } from '@/store/app/app.model';
import { NewReviewScannedInvoiceScreen } from '@/widgets/pay-dashboard/review-scanned-invoice/NewReviewScannedInvoiceScreen.widget';
import { ApConfigurationProvider } from './app-providers/ApConfigurationProvider.component';

const RootRoute = () => {
  const [searchParams] = useSearchParams();
  return <Navigate to={`auth?${searchParams.toString()}`} replace />;
};

const DemoRoutes = () => {
  const config = useConfig();
  if (config.production) {
    return <></>;
  }

  return <Outlet />;
};

performance.mark('LD_mark');

const WithAccessToFirm = () => {
  const hasAccessToFirm = useHasAccessToFirm();
  if (!hasAccessToFirm) {
    return null;
  }

  return <Outlet />;
};

const RestrictedToAccountingFirmOnly = () => {
  const isAccountingFirm = useIsAccountingFirm();
  if (!isAccountingFirm) {
    return null;
  }

  return <Outlet />;
};

export const PartnerApp = ({ partnerName }: { partnerName: PartnerName }) => {
  const pathPrefix = useRecoilValue(appPathPrefixSelector);

  return (
    <BrowserRouter basename={`/${pathPrefix}`}>
      <Routes>
        <Route index element={<RootRoute />} />
        {getVexAppRoutes(partnerName)}
        <Route element={<ApConfigurationProvider partnerName={partnerName} />}>
          <Route path="ar/*" element={<ARRouter />} />
          {/* Anonymous routes */}
          <Route element={<WithAnonymousInitialData />}>
            <Route path="start" element={<StartRoute />} />

            <Route element={<OnlyFooterLayout />}>
              <Route path="auth/action" element={<AuthActionRoute />} />
            </Route>

            <Route element={<FullScreenLayout hideTopNav={true} showOrgSwitcher={false} />}>
              <Route path="auth" element={<AuthRoute />} />
              <Route path="redirect" element={<AppRedirectRoute />} />
            </Route>
            <Route element={<OnlyFooterLayout />}>
              <Route path="*" element={<ErrorRoute />} />
            </Route>
          </Route>

          {/* User routes */}

          <Route element={<WithTimeoutHandling />}>
            <Route element={<WithInitialData kycComplianceChecksEnabled />}>
              <Route element={<SubscriptionAppFlows />}>
                <Route element={<DashboardLayout />}>
                  <Route path="settings/*" element={<SettingsRoute />} />
                  <Route path="accounts/*" element={<AccountsRoute />} />
                  <Route element={<WithAccessToFirm />}>
                    <Route path="pay/*" element={<PayDashboardRoute />} />
                    <Route path="vendors/*" element={<VendorsRoute />} />
                    <Route path="payment-classification" element={<PaymentsClassificationRoute />} />
                    <Route path="pay-dashboard/*" element={<NewPayDashboardRoute />} />
                  </Route>
                </Route>

                <Route path="complete-details/*" element={<CompleteDetailsRoute />} />
                <Route path="bills-sync-redirect" element={<EbillsImportRedirectPage />} />
                <Route
                  path="payment-request/:paymentRequestId/pay/*"
                  element={
                    <RouterComponentWrapper
                      Component={PaymentRequestEntryPointScreen}
                      componentProps={{ paymentRequestId: { _pathParam: 'paymentRequestId' } }}
                    />
                  }
                />
                <Route element={<OnlyFooterLayout />}>
                  <Route element={<WithAccessToFirm />}>
                    <Route path="bills/*" element={<BillsRoute />} />
                    <Route path="vendors/new-vendor/*" element={<NewVendorRoute />} />
                    <Route path="vendors/:vendorId/delivery-methods/*" element={<VendorDeliveryMethodRoute />} />
                    <Route path="vendors/:vendorId/ebills-subscription/*" element={<EBillsSubscriptionRoute />} />
                    <Route
                      path="vendors/:vendorId/auto-payment-activation/*"
                      element={<AutoPaymentActivationRoute />}
                    />
                    <Route path="financing/*" element={<FinancingApplicationRoute />} />
                  </Route>
                  <Route path="companies/new-company/*" element={<AddCompanyScreen />} />
                  <Route
                    path="review-draft/new/:scannedInvoiceId"
                    element={
                      <RouterComponentWrapper
                        Component={NewReviewScannedInvoiceScreen}
                        componentProps={{ scannedInvoiceId: { _pathParam: 'scannedInvoiceId' } }}
                      />
                    }
                  />
                  <Route element={<WithAccessToFirm />}>
                    <Route path="schedule-payment/*" element={<SchedulePaymentRoute />} />
                  </Route>
                  <Route element={<WithAccessToFirm />}>
                    <Route path="payment/*" element={<PaymentRoute />} />
                  </Route>
                  <Route
                    path="bill-subscription/:billSubscriptionId/edit/*"
                    element={
                      <RouterComponentWrapper
                        Component={EditBillSubscriptionScreen}
                        componentProps={{ billSubscriptionId: { _pathParam: 'billSubscriptionId' } }}
                      />
                    }
                  />
                  <Route element={<WithAccessToFirm />}>
                    <Route path="batch-payments/:ids" element={<BatchPaymentsRoute />} />
                  </Route>
                  <Route element={<WithAccessToFirm />}>
                    <Route path="onboarding/*" element={<ContextualOnboardingRoute />} />
                    <Route path="approval-workflows/*" element={<ApprovalWorkflowsRoute />} />
                    <Route path="payment-methods/*" element={<PaymentMethodsRoute />} />
                    <Route path="billing-fee/*" element={<BillingMethodsRoute />} />
                    <Route path="receiving-methods/*" element={<ReceivingMethodsRoute />} />
                    <Route path="accounting-software/*" element={<AccountingSoftwareRoute />} />
                  </Route>
                  <Route element={<RestrictedToAccountingFirmOnly />}>
                    <Route path="accountants/*" element={<AccountantsRouter />} />
                  </Route>
                  <Route path="auth/providers/intuit/callback/success" element={<AuthIntuitCallbackRouteSuccess />} />
                  <Route path="auth/providers/intuit/callback/error" element={<AuthIntuitCallbackRouteError />} />
                </Route>
                <Route path="external-entries/*" element={<ExternalEntriesRoute />} />
                <Route element={<FullScreenLayout showOrgSwitcher />}>{AccountingClientSubscriptionRoutes()}</Route>
                {SubscriptionPlansRoute()}
                <Route element={<FullScreenLayout showOrgSwitcher />}>{SubscriptionFlowsFullScreen()}</Route>
                <Route element={<OnlyHeaderLayout />}>
                  <Route path="subscription/grace/*" element={<SubscriptionsGraceRouter />} />
                </Route>
              </Route>
            </Route>
          </Route>

          {/* Demo routes */}
          <Route element={<DemoRoutes />}>
            <Route
              path="embedded-experience"
              element={<RouterComponentWrapper Component={EmbeddedExperienceDemoRoute} componentProps={{}} />}
            />
            <Route
              path="auth/demo/:accountId"
              element={
                <RouterComponentWrapper
                  Component={AuthDemoRoute}
                  componentProps={{
                    accountId: { _pathParam: 'accountId' },
                  }}
                />
              }
            />
            <Route path="auth/demo-openid/callback" element={<AuthDemoOpenIdCallback />} />
            <Route
              path="auth/demo-openid/:accountId"
              element={
                <RouterComponentWrapper
                  Component={AuthDemoOpenIdRedirect}
                  componentProps={{
                    accountId: { _pathParam: 'accountId' },
                  }}
                />
              }
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
