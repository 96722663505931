import {
  addWildcardToRoutes,
  MessageKey,
  useDownloadInvoice,
  useFlowRouting,
  useMelioIntl,
  useNavigate,
  withOutlet,
  withRouteId,
} from '@melio/ar-domain';
// eslint-disable-next-line import/no-deprecated
import { useToast } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import {
  CancelInvoiceModalActivity as _CancelInvoiceModalActivity,
  DeleteInvoiceModalActivity as _DeleteInvoiceModalActivity,
  InvoiceDrawerActivity as _InvoiceDrawerActivity,
  InvoiceTableTabActivity,
  MarkInvoiceAsPaidActivity as _MarkInvoiceAsPaidActivity,
  MarkInvoiceAsUnpaidModalActivity as _MarkInvoiceAsUnpaidModalActivity,
  UpdateMarkInvoiceAsPaidActivity,
} from '../../activities';

const CancelInvoiceModalActivity = withRouteId(_CancelInvoiceModalActivity, 'invoiceId');
const DeleteInvoiceModalActivity = withRouteId(_DeleteInvoiceModalActivity, 'invoiceId');
const InvoiceDrawerActivity = withRouteId(_InvoiceDrawerActivity, 'invoiceId');
const MarkInvoiceAsPaidActivity = withRouteId(_MarkInvoiceAsPaidActivity, 'invoiceId');
const UpdateMarkInvoiceAsPaidModalActivity = withRouteId(UpdateMarkInvoiceAsPaidActivity, 'invoiceId');
const MarkInvoiceAsUnpaidModalActivity = withRouteId(_MarkInvoiceAsUnpaidModalActivity, 'invoiceId');

export type InvoicesTableFlowProps = {
  onError?: ARErrorFunction;
  onPreviewInvoicePDF: (id: string) => unknown;
  onEditInvoice: (id: string) => unknown;
  onSendReminder: (id: string) => unknown;
  createdInvoiceId?: string;
};

export const InvoicesTableFlow = forwardRef<InvoicesTableFlowProps>(
  ({ onPreviewInvoicePDF, createdInvoiceId, onEditInvoice, onSendReminder, onError, ...props }, ref) => {
    const {
      Paths,
      goHome,
      goToCancelInvoice,
      goToDeleteInvoice,
      goToPreviewInvoice,
      goToMarkInvoiceAsPaid,
      goToUpdateMarkAsPaid,
      goToMarkInvoiceAsUnpaid,
    } = useInvoicesTableFlow({ withSearchparams: true });

    const toastMessages = useToastMessages({ onError });

    const { downloadFile } = useDownloadInvoice();

    const onDownloadInvoice = (fileId: string) => {
      toastMessages.onDownloadInvoice();
      downloadFile(fileId);
    };

    const onCopyLink = (link: string) =>
      navigator.clipboard.writeText(link).then(toastMessages.onCopyLink).catch(toastMessages.onCopyLinkFailed);

    return (
      <Routes>
        <Route
          path={Paths.Home}
          element={withOutlet(
            <InvoiceTableTabActivity
              onPreviewInvoicePDF={onPreviewInvoicePDF}
              onDeleteInvoice={goToDeleteInvoice}
              onUpdateMarkAsPaid={goToUpdateMarkAsPaid}
              onCancelInvoice={goToCancelInvoice}
              onDownloadInvoice={onDownloadInvoice}
              onSendReminder={onSendReminder}
              onCopyLink={onCopyLink}
              onEditInvoice={onEditInvoice}
              onPreviewInvoice={goToPreviewInvoice}
              onRowClick={({ rowId }) => goToPreviewInvoice(rowId)}
              selectedInvoiceId={createdInvoiceId}
              onMarkInvoiceAsPaid={goToMarkInvoiceAsPaid}
              {...props}
              ref={ref}
            />
          )}
        >
          <Route
            path={Paths.DeleteInvoice}
            element={<DeleteInvoiceModalActivity onClose={goHome} onError={onError} />}
          />
          <Route
            path={Paths.CancelInvoice}
            element={
              <CancelInvoiceModalActivity
                onClose={goHome}
                onDone={() => goHome({ keepSystemMessage: true })}
                onError={onError}
              />
            }
          />
          <Route
            path={Paths.MarkInvoiceAsPaid}
            element={<MarkInvoiceAsPaidActivity isOpen onClose={goHome} onDone={goHome} onError={onError} />}
          />
          <Route
            path={Paths.UpdateMarkAsPaid}
            element={<UpdateMarkInvoiceAsPaidModalActivity isOpen onClose={goHome} onDone={goHome} onError={onError} />}
          />
          <Route
            path={Paths.MarkInvoiceAsUnpaid}
            element={<MarkInvoiceAsUnpaidModalActivity onClose={goHome} onDone={goHome} onError={onError} />}
          />
          <Route
            path={Paths.ViewInvoiceDrawer}
            element={
              <InvoiceDrawerActivity
                onPreviewInvoicePDF={onPreviewInvoicePDF}
                onDeleteInvoice={goToDeleteInvoice}
                onUpdateMarkAsPaid={goToUpdateMarkAsPaid}
                onCancelInvoice={goToCancelInvoice}
                onDownloadInvoice={onDownloadInvoice}
                onSendReminder={onSendReminder}
                onCopyLink={onCopyLink}
                onEditInvoice={onEditInvoice}
                onMarkInvoiceAsUnpaid={goToMarkInvoiceAsUnpaid}
                onMarkInvoiceAsPaid={goToMarkInvoiceAsPaid}
                onClose={goHome}
              />
            }
          />
        </Route>
      </Routes>
    );
  }
);

enum Paths {
  Home = '',
  DeleteInvoice = ':id/delete',
  CancelInvoice = ':id/cancel',
  ViewInvoiceDrawer = ':id/view',
  MarkInvoiceAsPaid = ':id/mark-as-paid',
  UpdateMarkAsPaid = ':id/mark-as-paid/edit',
  MarkInvoiceAsUnpaid = ':id/mark-as-unpaid',
}

export const useInvoicesTableFlow = (...args: Parameters<typeof useNavigate<Paths>>) => {
  const { navigate, createCallback } = useFlowRouting<Paths>(...args);
  const createNavigateWithId = (path: Paths) => (id: string) => navigate(path, { pathParams: { id } });

  const goToPreviewInvoice = createNavigateWithId(Paths.ViewInvoiceDrawer);
  const goToDeleteInvoice = createNavigateWithId(Paths.DeleteInvoice);
  const goToCancelInvoice = createNavigateWithId(Paths.CancelInvoice);
  const goHome = createCallback(Paths.Home);
  const goToMarkInvoiceAsPaid = createNavigateWithId(Paths.MarkInvoiceAsPaid);
  const goToUpdateMarkAsPaid = createNavigateWithId(Paths.UpdateMarkAsPaid);
  const goToMarkInvoiceAsUnpaid = createNavigateWithId(Paths.MarkInvoiceAsUnpaid);

  return {
    RedirectPaths: Paths,
    Paths: addWildcardToRoutes(Paths),
    goToPreviewInvoice,
    goToDeleteInvoice,
    goToCancelInvoice,
    goToMarkInvoiceAsUnpaid,
    goHome,
    goToMarkInvoiceAsPaid,
    goToUpdateMarkAsPaid,
  };
};

const useToastMessages = (props: { onError?: ARErrorFunction } = {}) => {
  const { formatMessage } = useMelioIntl();
  // eslint-disable-next-line import/no-deprecated
  const { toast } = useToast();
  const info = (message: MessageKey) => () => toast({ type: 'informative', title: formatMessage(message) });
  const onError = (err: ARPlatformError) => {
    toast({ type: 'error', title: err.message });
    props.onError?.(err);
  };
  return {
    onError,
    onDownloadInvoice: info('ar.dashboard.activities.invoiceTable.toasts.download.processing.label'),
    onCopyLink: info('ar.dashboard.activities.invoiceTable.toasts.copyLink.success.label'),
    onCopyLinkFailed: info('ar.dashboard.activities.invoiceTable.toasts.copyLink.failure.label'),
  };
};
