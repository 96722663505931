import { ARInvoice, useCustomers } from '@melio/ar-domain';
// eslint-disable-next-line import/no-deprecated
import { useToast } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { InvoiceActionsHandlers, invoices_screen_id, invoices_tab_panel_id } from '../../utils';
import { useInvoicesFiltering } from './hooks';
import { InvoicesTabScreen } from './screens';

export type InvoiceTableTabActivityProps = {
  onDeleteInvoice: (id: string) => unknown;
  onMarkInvoiceAsPaid: (id: string) => unknown;
  onUpdateMarkAsPaid: (id: string) => unknown;
  onSendReminder: (id: string) => unknown;
  onPreviewInvoicePDF: (fileId: string) => unknown;
  onCancelInvoice: (id: string) => unknown;
  onEditInvoice: (id: string) => unknown;
  onDownloadInvoice: (fileId: string) => unknown;
  onPreviewInvoice: (id: string) => unknown;
  onCopyLink: (link: string) => unknown;
  onRowClick?: (params: { rowId: string; rowData: ARInvoice }) => void;
  selectedInvoiceId?: string;
  onError?: ARErrorFunction;
};

export const InvoiceTableTabActivity = forwardRef<InvoiceTableTabActivityProps>(
  (
    {
      onError,
      selectedInvoiceId,
      onRowClick,
      onDeleteInvoice,
      onMarkInvoiceAsPaid,
      onUpdateMarkAsPaid,
      onSendReminder,
      onPreviewInvoicePDF,
      onPreviewInvoice,
      onCancelInvoice,
      onEditInvoice,
      onDownloadInvoice,
      onCopyLink,
    },
    ref
  ) => {
    const { invoiceStatus, invoices, pagination, isFetching, sortParams, setParams, search, error } =
      useInvoicesFiltering();
    // eslint-disable-next-line import/no-deprecated
    const { toast } = useToast();

    useEffect(() => {
      if (error) {
        toast({ type: 'error', title: error.message });
        onError?.(error);
      }
    }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

    // preload customers to display in table
    const { isLoading: isCustomersLoading } = useCustomers();

    const invoiceActions: InvoiceActionsHandlers = {
      onSendReminder,
      onDeleteInvoice,
      onMarkInvoiceAsPaid,
      onUpdateMarkAsPaid,
      onPreviewInvoicePDF,
      onDownloadInvoice,
      onCopyLink,
      onCancelInvoice,
      onEditInvoice,
      onPreviewInvoice,
    };

    return (
      <InvoicesTabScreen
        invoices={invoices}
        pagination={pagination}
        isFetching={isFetching || isCustomersLoading}
        onRowClick={onRowClick}
        onParamsChange={setParams}
        invoiceStatus={invoiceStatus}
        search={search}
        sortParams={sortParams}
        invoiceActions={invoiceActions}
        selectedInvoiceId={selectedInvoiceId}
        id={invoices_screen_id}
        aria-labeledby={invoices_tab_panel_id}
        ref={ref}
      />
    );
  }
);
InvoiceTableTabActivity.displayName = 'InvoiceTableScreen';
