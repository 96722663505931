import { getFundingSourceIconOrBrandSymbol } from '@melio/ap-widgets';
import { Group, ListItem } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import React from 'react';

import { useFundingSourceCardProps } from './useFundingSourceCardProps';

export const FundingSourceCard = ({ fundingSource }: { fundingSource: FundingSource }) => {
  const icon = getFundingSourceIconOrBrandSymbol(fundingSource);
  const { title, line1, line2 } = useFundingSourceCardProps({ fundingSource });

  return (
    <Group alignItems="center" spacing="s" data-testid="funding-source-card">
      {icon}
      <ListItem
        mainLabelProps={{
          label: title,
          variant: 'bold',
        }}
        labelProps={line1 ? { label: line1 } : undefined}
        descriptionProps={line2 ? { label: line2 } : undefined}
      />
    </Group>
  );
};
