import { PropsWithChildren } from 'react';
import { Box } from '@chakra-ui/react';
import { Button, Icon, Layout } from '@melio/penny';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { useRouter } from '@/hooks/router.hooks';
import { useIsMobile } from '@/hooks/useIsMobile';
import { usePlatformIntl } from '@/translations/Intl';
import { CompanyCardListTheme } from './AccountCardList.component.theme';

type Props = PropsWithChildren<{
  title: string;
  onAddButtonClick?: VoidFunction;
}>;

export const AccountCardList = ({ title, onAddButtonClick, children }: Props) => {
  const styles = useAppTheme(CompanyCardListTheme, {});
  const { formatMessage } = usePlatformIntl();
  const isMobile = useIsMobile();
  const { goToAddNewCompany } = useRouter();
  const [isAddCompanyEnabled] = useFeature(FeatureFlags.AddCompanyEnabled, false);

  const onAddNewCompanyButtonClick = () => {
    goToAddNewCompany({ entryPoint: 'companies-dashboard' });
    onAddButtonClick?.();
  };

  return (
    <Layout>
      <Box sx={styles['container']}>
        <Box sx={styles['title']}>
          {title}
          {isAddCompanyEnabled ? (
            <Button
              leftElement={<Icon size="small" type="add" color="inherit" aria-hidden />}
              data-testid="add-company-button"
              size={isMobile ? 'small' : 'medium'}
              variant="primary"
              label={formatMessage('app.accountsDashboard.addNewCompanyButton')}
              onClick={onAddNewCompanyButtonClick}
            />
          ) : null}
        </Box>
        <Box sx={styles['listContainer']}>{children}</Box>
      </Box>
    </Layout>
  );
};
