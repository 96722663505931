/* eslint-disable max-lines */
// eslint-disable-next-line import/no-deprecated
import { useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSetDocumentTitle } from '@melio/platform-sdk';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Plan, SubscriptionBillingCycleEnum, useSubscriptionMe } from '../../../api';
import { useSubscriptionContext } from '../../../context';
import {
  usePartnerGroups,
  usePendingSubscription,
  usePlansTiers,
  useRedirects,
  useSubscribeToFreePlan,
  useSubscription,
  useSubscriptionGracePeriod,
  useSubscriptionPlan,
  useWizardParams,
} from '../../../hooks';
import { useReactivatePlan } from '../../../hooks/useReactivatePlan';
import { ReactivatePlanModal } from '../../components';
import { RejectAccountingClientDiscountModal } from '../../components/RejectAccountingClientDiscountModal/RejectAccountingClientDiscountModal';
import { DEFAULT_DASHBOARD_ROUTE, SUBSCRIPTION_SETTINGS_PLANS_ROUTE } from '../../constants';
import { useSubscriptionRouter } from '../../utils';
import { CancelOrDowngradeFlowActivity } from '../CancelOrDowngradeFlow';
import { RequestPlanChangeFromAccountantModalScreen } from '../RequestPlanChangeFromAccountant';
import { SubscriptionGraceTimeToDecideFlow } from '../SubscriptionGraceTimeToDecideFlow/SubscriptionGraceTimeToDecideFlow';
import { SubscriptionUpgradeModalActivity } from '../SubscriptionUpgradeModal';
import { SwitchSubscriptionBillingCycleModalScreen } from '../SwitchSubscriptionCycle';
import { useManagePlanPermissions } from './hooks';
import { SubscriptionPlansScreen, SubscriptionPlansVariant } from './screens/SubscriptionPlans.screen';
import { SubscribeToPlanType } from './types';

enum ActionModalType {
  DOWNGRADE_PLAN = 'DOWNGRADE_PLAN',
  UPGRADE_PLAN = 'UPGRADE_PLAN',
  SWITCH_BILLING_CYCLE = 'SWITCH_BILLING_CYCLE',
  REACTIVATE_CURRENT_PLAN = 'REACTIVATE_CURRENT_PLAN',
  GRACE_TIME_TO_DECIDE = 'GRACE_TIME_TO_DECIDE',
  REQUEST_PLAN_CHANGE_FROM_ACCOUNTANT = 'REQUEST_PLAN_CHANGE_FROM_ACCOUNTANT',
  REJECT_ACCOUNTING_CLIENT_DISCOUNT = 'REJECT_ACCOUNTING_CLIENT_DISCOUNT',
}

type UpdateSubscriptionData = {
  selectedPlanId: Plan['id'];
  selectedBillingCycle: SubscriptionBillingCycleEnum;
};

type SubscriptionPlansActivityProps = {
  variant?: SubscriptionPlansVariant;
  returnUrl?: string;
  onBack?: () => void;
  isSubscribingToFreePlan?: boolean;
};

export const SubscriptionPlansActivity = ({
  variant,
  returnUrl,
  onBack,
  isSubscribingToFreePlan,
}: SubscriptionPlansActivityProps) => {
  const { promoCodeQueryString } = useWizardParams();
  const { isMutating: isUpdatingSubscription, update: updateSubscription } = useSubscriptionMe({ enabled: false });
  const { accountingClientSelectedSubscriptionPlan: accountingClientSelectedSubscriptionPlanOrgPreference } =
    useSubscriptionContext();
  const subscription = useSubscription();
  const { formatMessage } = useMelioIntl();
  // eslint-disable-next-line import/no-deprecated
  const { toast } = useToast();
  const { track } = useAnalytics();
  const { goToSettingsPage } = useRedirects();
  const { goToSubscriptionCheckout } = useSubscriptionRouter();
  const navigate = useNavigate();
  const [switchBillingCycleError, setSwitchBillingCycleError] = useState(false);
  const [pendingPlanSelection, setPendingPlanSelection] = useState<SubscribeToPlanType>();

  const { isDowngradedPlan, isUpgradedPlan, currentPlan } = useSubscriptionPlan();
  const { isReactivationAllowed } = usePendingSubscription();
  const { isFiservPartner } = usePartnerGroups();

  const [updateSubscriptionData, setUpdateSubscriptionData] = useState<UpdateSubscriptionData>();
  const [actionModal, setActionModal] = useState<ActionModalType>();
  const { isEligibleForGracePeriod, isTodayInGracePeriod } = useSubscriptionGracePeriod();
  const { subscribeToFreePlan } = useSubscribeToFreePlan();

  useSetDocumentTitle(formatMessage('activities.subscription.plans.document.title'));
  const { getIsFreePlan, getFreePlan, getPlan } = usePlansTiers();
  const freePlan = getFreePlan();

  const { isLoading: isLoadingManagePlanPermissions, shouldRequestPlanChangeFromAccountant } =
    useManagePlanPermissions();

  const reactivatePlan = useReactivatePlan({
    subscription,
    onDone: () => navigate(returnUrl || SUBSCRIPTION_SETTINGS_PLANS_ROUTE),
  });

  const pendingPlan = pendingPlanSelection && getPlan(pendingPlanSelection.selectedPlanId);
  const accountingClientSelectedSubscriptionPlan =
    accountingClientSelectedSubscriptionPlanOrgPreference &&
    getPlan(accountingClientSelectedSubscriptionPlanOrgPreference?.planId);

  const onSubscribe = ({ selectedPlanId, selectedBillingCycle }: SubscribeToPlanType) => {
    if (getIsFreePlan(selectedPlanId)) {
      void subscribeToFreePlan();
    } else {
      goToSubscriptionCheckout({
        planId: selectedPlanId,
        returnUrl,
        promoCode: promoCodeQueryString,
        billingCycle: selectedBillingCycle,
      });
    }
  };

  const onSelectPlan = (planSelection: SubscribeToPlanType, isRejectedAccountingFirmDiscount?: boolean) => {
    const { selectedPlanId, selectedBillingCycle } = planSelection;
    setUpdateSubscriptionData({ selectedPlanId, selectedBillingCycle });
    setSwitchBillingCycleError(false);
    if (accountingClientSelectedSubscriptionPlan && !isRejectedAccountingFirmDiscount) {
      setPendingPlanSelection(planSelection);
      setActionModal(ActionModalType.REJECT_ACCOUNTING_CLIENT_DISCOUNT);
      return;
    }
    const isFreePlanSelectedDuringGracePeriod =
      getIsFreePlan(selectedPlanId) && isEligibleForGracePeriod && isTodayInGracePeriod;
    if (isFreePlanSelectedDuringGracePeriod) {
      return setActionModal(ActionModalType.GRACE_TIME_TO_DECIDE);
    }
    if (!isFiservPartner && (!subscription || getIsFreePlan(subscription.planId))) {
      return onSubscribe({ selectedPlanId, selectedBillingCycle });
    }

    if (shouldRequestPlanChangeFromAccountant) {
      return setActionModal(ActionModalType.REQUEST_PLAN_CHANGE_FROM_ACCOUNTANT);
    }
    if (isReactivationAllowed) {
      if (currentPlan?.id === selectedPlanId) {
        return reactivatePlan();
      }
      return setActionModal(ActionModalType.REACTIVATE_CURRENT_PLAN);
    }
    const hasBillingCycleChanged = selectedBillingCycle !== subscription?.planCyclePeriod;
    if (hasBillingCycleChanged && !getIsFreePlan(selectedPlanId)) {
      return setActionModal(ActionModalType.SWITCH_BILLING_CYCLE);
    }
    if (isDowngradedPlan(selectedPlanId)) {
      return setActionModal(ActionModalType.DOWNGRADE_PLAN);
    }
    if (isUpgradedPlan(selectedPlanId)) {
      return setActionModal(ActionModalType.UPGRADE_PLAN);
    }
  };

  const onSwitchBillingCycle = () => {
    const newCycle =
      subscription?.planCyclePeriod === SubscriptionBillingCycleEnum.Monthly
        ? SubscriptionBillingCycleEnum.Annual
        : SubscriptionBillingCycleEnum.Monthly;

    void updateSubscription({ planId: subscription?.planId, planCyclePeriod: newCycle })
      .then(() => {
        track('Organization', 'Status', {
          Intent: 'upgrade-plan',
        });
        goToSettingsPage();
        onActionModalClose();
        toast({
          id: 'subscription-switch-billing-cycle-success',
          type: 'success',
          title: formatMessage(`activities.settings.subscriptionPlans.switchBillingCycle.toast.${newCycle}.success`),
        });
      })
      .catch(() => {
        setSwitchBillingCycleError(true);
      });
  };
  const onActionModalClose = () => {
    setActionModal(undefined);
  };
  const onRejectDiscountModalClose = () => {
    setPendingPlanSelection(undefined);
    onActionModalClose();
  };
  const onClaimDiscountClick = () => {
    if (onBack) {
      return onBack();
    }
    return navigate(DEFAULT_DASHBOARD_ROUTE);
  };
  const onRejectDiscountClick = () => {
    onActionModalClose();
    if (pendingPlanSelection) {
      void onSelectPlan(pendingPlanSelection, true);
    }
  };
  return (
    <>
      <SubscriptionPlansScreen
        data-testid="subscriptions-plans-screen"
        isLoading={isLoadingManagePlanPermissions}
        onSelectPlan={onSelectPlan}
        selectedPlanId={updateSubscriptionData?.selectedPlanId || (isSubscribingToFreePlan ? freePlan?.id : undefined)}
        onShowReactivatePlanModal={() => setActionModal(ActionModalType.REACTIVATE_CURRENT_PLAN)}
        variant={variant}
        onBack={onBack}
      />
      <RequestPlanChangeFromAccountantModalScreen
        isOpen={actionModal === ActionModalType.REQUEST_PLAN_CHANGE_FROM_ACCOUNTANT}
        onClose={onActionModalClose}
      />
      <SubscriptionGraceTimeToDecideFlow
        isOpen={actionModal === ActionModalType.GRACE_TIME_TO_DECIDE}
        onDismiss={onActionModalClose}
      />
      <ReactivatePlanModal
        variant="before-scheduling"
        isOpen={actionModal === ActionModalType.REACTIVATE_CURRENT_PLAN}
        onClose={onActionModalClose}
      />

      {updateSubscriptionData && subscription && (
        <CancelOrDowngradeFlowActivity
          isOpen={actionModal === ActionModalType.DOWNGRADE_PLAN}
          newPlanId={updateSubscriptionData.selectedPlanId}
          onClose={onActionModalClose}
        />
      )}
      {updateSubscriptionData && currentPlan?.id && (
        <SubscriptionUpgradeModalActivity
          isOpen={actionModal === ActionModalType.UPGRADE_PLAN}
          onClose={onActionModalClose}
          planUpgradeProps={{
            planId: updateSubscriptionData.selectedPlanId,
            billingCycle: updateSubscriptionData.selectedBillingCycle,
            currentPlanId: currentPlan?.id,
          }}
        />
      )}
      {subscription && (
        <SwitchSubscriptionBillingCycleModalScreen
          isOpen={actionModal === ActionModalType.SWITCH_BILLING_CYCLE}
          onClose={onActionModalClose}
          onChange={onSwitchBillingCycle}
          switchBillingCycleError={switchBillingCycleError}
          subscription={subscription}
          isSubscribing={isUpdatingSubscription}
          newPlanId={updateSubscriptionData?.selectedPlanId}
        />
      )}
      <RejectAccountingClientDiscountModal
        isOpen={actionModal === ActionModalType.REJECT_ACCOUNTING_CLIENT_DISCOUNT}
        onClose={onRejectDiscountModalClose}
        onClaimDiscountClick={onClaimDiscountClick}
        onContinue={onRejectDiscountClick}
        selectedPlan={pendingPlan}
        selectedAccountingClientPlan={accountingClientSelectedSubscriptionPlan}
      />
    </>
  );
};
