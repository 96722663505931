import { Link, Typography } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const EmptyState = () => {
  const { formatMessage } = useMelioIntl();
  return (
    <Typography.Description
      data-testid="approval-workflows-empty-state"
      label={formatMessage('activities.settings.approval-workflow-settings.empty-state.description', {
        learnMoreLink: (
          <Link
            href="http://www.google.com"
            label={formatMessage('activities.settings.approval-workflow-settings.empty-state.learn-more')}
            newTab
          />
        ),
      })}
    />
  );
};
