import {
  BrandSymbolKey,
  FlagIcon,
  FlagKey,
  Group,
  Icon,
  IconKey,
  Link,
  SectionBanner,
  Text,
  Tooltip,
} from '@melio/penny';
import {
  Card,
  DeliveryMethod,
  DeliveryMethodType,
  DeliveryPreferenceType,
  ExchangeRateBase,
  FxQuote,
  InternationalAccount,
  InternationalAccountBicSwift,
  InternationalAccountBicSwiftIdentifierTypeEnum,
  InternationalAccountDeliveryMethod,
  InternationalAccountIban,
  InternationalAccountIbanIdentifierTypeEnum,
  ManagedAccount,
} from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { useConfig } from '@melio/platform-provider';

enum IdentifierType {
  iban = 'IBAN',
  swift = 'SWIFT',
  account = 'Account number',
  BIC = 'BIC',
}

const getInternationalIdentifierData = (
  details: InternationalAccountBicSwift | InternationalAccountIban | InternationalAccount
) => {
  const { identifierType, identifierDetails } = details;

  if (identifierType === InternationalAccountIbanIdentifierTypeEnum.Iban) {
    return {
      identifierType: IdentifierType.iban,
      identifierValue: identifierDetails.iban,
    };
  }

  if (identifierType === InternationalAccountBicSwiftIdentifierTypeEnum.BicSwift) {
    return {
      identifierType: IdentifierType.swift,
      identifierValue: identifierDetails.bicSwift,
    };
  }

  return getFxInternationalIdentifierData(details);
};

export const countryFlagTypeOverride: { [key: string]: string } = {
  'holy-see (vatican city)': 'vatican-city-state',
};
export const CountryFlagIcon = ({ countryDisplayName }: { countryDisplayName?: string }) => {
  const _flagType = countryDisplayName?.toLowerCase().replace(' ', '-') ?? '';
  const flagType = countryFlagTypeOverride[_flagType] || _flagType;
  return flagType ? <FlagIcon countryCode={flagType as FlagKey} size="small" /> : null;
};

const RateSectionApprovalFlow = ({ quote, message }: { quote: FxQuote | ExchangeRateBase; message: MessageKey }) => {
  const { formatMessage } = useMelioIntl();
  const { links } = useConfig();
  return (
    <Group alignItems="center" spacing="xxs" data-testid="fx-payment-rate-banner">
      <Text textStyle="body3" color="global.neutral.900">
        {formatMessage(message, {
          currencySymbol: quote?.foreignCurrency,
          amount: quote?.usdToForeignRate.toFixed(4),
        })}
      </Text>
      <Tooltip
        placement="top-end"
        label={formatMessage('widgets.reviewAndConfirm.amountSection.rate.tooltip', {
          link: (
            <Link
              color="inverted"
              label={formatMessage('widgets.reviewAndConfirm.amountSection.rate.tooltip.link')}
              href={links['widgets.learnAboutRates.href']}
              newTab
              size="medium"
            />
          ),
        })}
      >
        <Icon type="info" size="small" />
      </Tooltip>
    </Group>
  );
};

export const RateSection = ({
  quote,
  message,
  isApprovalFlow,
  tooltipOverrides,
}: {
  quote: FxQuote | ExchangeRateBase;
  message: MessageKey;
  tooltipOverrides?: {
    label?: string;
    link?: string;
  };
  isApprovalFlow?: boolean;
}) => {
  const { formatMessage } = useMelioIntl();
  const { links } = useConfig();

  if (isApprovalFlow) {
    return <RateSectionApprovalFlow quote={quote} message={message} />;
  }

  return (
    <SectionBanner
      data-testid="fx-payment-rate-banner"
      variant="secondary"
      hideIcon
      description={
        <Group alignItems="center">
          <Text textStyle="body3">
            {formatMessage(message, {
              currencySymbol: quote?.foreignCurrency,
              amount: quote?.usdToForeignRate.toFixed(4),
            })}
          </Text>
          <Tooltip
            shouldAddTriggerFocus
            placement="top-end"
            label={
              tooltipOverrides?.label ??
              formatMessage('widgets.reviewAndConfirm.amountSection.rate.tooltip', {
                link: (
                  <Link
                    color="inverted"
                    label={formatMessage('widgets.reviewAndConfirm.amountSection.rate.tooltip.link')}
                    href={tooltipOverrides?.link ?? links['widgets.learnAboutRates.href']}
                    newTab
                    size="medium"
                  />
                ),
              })
            }
          >
            <Icon type="info" size="small" />
          </Tooltip>
        </Group>
      }
    />
  );
};

export const displayIdentifier = (
  identifierType: string,
  identifierValue?: string,
  countryDisplayName?: string
): { identifierType: string; identifierValue: string; flag: JSX.Element | ''; countryDisplayName: string | '' } => {
  const flag = <CountryFlagIcon countryDisplayName={countryDisplayName} />;
  return {
    identifierType,
    identifierValue: identifierValue ? identifierValue?.slice(-4) : '',
    flag,
    countryDisplayName: countryDisplayName || '',
  };
};

export const getInternationalFormattedIdentifierData = (
  deliveryMethod: InternationalAccountDeliveryMethod,
  countryDisplayName?: string
) => {
  const { identifierType, identifierValue } = getInternationalIdentifierData(
    deliveryMethod.details as InternationalAccountBicSwift | InternationalAccountIban
  );

  return displayIdentifier(identifierType, identifierValue, countryDisplayName);
};

const getFxInternationalIdentifierData = (details: InternationalAccount) => {
  const { identifierDetails } = details;
  if (identifierDetails.iban) {
    return {
      identifierType: IdentifierType.iban,
      identifierValue: identifierDetails.iban,
    };
  }
  if (identifierDetails.bic) {
    return {
      identifierType: IdentifierType.BIC,
      identifierValue: identifierDetails.bic,
    };
  }
  return {
    identifierType: IdentifierType.account,
    identifierValue: identifierDetails.accountNumber,
  };
};

export const isInternationalDeliveryMethod = (
  deliveryMethod: DeliveryMethod | undefined
): deliveryMethod is InternationalAccountDeliveryMethod => deliveryMethod?.type === 'international-account';

export const getDeliveryMethodIconKey = (
  deliveryMethod: DeliveryMethod,
  icons: { [key: string]: BrandSymbolKey }
): IconKey | BrandSymbolKey => {
  let type = deliveryMethod.type;
  if (deliveryMethod.type === 'managed-account') {
    type = getManagedAccountDeliveryMethodType(deliveryMethod.details);
  }

  switch (type) {
    case 'bank-account':
      return 'bank';
    case 'paper-check':
      return 'paper-check';
    case 'domestic-wire-account':
      return 'bank';
    case 'virtual-account':
      return 'email';
    case 'virtual-card':
      return icons['virtualCard'] as BrandSymbolKey;
    case 'biller-account':
    case 'managed-account':
      return 'directory-payment';
    case 'international-account':
      return 'international';
    case 'card': {
      return (deliveryMethod.details as Card).type === 'debit' ? 'debit-card' : 'credit-card';
    }
  }
};

export const getIsChangingDeliveryMethodIsDisabled = (existingDeliveryMethod: DeliveryMethod) =>
  existingDeliveryMethod.isManaged || existingDeliveryMethod.type === DeliveryMethodType.InternationalAccount;

export const getManagedAccountDeliveryMethodType = (managedAccount: ManagedAccount): DeliveryMethodType => {
  switch (managedAccount.deliveryType) {
    case 'check':
      return DeliveryMethodType.PaperCheck;
    case 'ach':
      return DeliveryMethodType.BankAccount;
  }
  Logger.log('Could not determine delivery method type of a managed account', 'warn');
  return DeliveryMethodType.ManagedAccount;
};

export const getDefaultDeliveryPreferenceByDeliveryMethodType = (
  deliveryMethodType: DeliveryMethodType
): DeliveryPreferenceType => {
  const deliveryPreferenceMap: { [key in DeliveryMethodType]: DeliveryPreferenceType } = {
    [DeliveryMethodType.BankAccount]: DeliveryPreferenceType.Ach,
    [DeliveryMethodType.Card]: DeliveryPreferenceType.Card,
    [DeliveryMethodType.PaperCheck]: DeliveryPreferenceType.Check,
    [DeliveryMethodType.VirtualAccount]: DeliveryPreferenceType.Virtual,
    [DeliveryMethodType.BillerAccount]: DeliveryPreferenceType.Rpps,
    [DeliveryMethodType.DomesticWireAccount]: DeliveryPreferenceType.DomesticWire,
    [DeliveryMethodType.InternationalAccount]: DeliveryPreferenceType.International,
    [DeliveryMethodType.VirtualCard]: DeliveryPreferenceType.VirtualCard,
    [DeliveryMethodType.ManagedAccount]: DeliveryPreferenceType.Ach,
  };

  return deliveryPreferenceMap[deliveryMethodType];
};
