import { Button, Icon, Popover } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  onEditClick: VoidFunction;
  vendorName: string;
};

export const VendorMissingInfoIcon = ({ onEditClick, vendorName }: Props) => {
  const { formatMessage } = useMelioIntl();
  return (
    <Popover
      title={formatMessage('widgets.vendorMissingInfoIcon.tooltip.title')}
      actionRenderer={({ onClose, ...props }) => (
        <Button
          {...props}
          size="small"
          variant="secondary-inverse"
          label={formatMessage('widgets.vendorMissingInfoIcon.tooltip.cta')}
          onClick={() => {
            onClose();
            onEditClick();
          }}
        />
      )}
      description={formatMessage('widgets.vendorMissingInfoIcon.tooltip.description', { vendorName })}
      data-testid="vendor-missing-info-icon-widget-popover"
    >
      <Icon
        size="small"
        type="info"
        aria-label="Missing details icon"
        data-testid="vendor-missing-info-icon-widget-icon"
      />
    </Popover>
  );
};
