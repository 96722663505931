/* eslint-disable max-lines */
import { useVendorNameConstraints } from '@melio/ap-domain';
import { Container, Form, Group, SectionBanner, Text, useBreakpoint, useToast } from '@melio/penny';
import { Address, US_STATES } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import React, { useEffect, useMemo, useState } from 'react';

import { AddressSearchWidget, AddressSearchWidgetProps, ZipCodeField } from '../../../../../../../form-controls';
import { useStateSelect } from '../../../../../../useStateSelect';
import { useUnmanagedVendorFormExtendedSchema } from '../../../../../../useUnmanagedVendorFormExtendedSchema';
import { useVendorForm } from '../../../../../../useVendorForm';
import { AddVendorFormFields, AddVendorFormWidgetProps } from '../../../../types';
import { getCreateVendorPayload } from './utils';

type AddUnmanagedVendorExtendedFormFields = Omit<AddVendorFormFields, 'fullName'>;

export const AddUnmanagedVendorFormExtended = ({
  onClearCompanyNameSearchField,
  inlineApiErrorCodes,
  bannerApiErrorCodes,
  onSelectCompanyName,
  onSubmit,
  onSubmissionStateChange,
  isSaving,
  shouldHideCompanyNameField,
  defaultValues,
  titlesAs = 'h2',
  isEmailRequired,
  emailRequiredMessage,
  ...props
}: AddVendorFormWidgetProps) => {
  const { closeToast } = useToast();
  const { formatMessage } = useMelioIntl();
  const isManaged = !!props.managed;
  const [isCompanyNameBlurred, setIsCompanyNameBlurred] = useState(false);
  const [isSearchBusinessesInDirectoriesSupported] = useFeature<boolean>(
    FeatureFlags.IsSearchBusinessesInDirectoriesSupported,
    false
  );
  const {
    settings: {
      vendor: {
        createVendor: { shouldShowBankDetailsFields, shouldShowAddressDetailsBanner },
      },
    },
  } = useConfig();
  const {
    formProps,
    registerField,
    watch,
    setValue,
    reset,
    formState: { isSubmitted },
    onChangeWithValidation,
    trigger,
  } = useVendorForm<AddUnmanagedVendorExtendedFormFields>({
    inlineApiErrorCodes,
    bannerApiErrorCodes,
    onSubmit: (data, _e, target) => onSubmit(getCreateVendorPayload(data), target),
    schema: useUnmanagedVendorFormExtendedSchema({
      showCompanyField: !shouldHideCompanyNameField,
      isEmailRequired,
      emailRequiredMessage,
    }),
    defaultValues,
    isSaving,
    onSubmissionStateChange,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleAddressChange: AddressSearchWidgetProps['onChange'] = (event) => {
    const address = event.target.value as unknown as Address;
    const isFormValueCleared = address === null;

    if (isFormValueCleared) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - if we pass '' search works properly only after 2nd search input
      setValue('line1', null);
    }

    const setFormFieldValue = (field: keyof Omit<Address, 'countryCode'>) => {
      if (address?.[field]) {
        setValue(field, address[field], {
          shouldValidate: true,
        });
      }
    };
    setFormFieldValue('line1');
    setFormFieldValue('line2');
    setFormFieldValue('city');
    setFormFieldValue('state');
    setFormFieldValue('postalCode');
  };

  const { isExtraSmallScreen } = useBreakpoint();

  const companyNameInputProps = registerField('companyName');

  const { vendorNameBannerDescription, vendorNameConstraintType } = useVendorNameConstraints({
    vendorName: watch('companyName') ?? undefined,
    vendorNameFieldErrorType: companyNameInputProps?.error?.type,
    vendorNameFreeTextInputProps: isSearchBusinessesInDirectoriesSupported
      ? undefined
      : { isBlurred: isCompanyNameBlurred },
    isManaged,
    isFormSubmitted: isSubmitted,
  });

  const stateOptions = useMemo(
    () => US_STATES.map((state) => ({ value: state, label: formatMessage(`local.USA.states.${state}`) })),
    [formatMessage]
  );
  const { filteredStateOptions, handleChange: handleStateSearch } = useStateSelect(stateOptions);

  const handleOnBlur = () => {
    setIsCompanyNameBlurred(true);
  };

  const checkKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <Form
      rowGap="m"
      columnGap="m"
      data-component="AddVendorFormWidget"
      {...props}
      {...formProps}
      columns={2}
      size={isExtraSmallScreen ? 'small' : 'large'}
      onKeyDown={checkKeyDown}
    >
      <Form.ContentBox colSpan={isExtraSmallScreen ? 1 : 2}>
        <Group spacing="xs" variant="vertical">
          <Text as={titlesAs} textStyle="heading3Semi">
            {formatMessage('widgets.vendors.vendorDetailsGroup.title')}
          </Text>
        </Group>
      </Form.ContentBox>
      {!shouldHideCompanyNameField && (
        <Form.ContentBox colSpan={isExtraSmallScreen ? 1 : 2}>
          <Group spacing="xs" variant="vertical">
            <Form.TextField
              colSpan={2}
              {...companyNameInputProps}
              labelProps={{ label: formatMessage('widgets.vendors.companyName.label') }}
              placeholder={formatMessage('widgets.vendors.companyName.placeholder')}
              onBlur={handleOnBlur}
              autoFocus
            />
            {vendorNameBannerDescription && (
              <SectionBanner
                data-testid={`company-name-suggestion-${vendorNameConstraintType}`}
                description={vendorNameBannerDescription}
              />
            )}
          </Group>
        </Form.ContentBox>
      )}
      <Form.TextField
        colSpan={2}
        {...registerField('nickname')}
        labelProps={{ label: formatMessage('widgets.vendors.nickname.label') }}
        placeholder={formatMessage('widgets.vendors.nickname.placeholder')}
      />
      <Form.TextField
        colSpan={1}
        data-private
        {...registerField('accountNumber')}
        labelProps={{
          tooltipProps: {
            label: formatMessage('widgets.vendors.accountNumber.unmanagedVendorLabelTooltip'),
          },
          label: formatMessage('widgets.vendors.accountNumber.label'),
        }}
        helperTextProps={{
          label: formatMessage('widgets.vendors.accountNumber.unmanagedVendorHelperText'),
        }}
      />
      <Form.TextField
        data-private
        colSpan={1}
        {...registerField('confirmAccountNumber')}
        labelProps={{
          label: formatMessage('widgets.vendors.confirmAccountNumber.label'),
        }}
      />
      <Form.PhoneField
        colSpan={2}
        {...registerField('phone')}
        labelProps={{ label: formatMessage('widgets.vendors.phone.label') }}
        placeholder={formatMessage('widgets.vendors.phone.placeholder')}
        autoComplete="on"
      />
      <Form.TextField
        colSpan={2}
        {...registerField('email')}
        labelProps={{ label: formatMessage('widgets.vendors.email.label') }}
        placeholder={formatMessage('widgets.vendors.email.placeholder')}
        autoComplete="on"
      />
      {shouldShowBankDetailsFields ? (
        <>
          <Form.ContentBox colSpan={isExtraSmallScreen ? 1 : 2}>
            <Container paddingTop={isExtraSmallScreen ? 'xs' : 's'}>
              <Group spacing={isExtraSmallScreen ? 'm' : 's'} variant="vertical">
                <Text as={titlesAs} textStyle="heading3Semi">
                  {formatMessage('widgets.vendors.electronicPaymentDetailsGroup.title')}
                </Text>
                <SectionBanner
                  variant="informative"
                  description={formatMessage(`widgets.vendors.electronicPaymentDetailsGroup.banner.description`)}
                  data-testid="electronic-payment-details-banner"
                />
              </Group>
            </Container>
          </Form.ContentBox>
          <Form.TextField
            colSpan={1}
            labelProps={{
              label: formatMessage('widgets.vendors.bankRoutingNumber.label'),
            }}
            {...registerField('bankRoutingNumber')}
            onChange={(e) => {
              setValue('bankRoutingNumber', e.target.value, {
                shouldValidate: isSubmitted,
              });

              if (isSubmitted) {
                void trigger('bankAccountNumber');
              }
            }}
          />
          <Form.TextField
            colSpan={1}
            labelProps={{
              label: formatMessage('widgets.vendors.bankAccountNumber.label'),
            }}
            {...registerField('bankAccountNumber')}
            onChange={(e) => {
              setValue('bankAccountNumber', e.target.value, {
                shouldValidate: isSubmitted,
              });
              if (isSubmitted) {
                void trigger('bankRoutingNumber');
              }
            }}
          />
        </>
      ) : null}
      <Form.ContentBox colSpan={isExtraSmallScreen ? 1 : 2}>
        <Container paddingTop={isExtraSmallScreen ? 'xs' : 's'}>
          <Group spacing={isExtraSmallScreen ? 'm' : 's'} variant="vertical">
            <Text as={titlesAs} textStyle="heading3Semi">
              {formatMessage('widgets.vendors.addressDetailsGroup.title')}
            </Text>
            {shouldShowAddressDetailsBanner ? (
              <SectionBanner
                variant="informative"
                description={formatMessage(`widgets.vendors.addressDetailsGroup.banner.description`)}
                data-testid="address-details-group-banner"
              />
            ) : null}
          </Group>
        </Container>
      </Form.ContentBox>
      <AddressSearchWidget
        {...registerField('line1')}
        colSpan={2}
        onChange={handleAddressChange}
        // The 'OR' is a workaround for formatting the defaultValue we're getting for this field.
        formatSelectedValue={(option) =>
          (option.value as unknown as Address).line1 || (option.value as unknown as string)
        }
        labelProps={{
          label: formatMessage('widgets.vendors.addressLine1.label'),
        }}
        placeholder={formatMessage('widgets.vendors.addressLine1.placeholder')}
        clearButtonAriaLabel={formatMessage('widgets.vendors.addressLine1.clearButtonAriaLabel')}
        autoComplete="on"
      />
      <Form.TextField
        colSpan={2}
        labelProps={{
          label: formatMessage('widgets.vendors.addressLine2.label'),
        }}
        placeholder={formatMessage('widgets.vendors.addressLine2.placeholder')}
        {...registerField('line2')}
        onChange={(e) => {
          closeToast();
          onChangeWithValidation('line2', e.target.value, isSubmitted);
        }}
        autoComplete="on"
      />
      <Form.TextField
        colSpan={1}
        labelProps={{
          label: formatMessage('widgets.vendors.city.label'),
        }}
        {...registerField('city')}
        onChange={(e) => {
          closeToast();
          onChangeWithValidation('city', e.target.value, isSubmitted);
        }}
      />
      <Form.SelectNew
        colSpan={1}
        labelProps={{
          label: formatMessage('widgets.vendors.state.label'),
        }}
        placeholder={formatMessage('widgets.vendors.state.placeholder')}
        {...registerField('state')}
        onChange={(e) => {
          closeToast();
          onChangeWithValidation('state', e.target.value, isSubmitted);
        }}
        options={stateOptions}
        searchBarProps={{
          placeholder: formatMessage('widgets.vendors.state.placeholder'),
          options: filteredStateOptions,
          onChange: handleStateSearch,
        }}
        shouldHideClearButton
      />
      <ZipCodeField
        colSpan={2}
        labelProps={{
          label: formatMessage('widgets.vendors.postalCode.label.unmanaged'),
        }}
        placeholder={formatMessage('widgets.vendors.postalCode.placeholder')}
        {...registerField('postalCode')}
        onChange={(e) => {
          closeToast();
          onChangeWithValidation('postalCode', e.target.value, isSubmitted);
        }}
        helperTextProps={{ label: formatMessage('widgets.vendors.postalCode.description') }}
      />
    </Form>
  );
};
AddUnmanagedVendorFormExtended.displayName = 'AddVendorFormWidget';
