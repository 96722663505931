import {
  ApprovalWorkflow,
  WorkflowNumericConditionConfigurationOperatorEnum,
  WorkflowNumericConditionTypeEnum,
  WorkflowPaymentAmountConditionAttributeEnum,
  WorkflowPaymentCreatedByConditionAttributeEnum,
} from '@melio/platform-api';
import { minBy } from 'lodash';
import { useCallback } from 'react';

type Props = {
  approvalWorkflows: ApprovalWorkflow[] | undefined;
  userId: string;
};

export const useCollaboratorsApprovalLimit = ({ approvalWorkflows, userId }: Props) => {
  const getApprovalThresholdValue = useCallback(() => {
    const workflowsWithCreatedByAndAmountCriteria: ApprovalWorkflow[] | undefined = approvalWorkflows?.filter(
      (workflow) =>
        workflow.criteria.conditions.some(
          (condition) =>
            condition.attribute === WorkflowPaymentCreatedByConditionAttributeEnum.PaymentCreatedBy &&
            condition.condition.configuration.filterBy === 'user' &&
            condition.condition.configuration.ids?.includes(userId)
        ) &&
        workflow.criteria.conditions.some(
          (condition) =>
            condition.attribute === WorkflowPaymentAmountConditionAttributeEnum.PaymentAmount &&
            condition.condition.configuration.operator ===
              WorkflowNumericConditionConfigurationOperatorEnum.GreaterEqual
        )
    );

    if (!workflowsWithCreatedByAndAmountCriteria?.length) {
      return null;
    }

    const minThresholdWorkflow = minBy(workflowsWithCreatedByAndAmountCriteria, (workflow) =>
      workflow.criteria.conditions.filter(
        (condition) =>
          condition.condition.type === WorkflowNumericConditionTypeEnum.WorkflowNumericCondition &&
          condition.condition.configuration.value
      )
    );

    const thresholdCondition = minThresholdWorkflow?.criteria.conditions.find(
      (condition) => condition.condition.type === WorkflowNumericConditionTypeEnum.WorkflowNumericCondition
    );

    if (
      thresholdCondition?.condition.type === WorkflowNumericConditionTypeEnum.WorkflowNumericCondition &&
      thresholdCondition.condition.configuration
    ) {
      const amount = thresholdCondition.condition.configuration.value;
      return amount;
    }

    return null;
  }, [approvalWorkflows, userId]);

  return { getApprovalThresholdValue };
};
