import { useInvoiceDiscount, useMelioIntl } from '@melio/ar-domain';
// eslint-disable-next-line import/no-deprecated
import { useToast } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { DeleteIEntityDialogScreen } from '../shared';

type DeleteDiscountDialogActivityProps = {
  discountId?: string;
  onDone: VoidFunction;
  onClose: VoidFunction;
  onError?: ARErrorFunction;
};

export const DeleteDiscountDialogActivity = forwardRef<DeleteDiscountDialogActivityProps>(
  ({ discountId, onDone: onSuccess, onClose, onError, ...props }, ref) => {
    const { data, delete: deleteDiscount, isLoading, isMutating } = useInvoiceDiscount({ id: discountId, onError });

    const { formatMessage } = useMelioIntl();
    // eslint-disable-next-line import/no-deprecated
    const { toast } = useToast();

    const onSubmit = () =>
      deleteDiscount()
        .then(() => {
          onSuccess();
          toast({
            type: 'informative',
            title: formatMessage('ar.settings.activities.deleteDiscount.toast.onDelete.label', { name: data?.name }),
          });
        })
        .catch(onError);

    return (
      <DeleteIEntityDialogScreen
        data-testid="delete-discount-dialog"
        id={discountId}
        onSubmit={onSubmit}
        onClose={onClose}
        isDeleting={isMutating}
        isLoading={isLoading}
        header={formatMessage('ar.settings.activities.deleteDiscount.dialog.header')}
        description={formatMessage('ar.settings.activities.deleteDiscount.dialog.description', { name: data?.name })}
        primaryButtonLabel={formatMessage('ar.settings.activities.deleteDiscount.dialog.primaryButton.label')}
        secondaryButtonLabel={formatMessage('ar.settings.activities.deleteDiscount.dialog.secondaryButton.label')}
        {...props}
        ref={ref}
      />
    );
  }
);
DeleteDiscountDialogActivity.displayName = 'DeleteTaxRateActivity';
