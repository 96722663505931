import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useIsExternalAccountant, useOrganizationPreferences } from '@melio/platform-api';
import { useLocation } from '@melio/platform-utils';

import { useSubscriptionMe } from '../../../api';
import { useRedirects } from '../../../hooks';
import { useAccountingClientSelectedSubscriptionPlan } from '../../../hooks/useAccountingClientSelectedSubscriptionPlan';
import { useSubscriptionRouter } from '../../utils';
import { AccountingClientSubscriptionPlanSelectedComponent } from './components/AccountingClientSubscriptionPlanSelected.component';

export const AccountingClientSubscriptionPlanSelectedActivity = withAnalyticsContext(({ setAnalyticsProperties }) => {
  const { pathname } = useLocation();
  const accountingClientSelectedSubscriptionPlan = useAccountingClientSelectedSubscriptionPlan();
  const { goToSubscriptionCheckout, goToSubscriptionPlans } = useSubscriptionRouter();
  const { refetch: refetchOrganizationPreferences } = useOrganizationPreferences();
  const { goToDashboard } = useRedirects();
  const { data: subscription } = useSubscriptionMe({ enabled: false });
  const isExternalAccountant = useIsExternalAccountant();

  setAnalyticsProperties({
    PageName: 'your-accountant-chose-melio',
    Flow: 'subscription-accountant',
  });

  useAnalyticsView('Organization', true, true, {
    Intent: 'subscribe',
  });

  const { track } = useAnalytics();

  if (!accountingClientSelectedSubscriptionPlan || subscription) {
    void refetchOrganizationPreferences();
    goToDashboard();
    return <></>;
  }

  const onGoToCheckout = () => {
    track('Organization', 'Click', { Cta: 'continue', Intent: 'subscribe' });

    goToSubscriptionCheckout({
      returnUrl: pathname,
      planId: accountingClientSelectedSubscriptionPlan.planId,
      analyticsFlow: 'subscription-accountant',
    });
  };

  const onGoToPlansSelection = () => {
    track('Organization', 'Click', { Cta: 'see-all-plans', Intent: 'go-to-plans-screen' });

    goToSubscriptionPlans({
      returnUrl: pathname,
    });
  };

  return (
    <AccountingClientSubscriptionPlanSelectedComponent
      planId={accountingClientSelectedSubscriptionPlan.planId}
      firmName={accountingClientSelectedSubscriptionPlan.firmName}
      showSeeAllPlansButton={!isExternalAccountant}
      onGoToAllPlans={onGoToPlansSelection}
      onGoToCheckout={onGoToCheckout}
    />
  );
});
