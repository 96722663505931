import {
  ARErrorCode,
  FormWidgetProps,
  InvoiceSummary,
  useCatalogItems,
  useCustomers,
  useMelioIntl,
} from '@melio/ar-domain';
import { Form, Group, LoadingContainer, useWatch } from '@melio/penny';
import { forwardRef, useDebounce } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import {
  DiscountProps,
  ErrorDisplayDetails,
  InvoiceFormMode,
  InvoiceTotalAmountLimits,
  ServiceChargesProps,
  TaxRateProps,
} from '../types';
import {
  IncomingDefaultValues,
  InvoiceFormContext,
  InvoiceFormState,
  InvoiceFormValues,
  useInvoiceForm,
  useValidateInvoiceTotalAmount,
} from '../utils';
import { InvoiceDetailsFormSection, InvoiceItemsFormSection, PayOptionsFormSection } from './form-sections';

export type InvoiceFormProps = Pick<
  FormWidgetProps<InvoiceFormValues>,
  'isDisabled' | 'isSaving' | 'onSubmit' | 'onSubmissionStateChange'
> & {
  defaultValues?: IncomingDefaultValues;
  taxRate: TaxRateProps;
  discount: DiscountProps;
  serviceCharges: ServiceChargesProps;
  isLoading?: boolean;
  isLoadingSummary?: boolean;
  error?: ARPlatformError;
  invoiceSummary?: InvoiceSummary;
  onLineItemsChange?: (lineItems: InvoiceFormValues['lineItems']) => unknown;
  onFormStateChange?: (state: InvoiceFormState) => void;
  mode?: InvoiceFormMode;
  onCreateCustomer?: (value: string) => void;
  onUpdateCustomer?: (customerId: string) => void;
  customerId?: string;
  totalAmountLimits?: InvoiceTotalAmountLimits;
};

export const InvoiceForm = forwardRef<InvoiceFormProps>(
  (
    {
      onSubmit,
      defaultValues,
      isDisabled,
      isSaving,
      isLoading,
      isLoadingSummary,
      discount,
      taxRate,
      serviceCharges,
      onSubmissionStateChange,
      error,
      invoiceSummary,
      onLineItemsChange,
      onFormStateChange,
      mode,
      onCreateCustomer,
      onUpdateCustomer,
      customerId,
      totalAmountLimits,
      ...props
    },
    ref
  ) => {
    const { data: catalogItems, isLoading: isLoadingCatalogItems } = useCatalogItems();
    const { isLoading: isLoadingCustomers } = useCustomers();
    const { formatMessage } = useMelioIntl();
    const invoiceForm = useInvoiceForm({ onSubmit, defaultValues, isSaving, onSubmissionStateChange });
    const lineItems = useDebounce(useWatch({ control: invoiceForm.control, name: 'lineItems' }), 50);
    const [displayError, setDisplayError] = useState<ErrorDisplayDetails>();

    const { sectionBannerProps, shouldExpandCustomPayOptions } = useValidateInvoiceTotalAmount({
      totalAmountLimits,
      totalAmount: invoiceSummary?.totalAmount,
    });

    useEffect(() => {
      onLineItemsChange?.(lineItems);
    }, [lineItems]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      onFormStateChange?.(invoiceForm.formState);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceForm.formState]);

    useEffect(() => {
      // we need to reset and then set the customerId in an async way
      // because the customerId is not updated when hitting cancel on the customer modal
      // if we do it synchronously the change doesn't take effect
      invoiceForm.setValue('customerId', null as never);
      const timeoutId = setTimeout(() => {
        invoiceForm.setValue('customerId', customerId || defaultValues?.customerId || '');
        // we need to wait 1s due to a bug in penny where the input is not updated with the correct label
        // when creating a new customer from the customer modal
      }, 1000);
      return () => clearTimeout(timeoutId);
    }, [customerId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      // XXX This is a temporary solution to handle the error message for duplicate invoice number.
      if (error?.errorCode === ARErrorCode.DuplicateInvoiceNumber) {
        invoiceForm.setError('invoiceNumber', {
          message: formatMessage(
            'ar.invoiceLifecycle.activities.createInvoice.error.duplicateInvoiceNumber.description'
          ),
        });
      } else if (error) {
        setDisplayError({
          title: formatMessage('ar.invoiceLifecycle.activities.createInvoice.error.default.title'),
          description: formatMessage('ar.invoiceLifecycle.activities.createInvoice.error.default.description'),
        });
      }
    }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <InvoiceFormContext.Provider value={invoiceForm}>
        <LoadingContainer
          isLoading={isLoadingCatalogItems || isLoadingCustomers}
          data-component={InvoiceForm.displayName}
          ref={ref}
          {...props}
        >
          <Form
            size="small"
            {...invoiceForm.formProps}
            isDisabled={isDisabled}
            isLoading={isLoading}
            error={displayError}
          >
            <Group variant="vertical" spacing="xl">
              <InvoiceDetailsFormSection
                mode={mode}
                onCreateCustomer={onCreateCustomer}
                onUpdateCustomer={(value) => {
                  onUpdateCustomer?.(value);
                }}
                onClearCustomer={() => invoiceForm.setValue('customerId', '')}
              />
              <InvoiceItemsFormSection
                isDisabled={isDisabled}
                isLoading={isLoading}
                isLoadingSummary={isLoadingSummary}
                isSaving={isSaving}
                discount={discount}
                taxRate={taxRate}
                catalogItems={catalogItems}
                serviceCharges={serviceCharges}
                invoiceSummary={invoiceSummary}
                mode={mode}
              />
              <PayOptionsFormSection
                sectionBannerProps={sectionBannerProps}
                shouldExpandCustomPayOptions={shouldExpandCustomPayOptions}
              />
            </Group>
          </Form>
        </LoadingContainer>
      </InvoiceFormContext.Provider>
    );
  }
);
InvoiceForm.displayName = 'InvoiceForm';
