import { Container, Divider, Group, Skeleton } from '@melio/penny';
import { useAccount } from '@melio/platform-api';
import { useIsSubscriptionsEnabled, usePlansTiers } from '@melio/subscriptions';
import { SubscriptionBillingCycleEnum, useSubscriptionsPreview } from '@melio/subscriptions/src/api';
import { SubscriptionCheckoutErrorBanner } from '@melio/subscriptions/src/ui';
import {
  SubscriptionCheckoutBillingTotal,
  SubscriptionCheckoutSummaryPlan,
  SubscriptionDetailedSubtotal,
} from '@melio/subscriptions/src/ui/components/SubscriptionCheckoutSummary';
import { useEffect } from 'react';

import { FirmClientSubscriptionReviewAndConfirmDisclaimer } from './components/FirmClientSubscriptionReviewAndConfirmDisclaimer.component';

export type PreviewState = 'loading' | 'error' | 'fetched';

export type FirmClientSubscriptionPlanSelectionActivityProps = {
  planId: string;
  fundingSourceId: string | null;
  organizationId?: string;
  discountPercentage?: number;
  seatsQuantity?: number;
  planCycle: SubscriptionBillingCycleEnum;
  onPreviewStateChange?: (state: PreviewState) => void;
};

export const FirmClientSubscriptionReviewAndConfirmActivity = ({
  planId,
  fundingSourceId,
  organizationId,
  planCycle,
  discountPercentage,
  onPreviewStateChange,
  seatsQuantity,
}: FirmClientSubscriptionPlanSelectionActivityProps) => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const { getPlan } = usePlansTiers();
  const plan = getPlan(planId);
  const { data: accountData } = useAccount({ id: 'me' });
  const {
    data: preview,
    isFetching: isPreviewFetching,
    error: previewError,
  } = useSubscriptionsPreview({
    enabled: isSubscriptionsEnabled,
    params: {
      planId,
      fundingSourceId: fundingSourceId || undefined,
      unitQuantity: !organizationId ? seatsQuantity : undefined,
      planCycle,
      organizationId,
    },
  });

  useEffect(() => {
    onPreviewStateChange?.(isPreviewFetching ? 'loading' : previewError ? 'error' : 'fetched');
  }, [onPreviewStateChange, isPreviewFetching, previewError]);

  return previewError ? (
    <SubscriptionCheckoutErrorBanner error={previewError} />
  ) : (
    <Container
      backgroundColor="default"
      border="regular"
      height="auto"
      overflow="hidden"
      paddingX="m"
      paddingY="m"
      width="full"
    >
      <Group variant="vertical" spacing="s">
        <Group variant="vertical" spacing="m">
          <SubscriptionCheckoutSummaryPlan
            selectedPlan={plan}
            currentCycle={planCycle}
            preview={preview}
            isLoading={isPreviewFetching}
            predefinedDiscountPercentage={discountPercentage}
          />

          <Divider />

          {preview && !isPreviewFetching ? (
            <SubscriptionDetailedSubtotal
              legalAddress={fundingSourceId ? accountData?.company.legalAddress || null : null}
              preview={preview}
            />
          ) : (
            <Container>
              <Skeleton height="12px" />
            </Container>
          )}

          <Divider />
          <SubscriptionCheckoutBillingTotal preview={preview} isFreeTrial={false} isLoading={isPreviewFetching} />
          {fundingSourceId && (
            <FirmClientSubscriptionReviewAndConfirmDisclaimer
              isLoading={isPreviewFetching}
              preview={preview}
              planCycle={planCycle}
            />
          )}
        </Group>
      </Group>
    </Container>
  );
};
