import { Group, StatusModal } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { FxQuote, Payment, PaymentDate, useInternationalQuotes, usePaymentsETADates } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { SystemMessageDisplay, SystemMessageProvider } from '@melio/platform-utils';
import { uniqBy } from 'lodash';
import { useEffect, useMemo } from 'react';

import { PaymentsLateApprovalModalBody } from './components/PaymentsLateApprovalModalBody';

export type PaymentsLateApprovalModalActivityProps = {
  lateApprovalPayments: Payment[];
  fxPayments: Payment[];
  approvedPayments: Payment[];
  analytics?: { name: string; properties?: Record<string, string> };
  isOpen: boolean;
  isMutating: boolean;
  onClose: VoidFunction;
  onSuccess: (args: {
    payments: Payment[];
    overrides: Record<Payment['id'], { quote?: FxQuote; date?: PaymentDate }>;
  }) => Promise<void>;
};
export const PaymentsLateApprovalModalActivity = withAnalyticsContext<PaymentsLateApprovalModalActivityProps>(
  ({
    lateApprovalPayments,
    fxPayments,
    approvedPayments,
    analytics,
    isOpen,
    isMutating,
    onClose,
    onSuccess,
    setAnalyticsProperties,
  }) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();

    useEffect(() => {
      if (analytics?.name) {
        track(analytics?.name, 'View', analytics.properties);
      }
    }, [track, analytics]);

    const { data: lateApprovalPaymentsDates, isLoading: isLoadingPaymentsDates } = usePaymentsETADates({
      enabled: isOpen && lateApprovalPayments.length > 0,
      paymentIds: lateApprovalPayments.map((p) => p.id),
    });

    const {
      data: internationalQuotes = [],
      mutateAsync: create,
      isLoading: isLoadingQuotes,
    } = useInternationalQuotes();

    useEffect(() => {
      if (fxPayments.length > 0) {
        create(
          fxPayments.map((p) => ({
            vendorId: p.vendorId,
            foreignCurrency: p.currency,
            foreignAmount: p.foreignAmount ?? 0,
          }))
        );
      }
    }, [create, fxPayments]);

    const pendingPayments = useMemo(
      () => uniqBy([...lateApprovalPayments, ...fxPayments], 'id'),
      [lateApprovalPayments, fxPayments]
    );

    setAnalyticsProperties({
      PageName: 'confirm-payment-changes',
      Intent: 'approve-payments-changes',
      Flow: 'approve-payment',
      FxPayments: fxPayments.length,
      LatePayments: lateApprovalPayments.length,
      ApprovedPayments: approvedPayments.length,
      TotalPaymentsToApprove: pendingPayments.length,
    });

    const overrides = useMemo(() => {
      const quoteData = Object.fromEntries(fxPayments.map((p, i) => [p.id, internationalQuotes[i]]));
      return Object.fromEntries(
        pendingPayments.map((p) => [
          p.id,
          {
            quote: quoteData[p.id],
            date: lateApprovalPaymentsDates?.find((latePayment) => p.id === latePayment.paymentId),
          },
        ])
      );
    }, [pendingPayments, internationalQuotes, lateApprovalPaymentsDates, fxPayments]);

    const handleSubmit = () => {
      if (analytics?.name) {
        track(analytics?.name, 'Click', { Cta: 'update-and-approve', ...analytics.properties });
      }
      onSuccess({
        payments: pendingPayments,
        overrides,
      });
    };

    const handleClose = () => {
      if (analytics?.name) {
        track(analytics?.name, 'Click', { Cta: 'exit', ...analytics.properties });
      }
      onClose();
    };

    return (
      <SystemMessageProvider>
        <StatusModal
          data-testid="pending-approval-payments-modal"
          size="medium"
          isOpen={isOpen}
          onClose={handleClose}
          isLoading={isLoadingPaymentsDates || isLoadingQuotes}
          variant="informative"
          header={formatMessage('activities.paymentsLateApprovalModal.header')}
          primaryButton={{
            label: formatMessage('activities.paymentsLateApprovalModal.footer.submit'),
            onClick: handleSubmit,
            isLoading: isMutating,
            variant: 'primary',
          }}
        >
          <Group variant="vertical">
            <Group variant="vertical" spacing="l">
              <SystemMessageDisplay />

              <PaymentsLateApprovalModalBody
                approvedPayments={approvedPayments}
                pendingPayments={pendingPayments}
                changedFields={overrides}
              />
            </Group>
          </Group>
        </StatusModal>
      </SystemMessageProvider>
    );
  }
);
