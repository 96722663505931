import { useMelioQueryClient, useNonEntityMutation } from '@melio/api-client';
import { CatalogItemsApiClient, PostImportCatalogItemsResponse } from '@melio/platform-api-axios-client';

import { useCollection, UseCollectionProps } from '../../api-client';

export type UseCatalogItemsProps = UseCollectionProps<
  typeof CatalogItemsApiClient.getCatalogItems,
  typeof CatalogItemsApiClient.postCatalogItem,
  typeof CatalogItemsApiClient.updateCatalogItemCatalogItemId
>;

export const useCatalogItems = ({ onCreate, onUpdate, ...props }: UseCatalogItemsProps = {}) => {
  const queryClient = useMelioQueryClient();
  const query = useCollection({
    ...props,
    queryKey: 'CatalogItemsApi',
    queryFn: CatalogItemsApiClient.getCatalogItems,
    deleteFn: CatalogItemsApiClient.deleteCatalogItemCatalogItemId,
    updateFn: CatalogItemsApiClient.updateCatalogItemCatalogItemId,
    createFn: CatalogItemsApiClient.postCatalogItem,
    populateModels: true,
  });

  const importCatalogItemsMethod = useNonEntityMutation<
    typeof CatalogItemsApiClient.postImportCatalogItems,
    File | null,
    PostImportCatalogItemsResponse
  >(CatalogItemsApiClient.postImportCatalogItems, query.queryKey, {
    invalidateQuery: true,
    onSuccess: () => {
      void queryClient.invalidateQueries('CatalogItemsApi', 'collection');
      void queryClient.invalidateQueries('CatalogItemsApi', 'paginated-collection');
    },
  });

  return {
    ...query,
    importCatalogItems: importCatalogItemsMethod.mutateAsync,
  };
};
export type CatalogItemCollection = ReturnType<typeof useCatalogItems>;
