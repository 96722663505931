import { AccountingPlatformSlug } from '@melio/platform-api';
import { useSubscriptionFeature } from '@melio/subscriptions';

type UsePaymentsSyncSubscriptionFeatureProps = {
  accountingSlug: Extract<AccountingPlatformSlug, 'quickBooksOnline' | 'xero'>;
};

// TODO: this is a prepartion for replacing the current boolean features (accountingPlatformXero & accountingPlatformQbo)
// with a quota-based feature soon https://meliorisk.atlassian.net/browse/ME-74305
export const usePaymentsSyncSubscriptionFeature = ({ accountingSlug }: UsePaymentsSyncSubscriptionFeatureProps) => {
  const { tryUseFeature, isEligible } = useSubscriptionFeature({
    featureName: accountingSlug === AccountingPlatformSlug.Xero ? 'accountingPlatformXero' : 'accountingPlatformQbo',
  });

  return {
    remainingPaymentSync: isEligible ? Infinity : 0,
    isEligibleForMorePaymentsSync: isEligible,
    tryConnectToSync: tryUseFeature,
  };
};
