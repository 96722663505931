/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { object, SchemaOf, string } from 'yup';
import { validateInvalidChars } from '@melio/ap-widgets';
import { Form, Group, Text, useMelioForm } from '@melio/penny';
import { useVendor } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';

import { usePlatformIntl } from '@/translations/Intl';
import { DataComponentEnum, VendorDetailsFormFields } from '@/types/vendors.types';
import { VendorDetailsFormInnerProps } from '../types';
import { preventFormSubmissionOnEnter } from '../utils';
import { getUpdateVendorPayload } from './utils';

type VendorDetailsMangedByFiservFormFields = Pick<
  VendorDetailsFormFields,
  'companyName' | 'nickname' | 'accountNumber'
>;

const useSchemaVendorDetailsMangedByFiservForm = (): SchemaOf<VendorDetailsMangedByFiservFormFields> => {
  const { formatMessage } = usePlatformIntl();

  return object().shape({
    companyName: string().trim().required(formatMessage('widgets.vendorDetails.form.companyName.validation.required')),
    nickname: string()
      .nullable()
      .max(30, formatMessage('widgets.vendorDetails.form.nickname.validation.maxLength'))
      .test(
        'validate-invalid-chars',
        '',
        validateInvalidChars(/[ ,.\-0-9A-Za-z\r\n]*/, (invalidChars) =>
          formatMessage('widgets.vendorDetails.form.nickname.validation.format', {
            invalidChars,
          }),
        ),
      ),
    accountNumber: string()
      .nullable()
      .required(formatMessage('widgets.vendorDetails.form.accountNumber.validation.required'))
      .max(32, formatMessage('widgets.vendorDetails.form.accountNumber.validation.maxLength'))
      .test(
        'validate-invalid-chars',
        '',
        validateInvalidChars(/[!"#$%&\-0-9A-Za-z]*/, (invalidChars) =>
          formatMessage('widgets.vendorDetails.form.accountNumber.validation.format', {
            invalidChars,
          }),
        ),
      ),
  }) as SchemaOf<VendorDetailsMangedByFiservFormFields>;
};

export const VendorDetailsMangedByFiservForm = forwardRef<VendorDetailsFormInnerProps, 'form'>(
  ({ vendorId, defaultValues, isSaving, onSubmit, onSubmissionStateChange, isEditable, ...props }, ref) => {
    const { formatMessage } = usePlatformIntl();

    const [unmaskedAccountNumber, setUnmaskedAccountNumber] = useState<string>();

    const { getVendorUnmaskedAccountNumber } = useVendor({ id: vendorId, enabled: false });

    const { formProps, registerField, reset } = useMelioForm<VendorDetailsMangedByFiservFormFields>({
      onSubmit: (data) => onSubmit(getUpdateVendorPayload(data)),
      schema: useSchemaVendorDetailsMangedByFiservForm(),
      isSaving,
      defaultValues,
      onSubmissionStateChange,
    });

    useEffect(() => {
      getVendorUnmaskedAccountNumber().then(({ accountNumber }) => setUnmaskedAccountNumber(accountNumber));
    }, [getVendorUnmaskedAccountNumber, defaultValues?.accountNumber]);

    useEffect(() => {
      reset({ ...defaultValues, accountNumber: isEditable ? unmaskedAccountNumber : defaultValues?.accountNumber });
    }, [defaultValues, isEditable, unmaskedAccountNumber, reset]);

    return (
      <Group variant="vertical" spacing="m">
        <Group variant="vertical" spacing="xs">
          {isEditable && (
            <Text color="global.neutral.900" textStyle="body4">
              {formatMessage('widgets.vendorDetails.form.business.requiredFields')}
            </Text>
          )}
          <Text as="h3" textStyle="heading3Semi">
            {formatMessage('widgets.vendorDetails.form.business.title')}
          </Text>
        </Group>
        <Form
          data-component={DataComponentEnum.VENDOR_DETAILS_MANAGED_BY_FISERV_FORM}
          {...props}
          {...formProps}
          ref={ref}
          isViewMode={!isEditable}
          {...preventFormSubmissionOnEnter()}
        >
          <Form.TextField
            {...registerField('companyName')}
            labelProps={{ label: formatMessage('widgets.vendorDetails.form.companyName.label') }}
            placeholder={formatMessage('widgets.vendorDetails.form.companyName.placeholder')}
            isTruncated={!isEditable}
            isDisabled={isEditable}
          />
          <Form.TextField
            {...registerField('nickname')}
            labelProps={{ label: formatMessage('widgets.vendorDetails.form.nickname.label') }}
            viewModePlaceholder={formatMessage('widgets.vendorDetails.form.nickname.viewModePlaceholder')}
            autoFocus={isEditable}
            isTruncated={!isEditable}
          />
          <Form.TextField
            data-private
            {...registerField('accountNumber')}
            labelProps={{ label: formatMessage('widgets.vendorDetails.form.accountNumber.label') }}
            isTruncated={!isEditable}
          />
        </Form>
      </Group>
    );
  },
);
