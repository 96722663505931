import { roleToVariant, useRoleText } from '@melio/ap-domain';
import { ActionsDropdownMenuItemProps, Container, Group, IconKey, LoadingContainer } from '@melio/penny';
import { ButtonVariants } from '@melio/penny/dist/cjs/theme/utils/button.theme.utils';
import { PaymentStatusEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { SettingsPageHeader } from '@melio/platform-utils';
import { useIsSubscriptionBillingPayorIsFirm, useIsSubscriptionsEnabled } from '@melio/subscriptions';

import type { Collaborator, CollaboratorAction, Invitation, InvitationAction } from '../types';
import { CollaboratorItem } from './components/CollaboratorItem';
import { InvitationItem } from './components/InvitationItem';
import { getCollaboratorActions } from './utils/getCollaboratorActions';
import { getInvitationActions } from './utils/getInvitationActions';

type CollaboratorActionOptions = { collaborator: Collaborator };
type InvitationActionOptions = { invitation: Invitation };

type Props = {
  collaborators?: Collaborator[];
  invitations?: Invitation[];
  isFetching: boolean;
  actor?: Collaborator;
  onAddCollaboratorClick: VoidFunction;
  onAddAccountingFirmClick?: VoidFunction;
  onCollaboratorAction: (action: CollaboratorAction, options: CollaboratorActionOptions) => void;
  onInvitationAction: (action: InvitationAction, options: InvitationActionOptions) => void;
  canInvite?: boolean;
  isAccountingFirm?: boolean;
  isExternalAccountant?: boolean;
};

export const ManageCollaboratorsScreen = ({
  actor,
  invitations,
  isFetching,
  collaborators,
  onAddCollaboratorClick,
  onAddAccountingFirmClick,
  onInvitationAction,
  onCollaboratorAction,
  canInvite,
  isAccountingFirm,
  isExternalAccountant,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const { getRoleText } = useRoleText();
  const isSubscriptionEnabled = useIsSubscriptionsEnabled();
  const isSubscriptionPaidByFirm = useIsSubscriptionBillingPayorIsFirm();

  const secondaryButtonProps =
    !isAccountingFirm && !isExternalAccountant && canInvite && isSubscriptionEnabled
      ? {
          label: formatMessage('activities.manageCollaborators.screens.main.addAccountingFirm'),
          dataTestId: 'add-accounting-firm-button',
          variant: 'tertiary' as ButtonVariants,
          isDisabled: isSubscriptionPaidByFirm,
          onClick: onAddAccountingFirmClick,
        }
      : undefined;

  const primaryButtonProps = canInvite
    ? {
        label: formatMessage('activities.manageCollaborators.screens.main.addBtn'),
        icon: 'add' as IconKey,
        onClick: onAddCollaboratorClick,
        dataTestId: 'add-collaborator-button',
      }
    : undefined;

  const collaboratorActionToDropdownActionsOption = (
    action: CollaboratorAction,
    options: CollaboratorActionOptions
  ): ActionsDropdownMenuItemProps => {
    const onClick = () => onCollaboratorAction(action, options);
    const dataTestId = `actions-dropdown-menu-item-${action}`;
    switch (action) {
      case 'owner':
        return {
          label: formatMessage('activities.manageCollaborators.screens.main.actions.collaborator.makeOwner'),
          onClick,
          dataTestId,
        };
      case 'delete':
        return {
          label: formatMessage('activities.manageCollaborators.screens.main.actions.collaborator.delete'),
          variant: 'critical',
          onClick,
          dataTestId,
        };
      case 'edit':
        return {
          label: formatMessage('activities.manageCollaborators.screens.main.actions.collaborator.edit'),
          onClick,
          dataTestId,
        };
      case 'approve-accounting-firm':
        return {
          label: formatMessage('activities.manageCollaborators.screens.main.actions.collaborator.unblock'),
          onClick,
          dataTestId,
        };
    }
  };

  const invitationActionToDropdownActionsOption = (
    action: InvitationAction,
    options: InvitationActionOptions
  ): ActionsDropdownMenuItemProps => {
    const onClick = () => onInvitationAction(action, options);

    const dataTestId = `actions-dropdown-menu-item-${action}`;
    switch (action) {
      case 'resend':
        return {
          label: formatMessage('activities.manageCollaborators.screens.main.actions.invitation.resend'),
          onClick,
          dataTestId,
        };
      case 'cancel':
        return {
          label: formatMessage('activities.manageCollaborators.screens.main.actions.invitation.delete'),
          variant: 'critical',
          onClick,
          dataTestId,
        };
    }
  };

  const getCollaboratorBadgeText = (collaborator: Collaborator) =>
    collaborator.permissionLevel === PaymentStatusEnum.Blocked
      ? formatMessage('activities.manageCollaborators.collaborator.status.review')
      : getRoleText(collaborator.roleUniqueName);

  return (
    <Container width="full" paddingX="none" data-testid="manage-collaborators-activity-manage-collaborators-screen">
      <LoadingContainer isLoading={isFetching} data-testid="manage-collaborators-loading">
        <Group variant="vertical" hasDivider>
          <SettingsPageHeader
            title={formatMessage('app.settings.companySection.cards.collaborators.title')}
            subTitle={formatMessage('app.settings.companySection.cards.collaborators.description')}
            secondaryButton={secondaryButtonProps}
            primaryButton={primaryButtonProps}
          />
          {collaborators?.map((collaborator) => (
            <CollaboratorItem
              key={collaborator.id}
              {...collaborator}
              badgeText={getCollaboratorBadgeText(collaborator)}
              badgeVariant={roleToVariant(collaborator.roleUniqueName)}
              menuItems={getCollaboratorActions({ collaborator, actor }).map((action) =>
                collaboratorActionToDropdownActionsOption(action, { collaborator })
              )}
            />
          ))}
          {invitations?.map((invitation) => (
            <InvitationItem
              key={invitation.id}
              {...invitation}
              isExpired={invitation.status === 'expired'}
              badgeText={
                invitation.status === 'expired'
                  ? formatMessage('activities.manageCollaborators.invitation.status.expired')
                  : formatMessage('activities.manageCollaborators.invitation.status.pending')
              }
              menuItems={getInvitationActions({ invitation, actor }).map((action) =>
                invitationActionToDropdownActionsOption(action, { invitation })
              )}
            />
          ))}
        </Group>
      </LoadingContainer>
    </Container>
  );
};
