import { VisuallyHidden } from '@chakra-ui/react';
import { SearchBar as DSSearchBar } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { PaginationResponsePagination } from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { debounce } from 'lodash';
import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';

import { FocusEvents, useFocusOnEvent } from '../../FocusSkipToComponent';
import { useSearchTerm } from '../../hooks/useSearchTerm';

const DEBOUNCE_DELAY = 500;

type Props = {
  isDisabled?: boolean;
  onSearchSubmitted?: (searchTerm: string) => void;
  label?: string;
  placeholder?: MessageKey;
  placeholderOnFocus?: MessageKey;
  pagination?: PaginationResponsePagination;
  focusOnEvent?: FocusEvents;
};

export const SearchBar = ({
  isDisabled = false,
  onSearchSubmitted,
  placeholder = 'activities.payDashboard.search.searchBar.placeholder',
  placeholderOnFocus,
  pagination,
  focusOnEvent,
  ...rest
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { searchTerm, setSearchTerm, clearSearchTerm } = useSearchTerm();
  const [localSearchString, setLocalSearchString] = useState<string>(searchTerm ?? '');
  const [localPlaceholder, setLocalPlaceholder] = useState(placeholder);
  const [isInputFocus, setInputFocus] = useState<boolean>(false);
  const searchRef = useRef<HTMLInputElement>(null);
  const [isSearchOnKeyPressEnabled] = usePartnerFeature('PayDashboardSearchOnKeyPress', false);

  useFocusOnEvent({ event: focusOnEvent, ref: searchRef });

  useEffect(() => {
    if (searchTerm !== localSearchString) {
      setLocalSearchString(searchTerm ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const debouncedOnSearchSubmitted = useMemo(
    () =>
      debounce((val: string) => {
        setSearchTerm(val);
        onSearchSubmitted?.(val);
        track('Dashboard', 'Click', { Intent: 'search', Cta: 'search-result', DynamicSearch: true, SearchValue: val });
      }, DEBOUNCE_DELAY),
    [setSearchTerm, onSearchSubmitted, track]
  );

  useEffect(() => {
    if (isInputFocus) {
      setLocalPlaceholder(placeholderOnFocus || placeholder);
    } else {
      setLocalPlaceholder(placeholder);
    }
  }, [placeholder, placeholderOnFocus, isInputFocus]);

  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearchTerm(localSearchString);
      onSearchSubmitted?.(localSearchString);
      track('Dashboard', 'Click', { Intent: 'search', Cta: 'search-result', SearchValue: localSearchString });
    }
  };

  const handleOnValueClear = () => {
    setLocalSearchString('');
    clearSearchTerm();
    onSearchSubmitted?.('');
    track('Dashboard', 'Click', { Intent: 'search', Cta: 'delete-search-values', SearchValue: null });
    setInputFocus(true);
  };

  const handleOnValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setLocalSearchString(val);
    if (isSearchOnKeyPressEnabled) {
      debouncedOnSearchSubmitted(val);
    }
  };

  const handleOnBlur = () => {
    setInputFocus(false);
  };

  const handleOnFocus = () => {
    setInputFocus(true);
  };

  return (
    <>
      <DSSearchBar
        ref={searchRef}
        data-testid="pay-dashboard-search-input"
        isDisabled={isDisabled}
        value={localSearchString}
        placeholder={formatMessage(localPlaceholder)}
        onClear={handleOnValueClear}
        onKeyDown={handleOnKeyDown}
        onChange={handleOnValueChange}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        aria-label={rest.label}
        {...rest}
      />
      <VisuallyHidden
        role="status"
        aria-live="polite"
        aria-label={
          pagination?.totalCount !== undefined && searchTerm
            ? formatMessage('activities.payDashboard.search.searchBar.results.count', {
                count: pagination.totalCount,
                searchTerm,
              })
            : ''
        }
      />
      <VisuallyHidden
        role="status"
        aria-live="polite"
        aria-label={pagination?.totalCount === undefined && searchTerm ? formatMessage('app.loading') : ''}
      />
    </>
  );
};
