import { DeliveryPreferenceType } from '@melio/platform-api';

export type PaymentDraft = {
  note?: string;
  fundingSourceId?: string;
  scheduledDate?: Date;
  billId?: string;
  deliveryMethodId?: string;
  deliveryPreferenceType?: DeliveryPreferenceType;
};

export type GuestPayorFundingSourceTypes = 'bank' | 'card';
export type GuestPayorPaymentOptions = GuestPayorFundingSourceTypes | 'custom';

export enum ErrorType {
  EXPIRED = 'expired',
  INVOICE_CANCELLED = 'invoice-cancelled',
  GENERAL_ERROR = 'general-error',
}
