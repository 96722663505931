import { useDisclosure } from '@chakra-ui/react';
import { Loader, Modal, Text } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useApprovalWorkflows } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useIsSubscriptionBillingPayorIsFirm } from '@melio/subscriptions';
import React, { ComponentProps, useEffect } from 'react';

import { useManageCollaboratorAnalytics } from './hooks/analytics/useManageCollaboratorAnalytics';
import { useManageCollaboratorsData } from './hooks/useManageCollaboratorsData';
import { ManageCollaboratorsScreen } from './screens/ManageCollaborators.screen';
import { DeleteCollaboratorModalScreen } from './screens/modals/DeleteCollaborator/DeleteCollaborator.modal-screen';
import { ApproveAccountingFirmModalScreen } from './screens/modals/InviteOrEdit/ApproveAccountingFirm.modal-screen.';
import { CreateAccountingFirmInvitationModalScreen } from './screens/modals/InviteOrEdit/CreateAccountingFirmInvitation.modal-screen';
import { CreateInvitationModalScreen } from './screens/modals/InviteOrEdit/CreateInvitation.modal-screen';
import { EditCollaboratorModalScreen } from './screens/modals/InviteOrEdit/EditCollaborator.modal-screen';
import { MakeOwnerCollaboratorModalScreen } from './screens/modals/InviteOrEdit/MakeOwnerCollaborator.modal-screen';
import { toFullName } from './screens/utils/toFullName';
import { useApproveAccountingFirmModel } from './useApproveAccountingFirmModel';
import { useCancelInvitationModal } from './useCancelInvitationModal';
import { useCreateAccountingFirmInvitationModal } from './useCreateAccountingFirmInvitationModal';
import { useCreateInvitationModal } from './useCreateInvitationModal';
import { useDeleteCollaboratorModal } from './useDeleteCollaboratorModal';
import { useEditCollaboratorModal } from './useEditCollaboratorModal';
import { useMakeOwnerCollaboratorModal } from './useMakeOwnerCollaboratorModal';
import { useResendInvitationFlow } from './useResendInvitationFlow';
const CancelInvitationModalScreen = DeleteCollaboratorModalScreen;

export const ManageCollaboratorsActivity = withAnalyticsContext(() => {
  const {
    collaborators,
    invitations,
    isFetching,
    isLoading,
    actor,
    companyName,
    actorMinApprovalThreshold,
    permissions,
    collaboratorsEligibility,
    remainingFreeSeats,
    isAccountingFirm,
    isExternalAccountant,
  } = useManageCollaboratorsData();
  const createInvitationModal = useCreateInvitationModal();
  const createAccountingFirmInvitationModal = useCreateAccountingFirmInvitationModal();
  const cancelInvitationModal = useCancelInvitationModal();
  const editCollaboratorModal = useEditCollaboratorModal();
  const makeOwnerCollaboratorModal = useMakeOwnerCollaboratorModal();
  const deleteCollaboratorModal = useDeleteCollaboratorModal();
  const approveAccountingFirmModel = useApproveAccountingFirmModel(collaborators);
  const { resendInvitation } = useResendInvitationFlow();
  const { formatMessage } = useMelioIntl();
  const {
    isOpen: isAccountingFirmBilledModalOpen,
    onClose: onCloseAccountingFirmBilledModal,
    onOpen: openAccountingFirmBilledModal,
  } = useDisclosure();
  const isSubscriptionPaidByFirm = useIsSubscriptionBillingPayorIsFirm();
  const { data: approvalWorkflows, isLoading: isLoadingApprovalWorkflows } = useApprovalWorkflows();
  const { track } = useAnalytics();

  useEffect(() => {
    track('Settings', 'View', { PageName: 'collaborators-setting' });
  }, [track]);

  const hasApprovalWorkflow = !!(approvalWorkflows?.length ?? 0);

  const analytics = useManageCollaboratorAnalytics({ collaborators: collaborators ?? [], isLoadingData: isLoading });

  const onAddCollaboratorClick = () => {
    collaboratorsEligibility.tryUseFeature({ onFeatureIsEligible: openCollaboratorModal });
    analytics.clickAddCollaborator();
  };

  const openCollaboratorModal = () => {
    if (!isExternalAccountant && isSubscriptionPaidByFirm && !remainingFreeSeats) {
      return openAccountingFirmBilledModal();
    }
    createInvitationModal.openModal();
  };

  const onAddAccountingFirmClick = () => {
    collaboratorsEligibility.tryUseFeature({ onFeatureIsEligible: createAccountingFirmInvitationModal.openModal });
    analytics.clickAddAccountingFirm();
  };

  const onCollaboratorAction: ComponentProps<typeof ManageCollaboratorsScreen>['onCollaboratorAction'] = (
    action,
    options
  ) => {
    switch (action) {
      case 'delete':
        deleteCollaboratorModal.openModal(options.collaborator);
        break;
      case 'edit':
        editCollaboratorModal.openModal(options.collaborator);
        break;
      case 'owner':
        makeOwnerCollaboratorModal.openModal(options.collaborator);
        break;
      case 'approve-accounting-firm':
        approveAccountingFirmModel.openModal(options.collaborator);
        break;
    }
    analytics.clickCollaboratorAction({ action, collaboratorRoleUniqueName: options.collaborator.roleUniqueName });
  };

  const onInvitationAction: ComponentProps<typeof ManageCollaboratorsScreen>['onInvitationAction'] = (
    action,
    options
  ) => {
    switch (action) {
      case 'cancel':
        cancelInvitationModal.openModal(options.invitation);
        break;
      case 'resend':
        resendInvitation(options.invitation);
        break;
    }
    analytics.clickInvitationAction({
      action,
      collaboratorRoleUniqueName: options.invitation.roleUniqueName,
      approvalAmountThreshold: options.invitation.approvalAmountThreshold,
    });
  };

  if (!actor) {
    return <Loader />;
  }

  return (
    <>
      <ManageCollaboratorsScreen
        onAddCollaboratorClick={onAddCollaboratorClick}
        onAddAccountingFirmClick={onAddAccountingFirmClick}
        onCollaboratorAction={onCollaboratorAction}
        onInvitationAction={onInvitationAction}
        actor={actor}
        invitations={invitations}
        collaborators={collaborators}
        isFetching={isFetching}
        canInvite={permissions.invite}
        isExternalAccountant={isExternalAccountant}
        isAccountingFirm={isAccountingFirm}
      />
      {actor.roleUniqueName ? (
        <>
          <DeleteCollaboratorModalScreen
            companyName={companyName}
            fullName={toFullName(
              deleteCollaboratorModal.state.collaborator?.firstName,
              deleteCollaboratorModal.state.collaborator?.lastName
            )}
            isOpen={deleteCollaboratorModal.state.isOpen}
            onClose={deleteCollaboratorModal.closeModal}
            onDone={deleteCollaboratorModal.submit}
            isSaving={deleteCollaboratorModal.isDeleting}
            hasApprovalWorkflow={hasApprovalWorkflow}
            isLoading={isLoadingApprovalWorkflows}
            isApprover={deleteCollaboratorModal.state.collaborator?.approvalActions.canApprovePayments}
          />
          <CancelInvitationModalScreen
            companyName={companyName}
            fullName={toFullName(
              cancelInvitationModal.state.invitation?.firstName,
              cancelInvitationModal.state.invitation?.lastName
            )}
            isOpen={cancelInvitationModal.state.isOpen}
            onClose={cancelInvitationModal.closeModal}
            onDone={cancelInvitationModal.submit}
            isSaving={cancelInvitationModal.isCanceling}
          />
          <EditCollaboratorModalScreen
            collaborator={editCollaboratorModal.state.collaborator}
            isOpen={editCollaboratorModal.state.isOpen}
            onClose={editCollaboratorModal.closeModal}
            onDone={editCollaboratorModal.submit}
            isSaving={editCollaboratorModal.isUpdating}
            hasApprovalWorkflow={hasApprovalWorkflow}
            availableRoleOptions={editCollaboratorModal.state.availableRoleOptions}
          />
          <CreateInvitationModalScreen
            companyName={companyName}
            isOpen={createInvitationModal.isOpen}
            onClose={createInvitationModal.closeModal}
            onDone={createInvitationModal.submit}
            actor={actor}
            availableRoleOptions={permissions.inviteRoleOptions}
            maxAllowedThreshold={actorMinApprovalThreshold}
            isSaving={createInvitationModal.isCreating}
          />
          <CreateAccountingFirmInvitationModalScreen
            isOpen={createAccountingFirmInvitationModal.isOpen}
            onClose={createAccountingFirmInvitationModal.closeModal}
            onDone={createAccountingFirmInvitationModal.submit}
            isSaving={createAccountingFirmInvitationModal.isCreating}
          />
          <MakeOwnerCollaboratorModalScreen
            isOpen={makeOwnerCollaboratorModal.isOpen}
            onClose={makeOwnerCollaboratorModal.closeModal}
            actor={actor}
            collaborator={makeOwnerCollaboratorModal.collaborator}
          />
          <Modal
            isOpen={isAccountingFirmBilledModalOpen}
            data-testid="manage-collaborators-firm-payor-modal"
            header={formatMessage('activities.manageCollaborators.accountantModal.title')}
            onClose={onCloseAccountingFirmBilledModal}
            primaryButton={{
              label: formatMessage('activities.manageCollaborators.accountantModal.secondary'),
              onClick: onCloseAccountingFirmBilledModal,
              variant: 'primary',
            }}
          >
            <Text>{formatMessage('activities.manageCollaborators.accountantModal.description')}</Text>
          </Modal>
          <ApproveAccountingFirmModalScreen
            isOpen={approveAccountingFirmModel.state.isOpen}
            onApprove={approveAccountingFirmModel.submit}
            onDecline={approveAccountingFirmModel.onDecline}
            onClose={approveAccountingFirmModel.closeModal}
            isLoading={approveAccountingFirmModel.isDeleting || approveAccountingFirmModel.isUpdating}
            collaborator={approveAccountingFirmModel.state.collaborator}
          />
        </>
      ) : null}
    </>
  );
});
