import { getStatesByCountry, isValidState } from '@melio/ap-domain';
import { InternationalAddress } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { object, SchemaOf, string, StringSchema } from 'yup';

import { getCountryCode } from './utils';

export const useInternationalAddressSchema = () => {
  const { formatMessage } = useMelioIntl();

  const countryCodeSchema = string()
    .required(
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.countryCode.required')
    )
    .length(
      2,
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.countryCode.length')
    )
    .test(
      'validCountryCode',
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.countryCode.invalid'),
      (countryCode) => !countryCode || !!getCountryCode(countryCode)
    )
    .trim();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const addressSchema = object()
    .shape({
      line1: string().required(
        formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.addressField.required')
      ),
      line2: string().notRequired(),
      city: string()
        .required(
          formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.addressField.city.required'
          )
        )
        .trim(),
      state: string()
        .optional()
        .when('countryCode', (countryCode: string | undefined, schema: StringSchema<string | undefined>) => {
          if (countryCode) {
            const states = getStatesByCountry(countryCode);
            if (states?.length) {
              return schema.required(
                formatMessage(
                  'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.internationalAddressField.state.required'
                )
              );
            }
          }
          return schema;
        })
        .test(
          'isValidState',
          formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.internationalAddressField.state.invalid'
          ),
          (state, context) => {
            const countryCode = (context.parent as InternationalAddress)?.countryCode;
            if (countryCode && state) {
              return isValidState(state, countryCode);
            }
            return true;
          }
        ),
      postalCode: string()
        .required(
          formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.addressField.zipCode.required'
          )
        )
        .trim(),
      countryCode: countryCodeSchema,
    })
    .default(null)
    .required(
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.addressField.required'
      )
    ) as SchemaOf<InternationalAddress>;

  return { addressSchema, countryCodeSchema };
};
