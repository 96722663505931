import { Box } from '@chakra-ui/react';
import { Link, SectionBanner } from '@melio/penny';
import { AccountingPlatformVerifyQuickBooksDesktopConnectionResponseData } from '@melio/platform-api';
import { useConfig, useMelioIntl } from '@melio/platform-provider';
import { useCallback, useEffect, useRef } from 'react';

type Props = {
  verifyQuickBooksDesktopData?: AccountingPlatformVerifyQuickBooksDesktopConnectionResponseData;
};

export const ErrorBanner = ({ verifyQuickBooksDesktopData }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { links } = useConfig();
  const { supportEmail } = useConfig().settings;
  const mailToSupportEmail = `mailto:${supportEmail}`;
  const companyName = verifyQuickBooksDesktopData?.qbdtCompanyName;
  // TODO: Replace with the correct value when the new status is added on the API side
  // https://meliorisk.atlassian.net/browse/ME-72233
  const isReconnectFlow = false;
  // const connectionStatus = verifyQuickBooksDesktopData?.connectionStatus;
  // const isReconnectFlow = companyName && connectionStatus === VerifyQuickBooksDesktopConnectionStatus.NotAvailable;
  const title = formatMessage(
    isReconnectFlow
      ? 'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.sectionBanner.error.reconnect.title'
      : 'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.sectionBanner.error.general.title'
  );
  const description = isReconnectFlow
    ? 'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.sectionBanner.error.reconnect.description'
    : 'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.sectionBanner.error.general.description';
  const link = formatMessage(
    isReconnectFlow
      ? 'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.sectionBanner.error.reconnect.link'
      : 'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.sectionBanner.error.general.link'
  );

  const ref = useRef<HTMLDivElement>(null);

  const scrollToErrorBanner = useCallback(() => {
    ref?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [ref]);

  useEffect(() => {
    scrollToErrorBanner();
  }, [scrollToErrorBanner]);

  return (
    <Box pt="m" ref={ref}>
      <SectionBanner
        title={title}
        description={formatMessage(description, {
          link: (
            <Link
              href={links['quickBooksDesktopWebConnectorGuide']}
              isBold
              label={link}
              variant="standalone"
              size="medium"
              data-testid="connecting-melio-and-qbdt-guide-link"
            />
          ),
          supportEmail: (
            <Link href={mailToSupportEmail} label={supportEmail} isBold variant="standalone" size="medium" newTab />
          ),
          companyName,
        })}
        variant="critical"
        data-testid="verify-connection-error-banner"
      />
    </Box>
  );
};
