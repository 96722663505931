import { Text } from '@melio/penny';

import { usePlatformIntl } from '@/translations/Intl';
import { useIsCallbackEligible } from './hooks/useIsCallbackEligible';

export const ContactUsPhoneDescription = () => {
  const { formatMessage } = usePlatformIntl();
  const isCallbackEligible = useIsCallbackEligible();

  return (
    <Text>
      {isCallbackEligible && ' '.concat(formatMessage('app.settings.SupportSection.contact.phoneDescription'))}
    </Text>
  );
};
