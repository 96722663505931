import { Modal } from '@melio/penny';
import { Text } from '@melio/penny';
import { BankAccount, useFundingSource } from '@melio/platform-api';

import { usePlatformIntl } from '@/translations/Intl';
import { BankAccountDetails } from './BankAccountDetails';
type BankAccountDetailsModalProps = {
  fundingSourceId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const BankAccountDetailModal: React.FC<BankAccountDetailsModalProps> = ({
  fundingSourceId,
  isOpen,
  onClose,
}: BankAccountDetailsModalProps) => {
  const { isFetching, data } = useFundingSource({ id: fundingSourceId, params: { shouldDecrypt: true } });
  const { formatMessage } = usePlatformIntl();
  const { accountNumber, routingNumber } = (data?.details || {}) as BankAccount;
  return (
    <Modal
      isLoading={isFetching}
      isOpen={isOpen}
      onClose={onClose}
      header={<Text textStyle="heading2Semi">{formatMessage('widgets.bankDetails.title')}</Text>}
      primaryButton={{
        label: formatMessage('widgets.bankDetails.close'),
        onClick: onClose,
        variant: 'primary',
      }}
    >
      <BankAccountDetails accountNumber={accountNumber} routingNumber={routingNumber} />
    </Modal>
  );
};
