import { Box } from '@chakra-ui/react';
import { useVendorActions } from '@melio/ap-domain';
import { Button, Pill, PillProps, Popover, PopoverProps, Tooltip } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Vendor, VendorAutoPayStatusEnum, VendorEBillStatusEnum } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

export type EbillCellBadgeProps = {
  vendor: Vendor;
  onEBillSubscribeClick?: (vendorId: string) => void;
  onAutoPayActivationClick?: (vendorId: string) => void;
  isReadOnly?: boolean;
};

export const EbillCellBadge = ({
  vendor,
  onEBillSubscribeClick,
  onAutoPayActivationClick,
  isReadOnly,
}: EbillCellBadgeProps) => {
  const { formatMessage } = useMelioIntl();

  const { title, actionRenderer, description } =
    usePopoverProps(vendor, onEBillSubscribeClick, onAutoPayActivationClick) ?? {};

  const renderBadge = ({ role, ariaLabel }: { role?: React.AriaRole; ariaLabel?: string } = {}) => (
    <Box
      as={Pill}
      type="status"
      status={getBadgeStatus(vendor)}
      label={formatMessage('activities.payDashboard.eBillCellBadge.eBill')}
      data-testid={`ebill-cell-badge-${vendor.id}`}
      tabIndex={0}
      aria-label={ariaLabel}
      role={role}
    />
  );

  if (actionRenderer && !isReadOnly) {
    return (
      <Popover
        title={title ?? ''}
        description={description ?? ''}
        actionRenderer={actionRenderer}
        data-testid="ebill-badge-popover"
      >
        {renderBadge({ role: 'button' })}
      </Popover>
    );
  } else if (description) {
    return (
      <Tooltip label={description} placement="top">
        {renderBadge({ role: 'button' })}
      </Tooltip>
    );
  }

  return renderBadge({
    ariaLabel: formatMessage(`activities.payDashboard.vendorsTab.table.cells.vendorName.link.ariaLabel.eBillStatus`, {
      eBillStatus: vendor.eBillStatus,
    }),
  });
};

export const usePopoverProps = (
  vendor: Vendor,
  onEBillSubscribeClick?: (vendorId: string) => void,
  onAutoPayActivationClick?: (vendorId: string) => void
): null | {
  title?: string;
  description: string;
  actionRenderer?: PopoverProps['actionRenderer'];
} => {
  const { formatMessage } = useMelioIntl();
  const [isAutoPayEnabled] = useFeature(FeatureFlags.AutoPay, false);
  const { track } = useAnalytics();
  const vendorActions = useVendorActions(vendor);

  if (!onEBillSubscribeClick || !onAutoPayActivationClick) {
    return null;
  }

  const handleEBillSubscribeClick = () => {
    track('Dashboard', 'Click', {
      Cta: 'subscribe',
      VendorId: vendor.id,
    });
    onEBillSubscribeClick(vendor.id);
  };

  const handleAutoPayActivationClick = () => {
    track('Dashboard', 'Click', {
      Cta: 'autopay-set-up',
      VendorId: vendor.id,
    });
    onAutoPayActivationClick(vendor.id);
  };

  switch (vendor.eBillStatus) {
    case VendorEBillStatusEnum.Capable:
      return {
        title: formatMessage('activities.payDashboard.eBillCellBadge.popover.capable.title'),
        description: formatMessage('activities.payDashboard.eBillCellBadge.popover.capable.description', {
          vendorName: vendor.name,
        }),
        actionRenderer: ({ onClose, ...props }) => (
          <Button
            {...props}
            size="small"
            variant="secondary-inverse"
            label={formatMessage('activities.payDashboard.eBillCellBadge.popover.capable.subscribe')}
            onClick={() => {
              onClose();
              handleEBillSubscribeClick();
            }}
          />
        ),
      };
    case VendorEBillStatusEnum.Pending:
      return { description: formatMessage('activities.payDashboard.eBillCellBadge.popover.pending.description') };
    case VendorEBillStatusEnum.Rejected:
      return { description: formatMessage('activities.payDashboard.eBillCellBadge.popover.rejected.description') };
    case VendorEBillStatusEnum.Active: {
      if (isAutoPayEnabled && vendor.autoPayStatus === VendorAutoPayStatusEnum.Capable) {
        return {
          title: formatMessage('activities.payDashboard.autoPayCellBadge.popover.capable.title'),
          description: formatMessage('activities.payDashboard.autoPayCellBadge.popover.capable.description', {
            vendorName: vendor.name,
          }),
          actionRenderer: ({ onClose, ...props }) =>
            vendorActions.autoPayActivation && (
              <Button
                {...props}
                size="small"
                variant="secondary-inverse"
                label={formatMessage('activities.payDashboard.autoPayCellBadge.popover.capable.setup')}
                onClick={() => {
                  onClose();
                  handleAutoPayActivationClick();
                }}
              />
            ),
        };
      }

      return { description: formatMessage('activities.payDashboard.eBillCellBadge.popover.active.description') };
    }
  }

  return null;
};

export const getBadgeStatus = (vendor: Vendor): PillProps['status'] => {
  switch (vendor.eBillStatus) {
    case VendorEBillStatusEnum.Capable:
      return 'neutral';
    case VendorEBillStatusEnum.Pending:
      return 'warning';
    case VendorEBillStatusEnum.Rejected:
      return 'critical';
    case VendorEBillStatusEnum.Active:
      return 'success';
  }
  return 'neutral';
};
