import { Box } from '@chakra-ui/react';
import { FormattedMessage } from '@melio/ar-domain';
import { Group, Layout, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

export type CustomPaymentInstructionsProps = {
  paymentInstructions: string;
};

export const CustomPaymentInstructions = forwardRef<CustomPaymentInstructionsProps>(({ paymentInstructions }, ref) => (
  <Layout backgroundColor="lightest" paddingContent="s" data-layout={CustomPaymentInstructions.displayName} ref={ref}>
    <Group variant="vertical" spacing="xxs" data-testid="custom-payment-instructions">
      <Text textStyle="body4Semi" color="global.neutral.800">
        <FormattedMessage id="ar.guestPayment.customInstructionsPayment.title.text" />
      </Text>
      <Box textStyle="body3" whiteSpace="pre-line">
        {paymentInstructions}
      </Box>
    </Group>
  </Layout>
));

CustomPaymentInstructions.displayName = 'CustomPaymentInstructions';
