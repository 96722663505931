import { useMelioQueryClient, useNonEntityMutation } from '@melio/api-client';
import {
  CustomersApiClient,
  CustomersApiInstance,
  PostImportCustomersResponse,
} from '@melio/platform-api-axios-client';

import { useCollection, UseCollectionProps } from '../../api-client';

export type UseCustomersProps = UseCollectionProps<
  typeof CustomersApiInstance.getCustomers,
  typeof CustomersApiClient.postCustomer,
  typeof CustomersApiClient.updateCustomerById
>;

export const useCustomers = ({ onCreate, onUpdate, ...props }: UseCustomersProps = {}) => {
  const queryClient = useMelioQueryClient();
  const query = useCollection({
    ...props,
    queryKey: 'CustomersApi',
    queryFn: CustomersApiInstance.getCustomers,
    createFn: CustomersApiClient.postCustomer,
    deleteFn: CustomersApiClient.deleteCustomerCustomerId,
    updateFn: CustomersApiClient.updateCustomerById,
    populateModels: true,
    onUpdate: (...args) => {
      void queryClient.invalidateQueries('CustomersDashboardApi', 'collection');
      void queryClient.invalidateQueries('CustomersDashboardApi', 'paginated-collection');
      void onUpdate?.(...args);
    },
    onCreate: (...args) => {
      void queryClient.invalidateQueries('CustomersDashboardApi', 'collection');
      void queryClient.invalidateQueries('CustomersDashboardApi', 'paginated-collection');
      void onCreate?.(...args);
    },
  });

  const importCustomersMethod = useNonEntityMutation<
    typeof CustomersApiClient.postImportCustomers,
    File | null,
    PostImportCustomersResponse
  >(CustomersApiClient.postImportCustomers, query.queryKey, {
    invalidateQuery: true,
    onSuccess: () => {
      void queryClient.invalidateQueries('CustomersDashboardApi', 'collection');
      void queryClient.invalidateQueries('CustomersDashboardApi', 'paginated-collection');
      void queryClient.invalidateQueries('CustomersApi', 'collection');
      void queryClient.invalidateQueries('CustomersApi', 'paginated-collection');
    },
  });

  return {
    ...query,
    importCustomers: importCustomersMethod.mutateAsync,
  };
};

export type CustomerCollection = ReturnType<typeof useCustomers>;
