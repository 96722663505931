import { PillProps } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useFormatExpirationDate } from '@melio/platform-utils';
import { differenceInHours } from 'date-fns';
import { isUndefined } from 'lodash';
import { useMemo } from 'react';

const ACCOUNT_BALANCE_UPDATE_THRESHOLD = 24;

export const useFundingSourceCardProps = ({
  fundingSource,
}: {
  fundingSource: FundingSource;
}): {
  title: string;
  line1?: string;
  line2?: string;
  badgeProps?: Array<PillProps & { 'data-testid': string }>;
} => {
  const { formatMessage, formatDate, formatCurrency } = useMelioIntl();
  const { formatExpirationDate } = useFormatExpirationDate();
  // eslint-disable-next-line no-restricted-syntax
  const now = useMemo(() => new Date(), []);

  if (fundingSource.type === 'bank-account') {
    return {
      title: fundingSource.displayName,
      line1: fundingSource.nickname,
      ...(!isUndefined(fundingSource.availableBalance) &&
      fundingSource.availableBalanceUpdatedAt &&
      differenceInHours(now, fundingSource.availableBalanceUpdatedAt) < ACCOUNT_BALANCE_UPDATE_THRESHOLD
        ? {
            line2: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.bank-account.line2', {
              availableBalance: formatCurrency(fundingSource.availableBalance),
              availableBalanceUpdatedAt: formatDate(fundingSource.availableBalanceUpdatedAt, {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                timeZone: 'America/New_York',
                timeZoneName: 'short',
              }),
            }),
          }
        : {}),
    };
  } else if (fundingSource.type === 'card') {
    return {
      title: fundingSource.displayName,
      line1: fundingSource.nickname,
      line2: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.line2', {
        expirationDate: formatExpirationDate({
          month: fundingSource.details.expirationMonth,
          year: fundingSource.details.expirationYear,
        }),
      }),
    };
  } else {
    return {
      title: 'not implemented',
    };
  }
};
