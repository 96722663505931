import { useMelioIntl, useTaxRate } from '@melio/ar-domain';
// eslint-disable-next-line import/no-deprecated
import { useToast } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { pick } from 'lodash';

import { TaxRateFormDialogScreen, TaxRateFormFields } from './screens';

type EditTaxRateDialogActivityProps = {
  taxRateId: string;
  onDone: (id: string) => unknown;
  onClose: VoidFunction;
  onError?: ARErrorFunction;
};

export const EditTaxRateDialogActivity = forwardRef<EditTaxRateDialogActivityProps>(
  ({ taxRateId, onDone: onSuccess, onClose, onError, ...props }, ref) => {
    const { data: taxRates, update, isMutating, isLoading } = useTaxRate({ id: taxRateId, onError });

    const { formatMessage } = useMelioIntl();
    // eslint-disable-next-line import/no-deprecated
    const { toast } = useToast();

    const onSubmit = ({ ...values }: TaxRateFormFields) =>
      update(values)
        .then((item) => {
          onSuccess(item.id);
          toast({
            type: 'success',
            title: formatMessage('ar.settings.activities.addEditTaxRate.toast.onEdit.label', {
              name: item.name,
            }),
          });
        })
        .catch(onError);

    return (
      <TaxRateFormDialogScreen
        data-testid="edit-tax-rate-dialog"
        isOpen
        defaultValues={pick(taxRates, ['name', 'rate', 'isDefault'])}
        variant="edit"
        isSaving={isMutating}
        isLoading={isLoading}
        onClose={onClose}
        onSubmit={onSubmit}
        {...props}
        ref={ref}
      />
    );
  }
);
EditTaxRateDialogActivity.displayName = 'EditTaxRateDialogActivity';
