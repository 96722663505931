import { useRoleText } from '@melio/ap-domain';
import { Card, Container, Form, Group, Icon, SectionBanner, Text, Typography } from '@melio/penny';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

import { useFormContext } from '../../../FormContext';
import { FormFieldsSettings } from '../../../types';
import { useAllowedActions } from '../../../useAllowedActions';

type Props = { formFieldsSettings: Pick<FormFieldsSettings, 'roleUniqueName'> };
export const RolesSection = ({ formFieldsSettings }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { registerField, getValues, formState } = useFormContext();
  const { getRoleText } = useRoleText();
  const {
    settings: {
      collaborator: { showRoleTransferDisclaimer },
    },
  } = useConfig();
  const { canEditCollaborator, rolesPermittedToInvite } = useAllowedActions();

  const roleUniqueNameField = registerField('roleUniqueName');

  const renderRoleContent = (roleUniqueName: string) => (
    <Group variant="vertical" spacing="xs">
      <Typography.MainLabel
        variant="bold"
        label={getRoleText(roleUniqueName)}
        pillProps={
          formState.defaultValues?.roleUniqueName === roleUniqueName
            ? {
                label: formatMessage('activities.collaboratorDrawer.body.rolesSection.activeIndication'),
                status: 'informative',
                type: 'secondary',
              }
            : undefined
        }
      />

      <Typography.ParagraphList
        list={Array.from({ length: 5 })
          .map((_, index) => ({
            content: formatMessage(
              `activities.collaboratorDrawer.body.rolesSection.${roleUniqueName}.${index + 1}` as MessageKey
            ),
          }))
          .filter((s) => !!s.content)}
        type="unordered"
      />
    </Group>
  );

  const isReadonly = formFieldsSettings.roleUniqueName.readonly;
  return (
    <Container overflow="initial">
      <Group
        variant="vertical"
        spacing="m"
        role="radiogroup"
        aria-label={formatMessage('activities.collaboratorDrawer.body.rolesSection.heading')}
      >
        <Group.Item>
          <Text as="h3" textStyle="heading3Semi">
            {formatMessage('activities.collaboratorDrawer.body.rolesSection.heading')}
          </Text>
        </Group.Item>

        {isReadonly && canEditCollaborator({ allowAnySubject: true }) && showRoleTransferDisclaimer ? (
          <Group.Item>
            <SectionBanner
              data-testid="roles-section-transfer-disclaimer"
              variant="neutral"
              leftElement={<Icon type="info-fill" aria-hidden />}
              description={formatMessage(
                'activities.collaboratorDrawer.body.rolesSection.transferDisclaimer.description',
                {
                  roleUniqueName: getRoleText(formState.defaultValues?.roleUniqueName ?? ''),
                  supportPhoneNumber: formatMessage('supportPhoneNumber'),
                }
              )}
            />
          </Group.Item>
        ) : null}

        <Group.Item>
          {isReadonly ? (
            <Card
              variant="flat"
              paddingX="s"
              paddingY="s"
              data-testid={`roles-section-card-${getValues('roleUniqueName')}`}
            >
              {renderRoleContent(getValues('roleUniqueName'))}
            </Card>
          ) : (
            <Group variant="vertical" spacing="m">
              {rolesPermittedToInvite.map((value) => (
                <Card variant="flat" paddingX="s" paddingY="s" key={value} data-testid={`roles-section-card-${value}`}>
                  <Form.RadioGroup
                    {...roleUniqueNameField}
                    role={undefined}
                    padding="none"
                    options={[
                      {
                        value,
                        ariaLabel: formatMessage('activities.collaboratorDrawer.body.rolesSection.optionAriaLabel', {
                          roleUniqueName: getRoleText(value),
                          isActive: formState.defaultValues?.roleUniqueName === value,
                        }),
                        label: renderRoleContent(value),
                      },
                    ]}
                  />
                </Card>
              ))}
            </Group>
          )}
        </Group.Item>
      </Group>
    </Container>
  );
};
