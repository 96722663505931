import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Box, Stack, useDisclosure } from '@chakra-ui/react';
import { DemoRequestButtonActivity } from '@melio/ap-activities';
import { Button, Container, Group, Link, NakedButton, Text } from '@melio/penny';
import { OriginFlow, useAnalytics } from '@melio/platform-analytics';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { SettingsPageHeader } from '@melio/platform-utils';

import { SettingsCardIdEnum } from '@/cl/components/SettingsCard/SettingsCard.component';
import { SettingsSectionContainer } from '@/cl/components/SettingsSectionContainer/SettingsSectionContainer.component';
import { usePartnerConfig } from '@/hooks/partners';
import { usePlatformIntl } from '@/translations/Intl';
import { getAccessTokenData } from '@/utils/getAccessTokenData.utils';
import { useZendesk } from '@/utils/zendesk';
import { MelioTutorialVideoModal } from '@/widgets/pay-dashboard/melioTutorileVideo/MelioTutorialVideoModal.widget';
import RequestCallbackWidget from '@/widgets/settings-page/SupportPage/RequestCallback/RequestCallback.widget';
import { ContactUsPhoneDescription } from './ContactUsPhoneDescription.widget';
import { LaunchTourButton } from './LaunchTourButton.widget';
import { SupportAccordion } from './SupportAccordion.widget';

export const Support = () => {
  const { formatMessage } = usePlatformIntl();
  const { partnerConfig } = usePartnerConfig();
  const { showZendesk } = useZendesk();
  const { track } = useAnalytics();
  const [isPayDashboardTourEnabled] = useFeature<boolean>(FeatureFlags.IsPayDashboardTourEnabled, false);
  const { isOpen: isTutorialModalOpen, onOpen: onOpenTutorialModal, onClose: onCloseTutorialModal } = useDisclosure();
  const accessTokenData = getAccessTokenData();
  const [isDemoRequestEnabled] = useFeature(FeatureFlags.RequestADemo, false);

  useEffect(() => {
    track('Settings', 'View', { PageName: SettingsCardIdEnum.SupportSetting });
  }, [track]);

  return (
    <>
      <SettingsPageHeader
        title={formatMessage('app.settings.SupportSection.cards.support.title')}
        subTitle={formatMessage('app.settings.SupportSection.cards.support.description')}
      />
      <Container data-component="SupportComponent">
        {isDemoRequestEnabled && accessTokenData?.organization && (
          <SettingsSectionContainer>
            <Stack flexDirection="column" spacing={8}>
              <Stack flexDirection="column">
                <Text as="h3" textStyle={'heading3Semi'}>
                  {formatMessage('app.settings.SupportSection.demo.title', {
                    partnerProductName: partnerConfig.partnerProductName,
                  })}
                </Text>
                <Text>{formatMessage('app.settings.SupportSection.demo.description')}</Text>
                <Box pt={2}>
                  <DemoRequestButtonActivity
                    organizationId={accessTokenData?.organization?.id}
                    email={accessTokenData?.user.email}
                    helpCenterLink={partnerConfig.config.links.helpCenter}
                  />
                </Box>
              </Stack>
            </Stack>
          </SettingsSectionContainer>
        )}
        {isPayDashboardTourEnabled && (
          <SettingsSectionContainer>
            <Stack flexDirection="column" spacing={8}>
              <Stack flexDirection="column">
                <Text as="h3" textStyle="heading3Semi">
                  {formatMessage('app.settings.SupportSection.tour.title')}
                </Text>
                <Text>{formatMessage('app.settings.SupportSection.tour.description')}</Text>
                <Box pt={2}>
                  <LaunchTourButton />
                </Box>
              </Stack>
            </Stack>
          </SettingsSectionContainer>
        )}
        <SettingsSectionContainer data-component="SupportPage">
          <Stack flexDirection="column" spacing={8}>
            <Stack flexDirection="column">
              <Text as="h3" textStyle="heading3Semi">
                {formatMessage('app.settings.SupportSection.help.title')}
              </Text>
              <Text>
                {formatMessage('app.settings.SupportSection.help.description', {
                  tutorialLink: (
                    <NakedButton
                      size="large"
                      variant="secondary"
                      onClick={onOpenTutorialModal}
                      data-testid="open-melio-tutorial-video"
                      label={formatMessage('app.settings.SupportSection.help.tutorialLink')}
                    />
                  ),
                })}
              </Text>
            </Stack>
          </Stack>
        </SettingsSectionContainer>
        <SupportAccordion />
        <SettingsSectionContainer>
          <Stack>
            <Text as="h3" textStyle="heading3Semi">
              {formatMessage('app.settings.SupportSection.contact.title')}
            </Text>
            <Text>
              {formatMessage('app.settings.SupportSection.contact.description', {
                helpCenterLink: (
                  <Link
                    href={partnerConfig.config.links.helpCenter}
                    label={formatMessage('app.settings.SupportSection.contact.helpCenter.link')}
                    newTab
                  />
                ),
                submitSupportRequestLink: (
                  <Link
                    href={partnerConfig.config.links.submitSupportRequest}
                    label={formatMessage('app.settings.SupportSection.contact.submitSupportRequest.link')}
                    newTab
                  />
                ),
              })}
              <ErrorBoundary fallback={<></>}>
                <ContactUsPhoneDescription />
              </ErrorBoundary>
            </Text>
            <Box paddingTop="xs">
              <Group justifyContent={'space-between'} spacing="s" width="fit-content">
                <Button
                  variant="tertiary"
                  onClick={() => {
                    track('ContactUsViaChat', 'Chose');
                    showZendesk();
                  }}
                  label={formatMessage('app.settings.SupportSection.contact.chatButton')}
                />
                <ErrorBoundary fallback={<></>}>
                  <RequestCallbackWidget />
                </ErrorBoundary>
              </Group>
            </Box>
          </Stack>
        </SettingsSectionContainer>
        <MelioTutorialVideoModal
          isOpen={isTutorialModalOpen}
          origin={OriginFlow.SupportSettings}
          onModalClose={onCloseTutorialModal}
        />
      </Container>
    </>
  );
};
