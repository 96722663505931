import { useMelioIntl } from '@melio/platform-i18n';
import { filter } from 'lodash';
import { useCallback } from 'react';

import { Plan, Tier } from '../api';
import { getIsFeatureInPlan } from '../utils/planFeatures.utils';

type CapabilityListItem = {
  message: string;
  tooltipMessage?: string;
};

export type PlanCapabilities = {
  title: string;
  list: Array<CapabilityListItem>;
};

const RAW_CAPABILITIES_PER_TIER = {
  [Tier.MelioBasic]: [
    'freeAchPayments',
    'basicAccountingSync',
    'manageBills',
    'dashboard',
    'pmDmMatching',
    'fx',
    'recurringAndPartial',
    'mobile',
    'ar',
  ],
  [Tier.MelioPlus]: [
    'freeAchPayments',
    'syncWithAccountingSoftware',
    'batchPayments',
    'combinedPayment',
    'advancedUserRoles',
    'approvalFlows',
    'vendorStorage',
    'lineItems',
    'premiumSupport',
  ],
  [Tier.MelioPro]: [
    'freeAchPayments',
    'twoDayDelivery',
    'advancedApprovalWorkflows',
    'qbdtSync',
    'advancedLineItems',
    'phoneSupport',
  ],
  [Tier.MelioVip]: [],
  [Tier.FiservApBasic]: [
    'payAnyBillerOrVendor',
    'payByBankTransferOrCard',
    'deliverPaymentsElectronicallyOrByCheck',
    'batchPayments',
    'numberOfFreeMembers',
  ],
  [Tier.FiservApPro]: ['allAPBasicCapabilities', 'syncWithAccountingSoftware', 'numberOfFreeMembers'],
  [Tier.FiservApArBasic]: [
    'allAPBasicCapabilities',
    'getPaidByAchAndCard',
    'createAndTrackInvoices',
    'numberOfFreeMembers',
  ],
  [Tier.FiservApArPro]: [
    'allAPBasicCapabilities',
    'getPaidByAchAndCard',
    'createAndTrackInvoices',
    'numberOfFreeMembers',
  ],
  [Tier.MelioAccountingClientCore]: [
    'freeAchPayments',
    'numberOfFreeMembers',
    'syncWithAccountingSoftware',
    'batchPayments',
    'combinedPayment',
    'advancedUserRoles',
    'approvalFlows',
    'vendorStorage',
    'lineItems',
    'ar',
  ],
  [Tier.MelioAccountingClientBoost]: [
    'freeAchPayments',
    'twoDayDelivery',
    'advancedApprovalWorkflows',
    'qbdtSync',
    'advancedLineItems',
    'phoneSupport',
  ],
} as const;

type CapabilityTooltipMessagePerTier = { [key in Tier]: Partial<(typeof RAW_CAPABILITIES_PER_TIER)[key]> };

const CAPABILITY_TOOLTIP_MESSAGES_PER_TIER: CapabilityTooltipMessagePerTier = {
  [Tier.MelioBasic]: ['freeAchPayments'],
  [Tier.MelioPlus]: ['freeAchPayments'],
  [Tier.MelioPro]: ['freeAchPayments'],
  [Tier.MelioVip]: [],
  [Tier.FiservApBasic]: [],
  [Tier.FiservApPro]: [],
  [Tier.FiservApArBasic]: [],
  [Tier.FiservApArPro]: [],
  [Tier.MelioAccountingClientCore]: ['freeAchPayments'],
  [Tier.MelioAccountingClientBoost]: ['freeAchPayments'],
} as const;

export const usePlanCapabilities = () => {
  const { formatMessage } = useMelioIntl();

  const getPlanCapabilities = useCallback<(plan: Plan) => CapabilityListItem[]>(
    (plan: Plan) => {
      const hasQuickbooks = getIsFeatureInPlan(plan, { featureName: 'accountingPlatformQbo' }).isEligible;
      const hasXero = getIsFeatureInPlan(plan, { featureName: 'accountingPlatformXero' }).isEligible;
      const hasBatchPayments = getIsFeatureInPlan(plan, { featureName: 'batchPayments' }).isEligible;
      const freeAchPaymentsLimit = getIsFeatureInPlan(plan, {
        featureName: 'freeAchPayments',
        requirements: { totalUnits: 0 },
      }).quota.freeUnitsLimit;

      const getHasCapability = (capability: string) => {
        switch (capability) {
          case 'syncWithAccountingSoftware': {
            return hasQuickbooks && hasXero;
          }
          case 'batchPayments': {
            return hasBatchPayments;
          }
        }

        return true;
      };

      const getMessageParams = (capability: string) => {
        switch (capability) {
          case 'numberOfFreeMembers': {
            return { userSeats: plan.numberOfFreeUsers };
          }
          case 'freeAchPayments': {
            return { freeAchPaymentsLimit };
          }
        }

        return;
      };

      return filter(RAW_CAPABILITIES_PER_TIER[plan.tier], getHasCapability).map((item) => ({
        message: formatMessage(`activities.subscription.plans.capabilities.${item}`, getMessageParams(item)),
        ...(!!CAPABILITY_TOOLTIP_MESSAGES_PER_TIER[plan.tier].some((x) => x === item) && {
          tooltipMessage: formatMessage(
            `activities.subscription.plans.capabilities.${item}.tooltip`,
            getMessageParams(item)
          ),
        }),
      }));
    },
    [formatMessage]
  );

  return { getPlanCapabilities };
};
