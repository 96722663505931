import { Box } from '@chakra-ui/react';
import { Container, Group, Icon, Link, Pill, Text, Tooltip, useBreakpoint } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

export type TodoSectionProps = {
  type: string;
  icon: JSX.Element;
  title: string;
  description: { label: string; value?: string; popover?: string }[];
  action: { label: string; onClick: () => void };
  newItemsCount: number;
};

export const TodoSection = withAnalyticsContext<TodoSectionProps>(
  ({ icon, title, description, action, type, newItemsCount }: TodoSectionProps) => {
    const [todosNewIndicationsEnabled] = useFeature(FeatureFlags.TodosNewIndications, false);
    const { isExtraSmallScreen } = useBreakpoint();
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();

    const onClick = () => {
      track('Dashboard', 'Click', { Cta: type });
      action.onClick();
    };

    const renderTitle = () => (
      <Group>
        <Text textStyle="body3Semi" data-testid="title">
          {isExtraSmallScreen ? (
            <Link href="#" onClick={onClick} label={title} size="medium" color="secondary" data-testid="title-mobile" />
          ) : (
            title
          )}
        </Text>
        {todosNewIndicationsEnabled && newItemsCount > 0 ? (
          <Pill
            label={formatMessage('widgets.todosDrawer.section.newItems.label', {
              count: newItemsCount > 19 ? '+20' : newItemsCount,
            })}
            status="brand"
            type="primary"
            data-testid="new-indication"
          />
        ) : null}
      </Group>
    );

    const renderPopover = (text: string) => (
      <Container alignItems="center" paddingLeft="xxs" paddingBottom="xxxs" width="fit-content">
        <Tooltip label={text}>
          <Box
            as={Icon}
            role="tooltip"
            type="info"
            size="small"
            data-testid="description-popover"
            tabIndex={0}
            aria-label={text}
          />
        </Tooltip>
      </Container>
    );

    return (
      <Container data-testid={`todo-section-${type}`} paddingY="xxs" paddingX="xxs">
        <Group variant="horizontal" justifyContent="space-between" width="full" height="full" alignItems="center">
          <Group.Item>
            <Group alignItems="center">
              {icon}
              <Group variant="vertical" spacing="xxs">
                {renderTitle()}
                {description.length ? (
                  <Group
                    variant={isExtraSmallScreen ? 'vertical' : 'horizontal'}
                    alignItems={isExtraSmallScreen ? 'flex-start' : 'center'}
                    spacing={isExtraSmallScreen ? 'none' : 'xs'}
                    data-testid="description"
                  >
                    {description.map((d, i) => (
                      <Group spacing={isExtraSmallScreen ? 'none' : 'xs'} key={`desc-${i}`}>
                        <Group spacing="xxs" alignItems="center">
                          <Text textStyle="body3" color="global.neutral.900">
                            {d.label}
                          </Text>
                          {d.value && (
                            <Text textStyle="body3Semi" color="global.neutral.900">
                              {d.value}
                            </Text>
                          )}
                          {d.popover && renderPopover(d.popover)}
                        </Group>
                        {!isExtraSmallScreen && i < description.length - 1 && (
                          <Text textStyle="body3" color="global.neutral.900">
                            |
                          </Text>
                        )}
                      </Group>
                    ))}
                  </Group>
                ) : null}
              </Group>
            </Group>
          </Group.Item>

          <Group.Item shrink={0}>
            <Group alignItems="center">
              {isExtraSmallScreen ? null : (
                <Text textStyle="body3Semi">
                  <Link
                    href="#"
                    onClick={onClick}
                    label={action.label}
                    aria-label={formatMessage('widgets.todosDrawer.section.actionsAriaLabel', {
                      action: action.label,
                      title,
                    })}
                    size="medium"
                    color="secondary"
                    data-testid="action"
                  />
                </Text>
              )}
            </Group>
          </Group.Item>
        </Group>
      </Container>
    );
  }
);
