import { Box } from '@chakra-ui/react';
import {
  Container,
  Divider,
  Form,
  Group,
  Layout,
  LoadingContainer,
  Text,
  useFormSubmissionController,
  useMelioForm,
} from '@melio/penny';
import { ApprovalWorkflowRequest, WorkflowVendorsConditionTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef, SystemMessageDisplay } from '@melio/platform-utils';
import { useSubscriptionFeature } from '@melio/subscriptions';
import { Suspense } from 'react';
import type { DefaultValues } from 'react-hook-form';

import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';
import { FormContext } from '../FormContext';
import { useSchemaV2 } from '../schemaV2';
import { ApprovalActions } from './ApprovalActions/ApprovalActions';
import { ApprovalCriteria } from './ApprovalCriteria/ApprovalCriteria';

export type ApprovalWorkflowFormProps = {
  onClose: (workflow: ApprovalWorkflowRequest, action: 'cancel' | 'exit') => void;
  onBack: (workflow: ApprovalWorkflowRequest) => void;
  isSaving: boolean;
  isLoading?: boolean;
  defaultValues: DefaultValues<ApprovalWorkflowRequest>;
  title: string;
  onSubmit: (data: ApprovalWorkflowRequest) => void;
};

export const ApprovalWorkflowFormV2 = forwardRef<ApprovalWorkflowFormProps>((props, ref) => {
  const { formatMessage } = useMelioIntl();
  const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<ApprovalWorkflowRequest>();
  const schema = useSchemaV2();
  const { tryUseFeature: tryUseAdvancedApprovalWorkflows } = useSubscriptionFeature({
    featureName: 'advancedApprovalWorkflows',
  });
  const { tryUseFeature: tryUseApprovalWorkflows } = useSubscriptionFeature({
    featureName: 'approvalWorkflows',
  });
  const form = useMelioForm<ApprovalWorkflowRequest>({
    onSubmit: (res) => onSubmit(res),
    schema,
    mode: 'onSubmit',
    isSaving: props.isSaving,
    isLoading: props.isLoading,
    defaultValues: props.defaultValues,
    onSubmissionStateChange,
  });

  const onSubmit = (approvalWorkflowRequest: ApprovalWorkflowRequest) => {
    const isVendorConditionExist = approvalWorkflowRequest?.criteria.conditions?.some(
      (condition) => condition.condition.type === WorkflowVendorsConditionTypeEnum.WorkflowVendorsCondition
    );
    const isRequiredApprovalFromSpecificUser = approvalWorkflowRequest?.actions?.some(
      (action) => action.type === 'user'
    );

    const isAdvancedApprovalWorkflowsConditionExist = isVendorConditionExist || isRequiredApprovalFromSpecificUser;
    if (!isAdvancedApprovalWorkflowsConditionExist) {
      return tryUseApprovalWorkflows({ onFeatureIsEligible: () => props.onSubmit(approvalWorkflowRequest) });
    }

    return tryUseAdvancedApprovalWorkflows({ onFeatureIsEligible: () => props.onSubmit(approvalWorkflowRequest) });
  };

  return (
    <FormContext.Provider value={form}>
      <Layout
        data-testid="approval-workflow-form"
        backgroundColor="white"
        footer={{
          isSticky: true,
          content: (
            <Box width="full">
              <Divider variant="horizontal" />
              <Container justifyContent="space-between" width="full" paddingX="m" paddingY="m">
                <NewSinglePaymentStepLayout.SecondaryNextButton
                  {...form.cancelButtonProps}
                  size="medium"
                  label={formatMessage('activities.create-approval-workflows.cancel')}
                  onClick={() => props.onClose(form.getValues(), 'cancel')}
                  variant="tertiary"
                  data-testid="cancel-button"
                />
                <NewSinglePaymentStepLayout.NextButton
                  {...submitButtonProps}
                  size="medium"
                  data-testid="submit-approval-workflow"
                  label={formatMessage('activities.create-approval-workflows.save')}
                />
              </Container>
            </Box>
          ),
        }}
      >
        <Container width="full" justifyContent="center" alignItems="center">
          <Suspense fallback={<LoadingContainer isLoading />}>
            <Box width="container.xl" ref={ref}>
              <Group spacing="m" variant="vertical">
                <SystemMessageDisplay data-testid="workflow-form-system-message" />
                <Container paddingBottom="xxl">
                  <Text textStyle="heading1Semi">{props.title}</Text>
                </Container>
              </Group>
              <Form size="small" {...form.formProps}>
                <Group variant="vertical" spacing="xl" width="full">
                  <Group.Item>
                    <Group variant="vertical" spacing="m">
                      <Group.Item>
                        <Text textStyle="body1Semi">
                          {formatMessage('activities.create-approval-workflows.conditions.title')}
                        </Text>
                      </Group.Item>
                      <Group.Item>
                        <Container border="regular" paddingY="m" paddingX="m">
                          <ApprovalCriteria />
                        </Container>
                      </Group.Item>
                    </Group>
                  </Group.Item>

                  <Group.Item>
                    <Group variant="vertical" spacing="m">
                      <Group.Item>
                        <Text textStyle="body1Semi">
                          {formatMessage('activities.create-approval-workflows.actions.title')}
                        </Text>
                      </Group.Item>
                      <Group.Item>
                        <Container border="regular" paddingY="m" paddingX="m">
                          <ApprovalActions />
                        </Container>
                      </Group.Item>
                    </Group>
                  </Group.Item>
                </Group>
              </Form>
            </Box>
          </Suspense>
        </Container>
      </Layout>
    </FormContext.Provider>
  );
});
