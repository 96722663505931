import { useNavigate } from 'react-router-dom';
import { AddBillActivity } from '@melio/ap-activities';
import { SyncFlowEnum } from '@melio/ap-activities';
import { AddBillV2Activity } from '@melio/ap-activities/src/components/add-bill/AddBillV2/AddBillV2.activity';
import { AddBillFormWidgetFields } from '@melio/ap-widgets';
import { OriginFlow } from '@melio/platform-analytics';
import {
  AccountingPlatformSlug,
  isAcceptedScannedInvoiceFileType,
  PostScannedInvoicesBulkResponse,
  useAccountingPlatforms,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { SystemMessage, useSystemMessage } from '@melio/platform-utils';

import { WithLoading } from '@/hoc/withLoading.hoc';
import { useAccountingPlatformConnect, useIntuitConnect } from '@/hooks/accountingPlatforms.hooks';
import { useRouter } from '@/hooks/router.hooks';
import { useIsNewDashboardEnabled } from '@/hooks/useIsNewDashboardEnabled';
import { PayDashboardTabs } from '@/types/payDashboard.types';
import { getQboAccountingId, getXeroAccountingPlatform } from '@/utils/accountingPlatform.utils';

type AddBillScreenProps = {
  onDone: (
    data: AddBillFormWidgetFields,
    billId?: string,
    target?: 'continue' | 'close' | 'markAsPaid',
    message?: SystemMessage,
  ) => void;
  onBack: VoidFunction;
  onClose: VoidFunction;
  vendorId?: string;
  file?: File;
  amount?: string;
  categoryId?: string | null;
  origin?: OriginFlow;
  files?: File[];
};

export const AddBillScreen = ({
  onDone,
  onBack,
  onClose,
  vendorId,
  file,
  amount,
  origin,
  categoryId,
  files,
}: AddBillScreenProps) => {
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();
  const navigate = useNavigate();
  const { replaceToPayDashboardTab, generateNPEDashboardLink } = useRouter();

  const { isLoading: isAccountingPlatformLoading, data: accountingPlatformData } = useAccountingPlatforms();
  const qboAccountingId = getQboAccountingId(accountingPlatformData);
  const xeroAccountingPlatform = getXeroAccountingPlatform(accountingPlatformData);
  const { loginToQuickBooks, isLoading: isQuickbooksConnecting } = useIntuitConnect(
    SyncFlowEnum.Setting,
    qboAccountingId,
    { redirectMethod: 'replace' },
  );
  const { isLoading: isXeroConnecting, connectToAccountingPlatform: onConnectXero } = useAccountingPlatformConnect({
    flowToReturn: SyncFlowEnum.NewBill,
    accountingPlatformId: xeroAccountingPlatform?.id,
  });
  const isAccountingPlatformConnecting = isQuickbooksConnecting || isXeroConnecting;
  const onConnectAccountingPlatform = (accountingPlatformSlug: AccountingPlatformSlug) => {
    if (accountingPlatformSlug === AccountingPlatformSlug.QuickBooksOnline) {
      loginToQuickBooks();
    } else if (accountingPlatformSlug === AccountingPlatformSlug.Xero) {
      onConnectXero();
    }
  };
  const [isInternationalSupportedForRecurringFlow] = useFeature(
    FeatureFlags.IsInternationalSupportedForRecurringFlow,
    false,
  );

  const shouldRedirectToNewDashboard = useIsNewDashboardEnabled();

  const onUploadMultipleInvoicesSuccess = ({
    results,
    files,
  }: {
    results: PostScannedInvoicesBulkResponse;
    files: File[];
  }) => {
    const successLength = results?.data?.success?.length || 0;
    const failedLength = files.length - (results?.data?.success?.length || 0);
    const messageParams = {
      successful: successLength,
      failed: failedLength,
    };
    const someFilesFormatUnsupported = files.some((file) => !isAcceptedScannedInvoiceFileType(file));

    showMessage({
      type: 'informative',
      title: formatMessage(
        someFilesFormatUnsupported
          ? 'activities.payDashboard.uploadSummary.toast.titleUnsupportedFormat'
          : 'activities.payDashboard.uploadSummary.toast.titleRegular',
        messageParams,
      ),
    });
  };

  const onUploadMultipleInvoicesNavigate = () => {
    if (shouldRedirectToNewDashboard) {
      return navigate(generateNPEDashboardLink('bills'));
    }
    replaceToPayDashboardTab(PayDashboardTabs.Inbox);
  };

  return (
    <WithLoading isLoading={isAccountingPlatformLoading}>
      {vendorId || amount || file || (origin && [OriginFlow.JustPay, OriginFlow.Onboarding].includes(origin)) ? (
        <AddBillV2Activity
          onDone={onDone}
          onClose={onClose}
          onBack={onBack}
          initialValues={{ vendorId, amount, categoryId }}
          initialFile={file}
        />
      ) : (
        <AddBillActivity
          onBack={onBack}
          onClose={onClose}
          onDone={onDone}
          onConnectAccountingPlatform={onConnectAccountingPlatform}
          isAccountingPlatformConnecting={isAccountingPlatformConnecting}
          isInternationalSupportedForRecurringFlow={isInternationalSupportedForRecurringFlow}
          onUploadMultipleInvoicesSuccess={onUploadMultipleInvoicesSuccess}
          onUploadMultipleInvoicesNavigate={onUploadMultipleInvoicesNavigate}
          files={files}
        />
      )}
    </WithLoading>
  );
};
